import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { denkNames } from "@/components/outputdata/OutputDataSuppliersSalesInfoModel";
import { RequestParam } from "@/assets/apitype/outputDataListSupplier";
import { CodeName } from "@/store/common";
import moment from 'moment';

//店舗別 売上明細
export interface RowDataSupplier {
    no?: string,
    TRC?: string | null,
    SAC?: string | null,
    BSC?: string | null,
    BSN?: string | null,
    TKH?: string | null,
    TKN?: string | null,
    TKC?: string | null,
    SIN?: string | null,
    NHY?: string | null,
    KJD?: string | null,
    DNK?: string | null,
    DKN?: string | null,
    SPN?: string | null,
    JSN?: string | null,
    TSC?: string | null,
    JAN?: string | null,
    SHC?: string | null,
    MNK?: string | null,
    KKK?: string | null,
    ITN?: string | null,
    NOK?: number | null,
    BRC?: number | null,
    NHK?: number | null,
    CSC?: number | null,
    BLC?: number | null,
    }


  //Page State
  export type OutputDataSupplierTmpState = {
    //得意先
    tokuisakiList: CodeName[],
    accordionOpenSupplier: boolean,
    //部門
    buList: CodeName[],
    //倉庫
    centerList: CodeName[],
    //ベンダーコード
    supplierList: CodeName[],
    makerList: CodeName[],  //メーカー

    //検索する条件
    requestParamSupplier : RequestParam,
    //検索終了した条件
    retrievedIDs: string[],

    progress: Record<string, unknown>,
    datasSupplier: RowDataSupplier[],

    isIME: boolean,
    honbuIsIME: boolean,
    errorMessage: string | null,
  };

  export const initialState: OutputDataSupplierTmpState = {
    //得意先
    tokuisakiList: [],
    accordionOpenSupplier: true,
    //部門
    buList: [],
    //倉庫
    centerList: [],
    //ベンダーコード
    supplierList: [],
    makerList: [],

    requestParamSupplier : {},
    retrievedIDs: [],

    progress: {},
    datasSupplier: [],

    isIME: false,
    honbuIsIME: false,
    errorMessage: null,
  };

//Page Slice
export type OutputDataSupplierTmpReducer = {
  setTokuisakiList: (state: OutputDataSupplierTmpState, action: PayloadAction<CodeName[]>) => void,
  setAccordionOpenSupplier: (state: OutputDataSupplierTmpState, action: PayloadAction<boolean>) => void,
  setBuList: (state: OutputDataSupplierTmpState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state: OutputDataSupplierTmpState, action: PayloadAction<CodeName[]>) => void,
  setSupplierList: (state: OutputDataSupplierTmpState, action: PayloadAction<CodeName[]>) => void,
  setMakerList: (state: OutputDataSupplierTmpState, action: PayloadAction<CodeName[]>) => void,
  setRequestParamSupplier: (state: OutputDataSupplierTmpState, action: PayloadAction<RequestParam>) => void,
  setRetrievedID: (state: OutputDataSupplierTmpState, action: PayloadAction<string>) => void,
  addRetrievedID: (state: OutputDataSupplierTmpState, action: PayloadAction<string>) => void,
  putProgress: (state: OutputDataSupplierTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state: OutputDataSupplierTmpState, action: PayloadAction<string>) => void,
  searchedSupplier: (state: OutputDataSupplierTmpState, action: PayloadAction<{ param: RequestParam, datas: RowDataSupplier[] }>) => void,
  setDatasSupplier: (state: OutputDataSupplierTmpState, action: PayloadAction<RowDataSupplier[]>) => void,
  setIsIME: (state: OutputDataSupplierTmpState, action: PayloadAction<boolean>) => void,
  setHonbuIsIME: (state: OutputDataSupplierTmpState, action: PayloadAction<boolean>) => void,
  setErrorMessage: (state: OutputDataSupplierTmpState, action: PayloadAction<string>) => void,
}

const createReducerContent = (): OutputDataSupplierTmpReducer => {
  return {
    setTokuisakiList(state: OutputDataSupplierTmpState, action: PayloadAction<CodeName[]>) {
      state.tokuisakiList = action.payload;
    },
    setAccordionOpenSupplier(state: OutputDataSupplierTmpState, action: PayloadAction<boolean>) {
      state.accordionOpenSupplier = action.payload;
    },
    setBuList(state: OutputDataSupplierTmpState, action: PayloadAction<CodeName[]>) {
      state.buList = action.payload;
    },
    setCenterList(state: OutputDataSupplierTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    setSupplierList(state: OutputDataSupplierTmpState, action: PayloadAction<CodeName[]>) {
      state.supplierList = action.payload;
    },
    setMakerList(state: OutputDataSupplierTmpState, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },
    //検索条件
    setRequestParamSupplier(state: OutputDataSupplierTmpState, action: PayloadAction<RequestParam>) {
      action.payload.ymd = !action.payload.ymd ? null : moment(action.payload.ymd).format('YYYYMMDD');
      action.payload.ymdFrom = !action.payload.ymdFrom ? null : moment(action.payload.ymdFrom).format('YYYYMMDD');
      action.payload.shukkaYmd = !action.payload.shukkaYmd ? null : moment(action.payload.shukkaYmd).format('YYYYMMDD');
      action.payload.shukkaYmdFrom = !action.payload.shukkaYmdFrom ? null : moment(action.payload.shukkaYmdFrom).format('YYYYMMDD');
      state.requestParamSupplier = action.payload;
    },
    setRetrievedID(state: OutputDataSupplierTmpState, action: PayloadAction<string>) {
      state.retrievedIDs = [action.payload];
    },
    addRetrievedID(state: OutputDataSupplierTmpState, action: PayloadAction<string>) {
      const retrievedIDs = [...state.retrievedIDs];
      retrievedIDs.push(action.payload);
      state.retrievedIDs = retrievedIDs;
    },

    putProgress(state: OutputDataSupplierTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = { ...state.progress };
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state: OutputDataSupplierTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if (key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    //店舗別 売上明細
    searchedSupplier(state: OutputDataSupplierTmpState, action: PayloadAction<{ param: RequestParam, datas: RowDataSupplier[] }>) {
      //計算
      let datas = action.payload.datas;

      datas = parseDataSupplier(datas);
      datas = parseDataDenkName(datas);

      datas = doSortSupplier(datas);  //ソート
      datas = resetRowNoSupplier(datas); //列番号の振りなおし

      Object.assign(state, {
        datasSupplier: datas,
      });
    },
    setDatasSupplier(state: OutputDataSupplierTmpState, action: PayloadAction<RowDataSupplier[]>) {
      const datas = action.payload;
      Object.assign(state, {
        datasSupplier: datas,
      });
    },
    setIsIME(state: OutputDataSupplierTmpState, action: PayloadAction<boolean>) {
      state.isIME = action.payload;
    },
    setHonbuIsIME(state: OutputDataSupplierTmpState, action: PayloadAction<boolean>) {
      state.honbuIsIME = action.payload;
    },
    setErrorMessage(state: OutputDataSupplierTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    }

  }
};

const createSliceContent = (name: string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

  //数値のパース(数値が文字列で返ってくる)
  export const parseDataSupplier = (datas:RowDataSupplier[]): RowDataSupplier[] => {
    //set No.
    datas.forEach(data => {
      //ここに貼り付け
      if(typeof data.NOK === 'string') data.NOK = parseFloat(data.NOK);
      if(typeof data.BRC === 'string') data.BRC = parseFloat(data.BRC);
      if(typeof data.NHK === 'string') data.NHK = parseFloat(data.NHK);
      if(typeof data.CSC === 'string') data.CSC = parseFloat(data.CSC);
      if(typeof data.BLC === 'string') data.BLC = parseFloat(data.BLC);
    });
    return datas;
  }

  //伝区コードを伝区名に変換
  export const parseDataDenkName = (datas:RowDataSupplier[]): RowDataSupplier[] => {
    //set No.
    datas.forEach(data => {
      data.DKN = denkNames[data.DNK];
    });
    return datas;
  }

  //no振りなおし
  //no振りなおし
  export const resetRowNoSupplier = (datas:RowDataSupplier[]): RowDataSupplier[] => {
    let no = 1;
    datas = datas.map((row) => {
      return {
        ...row,
        no: "" + (no++),
      }
    });
    return datas;
  }

  //ソート
  export const doSortSupplier = (datas:RowDataSupplier[]): RowDataSupplier[] => {
    return datas;
  }

  //検索条件の複数指定判定
  export const getOptionLabel = (option: CodeName | string) => {
    if(typeof option == "string") {
      return option;
    }
    return option && option.name ? option.name : "";
}
export const getOptionLabelCenter = (option: CodeName | string) => {
  if(typeof option == "string") {
    return option;
  }
  return option && option.name ? option.name : "";
}
export const getOptionLabelSupplier = (option: CodeName | string) => {
  if(typeof option == "string") {
    return option;
  }
  return option ? option.code : "";
}

//Page Slice Export
//outputDataTokuisakiTmp
export const outputDataSupplierTmpSlice = createSliceContent("outputDataSupplierTmp");
export const outputDataStoreTmpSlice = createSliceContent("outputDataStoreTmp");
