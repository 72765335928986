import moment from 'moment';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/store/common";

import { KaihaiGroup, listSortOrders } from '@/store/kaihai/kaihaiCommon'
import * as kaihaiRetrieve from "@/assets/apitype/kaihaiRetrieve";
import { KaihaiColRowModel, rowKeys, headersRow, colDataType, rowDataType, mergeColKeys } from "@/components/kaihai/KaihaiTableModel";
import * as compareUtil from "@/util/compareUtil";
import * as arrayutil from "@/util/arrayUtil";
import * as editorUtil from "@/util/editorUtil";
import * as calcUtil from "@/util/calcUtil";

//0.00フォーマット
const formatterP00 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
//0,0フォーマット
const formatterN0 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 });

export type RowDataGroup = {
  kaihaiGroup: KaihaiGroup,
  maxkigenDatasSize?: number,

}

export type RowInfo = {
  TP: "item" | "group",
  no?: string,
  subno?: string,
  dataGroup:RowDataGroup,
  rowKey?: string,

  rowIndex?:number,  //同一集計行内でのrowKeyのindex
  rowIndexLast?:boolean, //同一集計行内での最後のrowKeyフラグ
}

//Page State
export type KaihaiTmpState = {
  accordionOpen: boolean,
  accordionInvalid: boolean,
  ymList: CodeName[],  //年月
  makerList: CodeName[],  //メーカー
  // bumonList: CodeName[], //所属
  // areaList: CodeName[], //エリア
  centerList: CodeName[], //倉庫

  progress: Record<string, unknown>,
  retrieveParam: kaihaiRetrieve.RequestParam,  //検索条件(検索済み)
  retrievedParam: kaihaiRetrieve.RequestParam,  //検索条件(検索済み)
  shareParam: kaihaiRetrieve.RequestParam,
  shareURL: string,  //共有URL

  dataGroupsAll: RowDataGroup[],
  dataGroups: RowDataGroup[],
  rowInfos: RowInfo[],
  fixedRowsTop :number,
  rows: any[][],
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[],

  lastUpdate: string | null,
  errorMessage: string | null,
};

export const initialState: KaihaiTmpState = {
  accordionOpen: true,
  accordionInvalid: false,
  ymList: [],
  makerList: [],
  // bumonList: [],
  // areaList: [],
  centerList: [],

  progress: {},
  retrieveParam: null,
  retrievedParam: null,
  shareParam: null,
  shareURL: '',  //共有URL

  dataGroupsAll: [],
  dataGroups: [],
  rowInfos: [],
  fixedRowsTop :0,
  rows: [],
  mergeCells: null,

  lastUpdate: null,
  errorMessage: null,
};

//Page Slice
export type KaihaiTmpReducer = {
  setAccordionInvalid: (state:KaihaiTmpState, action: PayloadAction<boolean>) => void,
  setAccordionOpen: (state:KaihaiTmpState, action: PayloadAction<boolean>) => void,
  setShareURL: (state: KaihaiTmpState, action: PayloadAction<string>) => void,
  setShareParam: (state: KaihaiTmpState, action: PayloadAction<kaihaiRetrieve.RequestParam>) => void,
  putProgress: (state:KaihaiTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state:KaihaiTmpState, action: PayloadAction<string>) => void,
  setErrorMessage: (state:KaihaiTmpState, action: PayloadAction<string>) => void,
  setYMList: (state:KaihaiTmpState, action: PayloadAction<CodeName[]>) => void,
  setMakerList: (state:KaihaiTmpState, action: PayloadAction<CodeName[]>) => void,
  // setBumonList: (state:KaihaiTmpState, action: PayloadAction<CodeName[]>) => void,
  // setAreaList: (state:KaihaiTmpState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state:KaihaiTmpState, action: PayloadAction<CodeName[]>) => void,
  setRetrieveParam: (state:KaihaiTmpState, action: PayloadAction<kaihaiRetrieve.RequestParam>) => void,
  setRetrievedParam: (state:KaihaiTmpState, action: PayloadAction<kaihaiRetrieve.RequestParam>) => void,

  searched: (state:KaihaiTmpState, action: PayloadAction<{param: kaihaiRetrieve.RequestParam, kaihaiGroups: KaihaiGroup[], colRowModel:KaihaiColRowModel, 
    listSortOrder:CodeName, listSortOrderDesc:boolean, 
  }>) => void,
  refreshTableWithFilter: (state:KaihaiTmpState, action: PayloadAction<{colRowModel:KaihaiColRowModel, 
    listSortOrder:CodeName, listSortOrderDesc:boolean, 
  }>) => void,
  refreshTable: (state:KaihaiTmpState, action: PayloadAction<{colRowModel:KaihaiColRowModel, 
    listSortOrder:CodeName, listSortOrderDesc:boolean}>) => void,
  setLastUpdate: (state:KaihaiTmpState, action: PayloadAction<string | null>) => void,
}

const createReducerContent = ():KaihaiTmpReducer => {return {
  setAccordionInvalid(state:KaihaiTmpState, action: PayloadAction<boolean>) {
      state.accordionInvalid = action.payload;
    },
    setAccordionOpen(state:KaihaiTmpState, action: PayloadAction<boolean>) {
      state.accordionOpen = action.payload;
    },
    setShareURL(state: KaihaiTmpState, action: PayloadAction<string>) {
      state.shareURL = action.payload;
    },
    setShareParam(state: KaihaiTmpState, action: PayloadAction<kaihaiRetrieve.RequestParam>) {
      state.shareParam = action.payload;
    },
    putProgress(state:KaihaiTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:KaihaiTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    setErrorMessage(state:KaihaiTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setYMList(state:KaihaiTmpState, action: PayloadAction<CodeName[]>) {
      state.ymList = action.payload;
    },
    setMakerList(state:KaihaiTmpState, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },
    // setBumonList(state:KaihaiTmpState, action: PayloadAction<CodeName[]>) {
    //   state.bumonList = action.payload;
    // },
    // setAreaList(state:KaihaiTmpState, action: PayloadAction<CodeName[]>) {
    //   state.areaList = action.payload;
    // },
    setCenterList(state:KaihaiTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    setRetrieveParam(state:KaihaiTmpState, action: PayloadAction<kaihaiRetrieve.RequestParam>) {
      state.retrieveParam = action.payload;
    },
    setRetrievedParam(state:KaihaiTmpState, action: PayloadAction<kaihaiRetrieve.RequestParam>) {
      state.retrievedParam = action.payload;
    },

    searched(state:KaihaiTmpState, action: PayloadAction<{param: kaihaiRetrieve.RequestParam, kaihaiGroups: KaihaiGroup[], colRowModel:KaihaiColRowModel, 
      listSortOrder:CodeName, listSortOrderDesc:boolean, 
    }>) {
      const colRowModel = action.payload.colRowModel;
      const param = action.payload.param;

      let kaihaiGroups = parseDataKaihaiGroup(action.payload.kaihaiGroups);

      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      
      let dataGroupsAll = convertDataGroups(kaihaiGroups);
      dataGroupsAll = calcDataGroups(dataGroupsAll);

      let dataGroups = filterDataGroups(dataGroupsAll);
      dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop] = convertRowInfos(dataGroups, listSortOrder);
      
      //store更新
      state.dataGroupsAll = dataGroupsAll;
      state.dataGroups = dataGroups;
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.mergeCells = createMergeCells(rowInfos, colRowModel);
      state.rows = convertRows(rowInfos, colRowModel);
    },
    refreshTableWithFilter(state:KaihaiTmpState, action: PayloadAction<{colRowModel:KaihaiColRowModel, 
      listSortOrder:CodeName, listSortOrderDesc:boolean, 
    }>){
      console.log('refreshTableWithFilter');
      if(!state.dataGroupsAll || state.dataGroupsAll.length == 0) {
        return;
      }
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const colRowModel = action.payload.colRowModel;

      let dataGroupsAll = state.dataGroupsAll;
      let dataGroups = filterDataGroups(dataGroupsAll);
      dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop] = convertRowInfos(dataGroups, listSortOrder);
      //store更新
      state.dataGroups = dataGroups;
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.mergeCells = createMergeCells(rowInfos, colRowModel);
      state.rows = convertRows(rowInfos, colRowModel);
    },
    refreshTable(state:KaihaiTmpState, action: PayloadAction<{colRowModel:KaihaiColRowModel, 
      listSortOrder:CodeName, listSortOrderDesc:boolean
      }>){
      console.log('refreshTable');
      if(!state.dataGroups || state.dataGroups.length == 0) {
        return;
      }
      const listSortOrder = action.payload.listSortOrder;
      const listSortOrderDesc = action.payload.listSortOrderDesc;
      const colRowModel = action.payload.colRowModel;

      let dataGroups = state.dataGroups;
      dataGroups = sortDataGroups(dataGroups, listSortOrder, listSortOrderDesc);
      const [rowInfos, fixedRowsTop] = convertRowInfos(dataGroups, listSortOrder);
      //store更新
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.mergeCells = createMergeCells(rowInfos, colRowModel);
      state.rows = convertRows(rowInfos, colRowModel);
    },
    setLastUpdate(state:KaihaiTmpState, action: PayloadAction<string | null>) {
      state.lastUpdate = action.payload;
    },

}};

const parseDataKaihaiGroup = (datas:KaihaiGroup[]): KaihaiGroup[] => {
  datas = [...datas];
  datas.forEach((data) => {
    // if(typeof data.termStartDate === 'string') data.blIrisu = parseInt(data.blIrisu);
    // if(typeof data.termEndDate === 'string') data.blIrisu = parseInt(data.blIrisu);

    // if(typeof data.blIrisu === 'string') data.blIrisu = parseInt(data.blIrisu);
    // if(typeof data.csIrisu === 'string') data.csIrisu = parseInt(data.csIrisu);
    // if(typeof data.bestBefore === 'string') data.bestBefore = parseInt(data.bestBefore);
    // if(typeof data.teika === 'string') data.teika = parseInt(data.teika);
    // if(typeof data.tatene === 'string') data.tatene = parseFloat(data.tatene);

    // if(typeof data.zaikoQty1 === 'string') data.zaikoQty1 = parseFloat(data.zaikoQty1);
    // if(typeof data.zaikoQty2 === 'string') data.zaikoQty2 = parseFloat(data.zaikoQty2);
    // if(typeof data.zaikoQty3 === 'string') data.zaikoQty3 = parseFloat(data.zaikoQty3);
    // if(typeof data.zaikoQty4 === 'string') data.zaikoQty4 = parseFloat(data.zaikoQty4);
    // if(typeof data.zaikoQty5 === 'string') data.zaikoQty5 = parseFloat(data.zaikoQty5);
  });
  return datas;
}
//RowDataGroupに変換
const convertDataGroups = (kaihaiGroups: KaihaiGroup[]): RowDataGroup[] => {
  // //商品、センター順でソート
  // kaihaiGroups = kaihaiGroups.sort((a,b) => {
  //   let comp = 0;
  //   comp = compareUtil.compareString(a.itemCD, b.itemCD, true)
  //   if(comp == 0) {
  //     comp = compareUtil.compareString(a.centerCD, b.centerCD, true)
  //   }
  //   return comp;
  // });

  // //返品だけは在庫から取得する
  // kaihaiGroups.filter(kaihaiGroup => kaihaiGroup.zaikoQty5).forEach(kaihaiGroup => {
  //   const key = `${kaihaiGroup.itemCD} ${kaihaiGroup.centerCD}`;
  // });

  return kaihaiGroups.map(kaihaiGroup => {
    return {
      kaihaiGroup: kaihaiGroup,
      maxkigenDatasSize: 1,
    };
  });
}
//フィルタ
const filterDataGroups = (dataGroups:RowDataGroup[]): RowDataGroup[] => {
    return dataGroups;
}
//並び順変更
const sortDataGroups = (dataGroups:RowDataGroup[], listSortOrder:CodeName, listSortOrderDesc:boolean): RowDataGroup[] => {
  if(!dataGroups) {
    return dataGroups;
  }
  let asc = !listSortOrderDesc;
  let getSortKey1 = (o:RowDataGroup):string|number => 
    !o || !o.kaihaiGroup || !listSortOrder ? null : 
    o.kaihaiGroup[listSortOrder.option1]
  ;
  let getSortKey2 = (o:RowDataGroup):string|number => 
    !o || !o.kaihaiGroup || !listSortOrder ? null : 
    o.kaihaiGroup[listSortOrder.option2]
  ;
  let getSortKey3 = (o:RowDataGroup):string|number => 
    !o || !o.kaihaiGroup || !listSortOrder ? null : 
    o.kaihaiGroup[listSortOrder.option3]
  ;
  let getSortKey4 = (o:RowDataGroup):string|number => 
    !o || !o.kaihaiGroup || !listSortOrder ? null : 
    o.kaihaiGroup[listSortOrder.option4]
  ;
  dataGroups.sort((a, b) => {
    //第1弾ソート項目
    let va = getSortKey1(a);
    let vb = getSortKey1(b);
    let comp = compareUtil.compareAny(va, vb, asc);
    //第2弾ソート項目
    if(comp == 0) {
      va = getSortKey2(a);
      vb = getSortKey2(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    //第3弾ソート項目
    if(comp == 0) {
      va = getSortKey3(a);
      vb = getSortKey3(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    //第4弾ソート項目
    if(comp == 0) {
      va = getSortKey4(a);
      vb = getSortKey4(b);
      comp = compareUtil.compareAny(va, vb, asc);
    }
    return comp;
  });
  return dataGroups;
}
//RowDataGroupの計算
const calcDataGroups = (dataGroups:RowDataGroup[]): RowDataGroup[] => {
  /*
  //賞味期限別を合計する
  const sumKigenDatas = (kigenDatas:KigenData[]): KigenData => {
    const kigenDataTotal:KigenData = {
      zaikoQty: 0,
    };
    if(kigenDatas && kigenDatas.length > 0) {
      kigenDatas.forEach(kigenData => {
        kigenDataTotal.zaikoQty = calcUtil.plus(kigenDataTotal.zaikoQty, kigenData.zaikoQty);
      });
    }
    return kigenDataTotal;
  }
  ;

  return dataGroups.map(dataGroup => {
    dataGroup.kigenDataTotal1 = sumKigenDatas(dataGroup.kigenDatas1);
    dataGroup.kigenDataTotal2 = sumKigenDatas(dataGroup.kigenDatas2);
    dataGroup.kigenDataTotal3 = sumKigenDatas(dataGroup.kigenDatas3);
    dataGroup.kigenDataTotal4 = sumKigenDatas(dataGroup.kigenDatas4);
    dataGroup.kigenDataTotal5 = sumKigenDatas(dataGroup.kigenDatas5);
    return dataGroup;
  });
  */
  //在庫の合計を利用する
  return dataGroups.map(dataGroup => {
    return dataGroup;
  });
}

//行情報に変換
const convertRowInfos = (dataGroups:RowDataGroup[], listSortOrder:CodeName): [RowInfo[], number] => {
  const targetRowsKeys = rowKeys;

  const rowInfos:RowInfo[] = [];
  let fixedRowsTop:number = 0;

  //明細行
  fixedRowsTop = rowInfos.length;

  let beforeDataGroup:RowDataGroup;
  let no = 0;
  let subno = 0;
  dataGroups.forEach(dataGroup => {
    if(rowInfos.length > 0) {
      rowInfos[rowInfos.length-1].rowIndexLast = true; //同一集計行内での最後のrowKeyフラグ
    }

    // グルーピング
    {
      no++;
      subno = 0;
      rowInfos.push({
        TP: "group",
        no: `${no}`,
        dataGroup: dataGroup,
      });
    }

    //明細行
    let rowIndex = 0;
    dataGroup.kaihaiGroup.items?.forEach(item => {
      subno++;
      rowInfos.push({
        ...{
          TP: "item",
          no: `${no}`,
          subno: `${subno}`,
          dataGroup: dataGroup,
        }, 
        ...{
          rowKey: `${subno}`,
  
          rowIndex: rowIndex++,  //同一集計行内でのrowKeyのindex
          rowIndexLast: false, //同一集計行内での最後のrowKeyフラグ（仮置き）
        }
      });
    });


    beforeDataGroup = dataGroup;
  });
  if(rowInfos.length > 0) {
    rowInfos[rowInfos.length-1].rowIndexLast = true; //同一集計行内での最後のrowKeyフラグ
  }

  return [rowInfos, fixedRowsTop];
}
//配列データに変換
const convertRows = (rowInfos:RowInfo[], colRowModel:KaihaiColRowModel): any[][] => {
  return rowInfos.map((rowInfo) => convertRow(rowInfo, colRowModel));
}
//配列データに変換
const convertRow = (rowInfo:RowInfo, colRowModel:KaihaiColRowModel): any[] => {
  //set No.
  const dataGroup:RowDataGroup = rowInfo.dataGroup;
  return colRowModel.colKeys.map(colKey => {

    //グループ行
    if(rowInfo.TP == "group") {
      switch (colKey) {
        case "no":
          return rowInfo.no;
        case "beforeSyubaiDate" : 
          return dataGroup.kaihaiGroup?.beforeSyubaiDate ? moment(dataGroup.kaihaiGroup?.beforeSyubaiDate).format('YY/MM/DD') : null;
        case "beforeJan" : 
          return dataGroup.kaihaiGroup?.beforeJan;
        case "afterHatsubaiDate" : 
          return dataGroup.kaihaiGroup?.afterHatsubaiDate ? moment(dataGroup.kaihaiGroup?.afterHatsubaiDate).format('YY/MM/DD') : null;
        case "afterJan" : 
          return dataGroup.kaihaiGroup?.afterJan;
        case "rnk" : 
          // return dataGroup.kaihaiGroup?.rnk;
          return null;
        case "beforeItemCd" : 
          // return dataGroup.kaihaiGroup?.beforeItemCd;
          return null;
        case "beforeMakerName" : 
          return dataGroup.kaihaiGroup?.beforeMakerName;
        case "beforeItemName" : 
          return dataGroup.kaihaiGroup?.beforeItemName;
        case "beforeKikaku" : 
          return dataGroup.kaihaiGroup?.beforeKikaku;
        case "beforeIrisu" : 
          return dataGroup.kaihaiGroup?.beforeIrisu;
        case "beforeJodai" : 
          return dataGroup.kaihaiGroup?.beforeJodai;
        case "beforeSyubaiFlg" : 
          return dataGroup.kaihaiGroup?.beforeSyubaiFlg ? '〇' : null;
        case "beforeJyodaiFlg" : 
          return dataGroup.kaihaiGroup?.beforeJyodaiFlg ? '〇' : null;
        case "beforeKikakuFlg" : 
          return dataGroup.kaihaiGroup?.beforeKikakuFlg ? '〇' : null;
        case "beforeNyusatsuFlg" : 
          return dataGroup.kaihaiGroup?.beforeNyusatsuFlg ? '〇' : null;
        case "beforeBikou" : 
          return dataGroup.kaihaiGroup?.beforeBikou;
        case "afterItemCd" : 
          // return dataGroup.kaihaiGroup?.afterItemCd;
          return null;
        case "afterMakerName" : 
          return dataGroup.kaihaiGroup?.afterMakerName;
        case "afterItemName" : 
          return dataGroup.kaihaiGroup?.afterItemName;
        case "afterKikaku" : 
          return dataGroup.kaihaiGroup?.afterKikaku;
        case "afterIrisu" : 
          return dataGroup.kaihaiGroup?.afterIrisu;
        case "afterJodai" : 
          return dataGroup.kaihaiGroup?.afterJodai;
        case "afterJanhenFlg" : 
          return dataGroup.kaihaiGroup?.afterJanhenFlg ? '〇' : null;
        case "afterBikou" : 
          return dataGroup.kaihaiGroup?.afterBikou;
        case "createDate" : 
          return dataGroup.kaihaiGroup?.createDate ? moment(dataGroup.kaihaiGroup?.createDate).format('YY/MM/DD HH:mm') : null;
        case "createUserid" : 
          return dataGroup.kaihaiGroup?.createUserid;
        case "createUsername" : 
          return dataGroup.kaihaiGroup?.createUsername;
        case "updateDate" : 
          return dataGroup.kaihaiGroup?.updateDate ? moment(dataGroup.kaihaiGroup?.updateDate).format('YY/MM/DD HH:mm') : null;
        case "updateUserid" : 
          return dataGroup.kaihaiGroup?.updateUserid;
        case "updateUsername" : 
          return dataGroup.kaihaiGroup?.updateUsername;
       default: {
          return null;
        }
      }
    }
    else {
      //明細行
      switch (colKey) {
        case "no":
          return `${rowInfo.no}-${rowInfo.subno}`;
        // case "beforeSyubaiDate" : 
        //   return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeSyubaiDate;
        // case "beforeJan" : 
        //   return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeJan;
        // case "afterHatsubaiDate" : 
        //   return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterHatsubaiDate;
        // case "afterJan" : 
        //   return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterJan;
        case "rnk" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.rnk;
        case "beforeItemCd" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeItemCd;
        case "beforeMakerName" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeMakerName;
        case "beforeItemName" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeItemName;
        case "beforeKikaku" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeKikaku;
        case "beforeIrisu" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeIrisu;
        case "beforeJodai" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeJodai;
        case "beforeSyubaiFlg" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeSyubaiFlg;
          return null;
        case "beforeJyodaiFlg" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeJyodaiFlg;
          return null;
        case "beforeKikakuFlg" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeKikakuFlg;
          return null;
        case "beforeNyusatsuFlg" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeNyusatsuFlg;
          return null;
        case "beforeBikou" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeBikou;
          return null;
        case "afterItemCd" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterItemCd;
        case "afterMakerName" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterMakerName;
        case "afterItemName" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterItemName;
        case "afterKikaku" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterKikaku;
        case "afterIrisu" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterIrisu;
        case "afterJodai" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterJodai;
        case "afterJanhenFlg" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterJanhenFlg;
          return null;
        case "afterBikou" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterBikou;
          return null;
        case "createDate" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.createDate;
          return null;
        case "createUserid" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.createUserid;
          return null;
        case "createUsername" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.createUsername;
          return null;
        case "updateDate" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.updateDate;
          return null;
        case "updateUserid" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.updateUserid;
          return null;
        case "updateUsername" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.updateUsername;
          return null;
        default: {
          return dataGroup[colKey];
        }

      }

    }
  });
}

//マージを作成
const createMergeCells = (rowInfos:RowInfo[], colRowModel:KaihaiColRowModel): {row: number, col: number, rowspan: number, colspan: number}[] => {
  let mergeCells:{row: number, col: number, rowspan: number, colspan: number}[] = [];
  mergeColKeys.forEach(colKey => {
    let col = colRowModel.colFromKey(colKey);
    rowInfos.map((rowInfo, index) => {
      if(rowInfo?.TP == 'group' && rowInfo.dataGroup.kaihaiGroup?.items?.length) {
        mergeCells.push({row: index, col: col, rowspan: rowInfo.dataGroup.kaihaiGroup?.items?.length + 1, colspan:1});
      }
    });
  });
  return mergeCells.length == 0 ? null : mergeCells;
}


export const getOptionLabel = (option: CodeName | string) => {
  if(typeof option == "string") {
    return option;
  }
  return option && option.name ? (option.code + ' ' + option.name) : "";
}

const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

//Page Slice Export
//KaihaiTmp
export const kaihaiTmpSlice = createSliceContent("KaihaiTmp");
