import { CodeName } from "@/store/common";

//検索画面用
export type KaihaiGroup = {
  beforeSyubaiDate?: Date|null,
  beforeJan?: string|null,
  afterHatsubaiDate?: Date|null,
  afterJan?: string|null,
  // rnk?: number|null,
  // beforeItemCd?: string|null,
  beforeMakerName?: string|null,
  beforeItemName?: string|null,
  beforeKikaku?: string|null,
  beforeIrisu?: string|null,
  beforeJodai?: number|null,
  beforeSyubaiFlg?: boolean|null,
  beforeJyodaiFlg?: boolean|null,
  beforeKikakuFlg?: boolean|null,
  beforeNyusatsuFlg?: boolean|null,
  beforeBikou?: string|null,
  // afterItemCd?: string|null,
  afterMakerName?: string|null,
  afterItemName?: string|null,
  afterKikaku?: string|null,
  afterIrisu?: string|null,
  afterJodai?: number|null,
  afterJanhenFlg?: boolean|null,
  afterBikou?: string|null,
  createDate?: Date|null,
  createUserid?: string|null,
  createUsername?: string|null,
  updateDate?: Date|null,
  updateUserid?: string|null,
  updateUsername?: string|null,

  items?: KaihaiItem[]|null,
}

export type KaihaiItem = {
  beforeSyubaiDate?: Date|null,
  beforeJan?: string|null,
  afterHatsubaiDate?: Date|null,
  afterJan?: string|null,
  rnk?: number|null,
  beforeItemFlg?: boolean|null,
  beforeItemCd?: string|null,
  beforeMakerName?: string|null,
  beforeItemName?: string|null,
  beforeKikaku?: string|null,
  beforeIrisu?: string|null,
  beforeJodai?: number|null,
  // beforeSyubaiFlg?: boolean|null,
  // beforeJyodaiFlg?: boolean|null,
  // beforeKikakuFlg?: boolean|null,
  // beforeNyusatsuFlg?: boolean|null,
  // beforeBikou?: string|null,
  afterItemFlg?: boolean|null,
  afterItemCd?: string|null,
  afterMakerName?: string|null,
  afterItemName?: string|null,
  afterKikaku?: string|null,
  afterIrisu?: string|null,
  afterJodai?: number|null,
  // afterJanhenFlg?: boolean|null,
  // afterBikou?: string|null,
  // createDate?: Date|null,
  // createUserid?: string|null,
  // createUsername?: string|null,
  // updateDate?: Date|null,
  // updateUserid?: string|null,
  // updateUsername?: string|null,
}

//Excel取込用
export type KaihaiImportExcelGroup = {
  // uuid?: string,

  targetFlg?: boolean|null,
  checkMessage?: string|null,
  checkMessageType?: 'info' | 'warn' | 'error' | 'success' | string,

  filename: string,
  sheetname: string,
  rowNo: number,

  beforeSyubaiDate?: Date|null,
  beforeJan?: string|null,
  afterHatsubaiDate?: Date|null,
  afterJan?: string|null,
  // rnk?: number|null,
  // beforeItemCd?: string|null,
  beforeMakerName?: string|null,
  beforeItemName?: string|null,
  beforeKikaku?: string|null,
  beforeIrisu?: string|null,
  beforeJodai?: number|null,
  beforeSyubaiFlg?: boolean|null,
  beforeJyodaiFlg?: boolean|null,
  beforeKikakuFlg?: boolean|null,
  beforeNyusatsuFlg?: boolean|null,
  beforeBikou?: string|null,
  // afterItemCd?: string|null,
  afterMakerName?: string|null,
  afterItemName?: string|null,
  afterKikaku?: string|null,
  afterIrisu?: string|null,
  afterJodai?: number|null,
  afterJanhenFlg?: boolean|null,
  afterBikou?: string|null,
  createDate?: Date|null,
  createUserid?: string|null,
  createUsername?: string|null,
  updateDate?: Date|null,
  updateUserid?: string|null,
  updateUsername?: string|null,

  items?: KaihaiItem[]|null,
}

//並び順
export const listSortOrders:CodeName[] = [
  new CodeName({group_code:"before", group_name:"【変更前/廃止商品】", code: "beforeSyubaiDate", name: "変更前 - 終売日", option1: "beforeSyubaiDate", option2:"", option3: "beforeSyubaiDate", option4: "beforeJan"}),
  new CodeName({group_code:"before", group_name:"【変更前/廃止商品】", code: "beforeJan", name: "変更前 - JANコード", option1: "beforeJan", option2:"", option3: "beforeSyubaiDate", option4: "beforeJan"}),
  // new CodeName({group_code:"before", group_name:"【変更前/廃止商品】", code: "beforeItemCd", name: "変更前 - 商品CD", option1: "beforeItemCd", option2:"", option3: "beforeSyubaiDate", option4: "beforeJan"}),
  new CodeName({group_code:"before", group_name:"【変更前/廃止商品】", code: "beforeMakerName", name: "変更前 - メーカー名", option1: "beforeMakerName", option2:"", option3: "beforeSyubaiDate", option4: "beforeJan"}),
  new CodeName({group_code:"before", group_name:"【変更前/廃止商品】", code: "beforeItemName", name: "変更前 - 商品名", option1: "beforeItemName", option2:"", option3: "beforeSyubaiDate", option4: "beforeJan"}),
  new CodeName({group_code:"before", group_name:"【変更前/廃止商品】", code: "beforeKikaku", name: "変更前 - 規格", option1: "beforeKikaku", option2:"", option3: "beforeSyubaiDate", option4: "beforeJan"}),
  new CodeName({group_code:"before", group_name:"【変更前/廃止商品】", code: "beforeIrisu", name: "変更前 - 入数", option1: "beforeIrisu", option2:"", option3: "beforeSyubaiDate", option4: "beforeJan"}),
  new CodeName({group_code:"before", group_name:"【変更前/廃止商品】", code: "beforeJodai", name: "変更前 - 上代", option1: "beforeJodai", option2:"", option3: "beforeSyubaiDate", option4: "beforeJan"}),

  new CodeName({group_code:"after", group_name:"【変更後/新商品】", code: "afterSyubaiDate", name: "変更後 - 終売日", option1: "afterSyubaiDate", option2:"", option3: "afterSyubaiDate", option4: "afterJan"}),
  new CodeName({group_code:"after", group_name:"【変更後/新商品】", code: "afterJan", name: "変更後 - JANコード", option1: "afterJan", option2:"", option3: "afterSyubaiDate", option4: "afterJan"}),
  // new CodeName({group_code:"after", group_name:"【変更後/新商品】", code: "afterItemCd", name: "変更後 - 商品CD", option1: "afterItemCd", option2:"", option3: "afterSyubaiDate", option4: "afterJan"}),
  new CodeName({group_code:"after", group_name:"【変更後/新商品】", code: "afterMakerName", name: "変更後 - メーカー名", option1: "afterMakerName", option2:"", option3: "afterSyubaiDate", option4: "afterJan"}),
  new CodeName({group_code:"after", group_name:"【変更後/新商品】", code: "afterItemName", name: "変更後 - 商品名", option1: "afterItemName", option2:"", option3: "afterSyubaiDate", option4: "afterJan"}),
  new CodeName({group_code:"after", group_name:"【変更後/新商品】", code: "afterKikaku", name: "変更後 - 規格", option1: "afterKikaku", option2:"", option3: "afterSyubaiDate", option4: "afterJan"}),
  new CodeName({group_code:"after", group_name:"【変更後/新商品】", code: "afterIrisu", name: "変更後 - 入数", option1: "afterIrisu", option2:"", option3: "afterSyubaiDate", option4: "afterJan"}),
  new CodeName({group_code:"after", group_name:"【変更後/新商品】", code: "afterJodai", name: "変更後 - 上代", option1: "afterJodai", option2:"", option3: "afterSyubaiDate", option4: "afterJan"}),

  new CodeName({group_code:"create", group_name:"【作成情報】", code: "createDate", name: "作成日時", option1: "createDate", option2:"", option3: "", option4: "createDate"}),
  new CodeName({group_code:"create", group_name:"【作成情報】", code: "createUsername", name: "作成者", option1: "createUsername", option2:"", option3: "", option4: "createUsername"}),
  new CodeName({group_code:"update", group_name:"【更新情報】", code: "updateDate", name: "更新日時", option1: "updateDate", option2:"", option3: "", option4: "updateDate"}),
  new CodeName({group_code:"update", group_name:"【更新情報】", code: "updateUsername", name: "更新者", option1: "updateUsername", option2:"", option3: "", option4: "updateUsername"}),

];

export const optionAll = new CodeName({ code: '', name: '全て' });
