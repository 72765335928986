import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//テーブルの各列のキー
export const colKeys: string[] = [
  'no',

  'beforeSyubaiDate',
  'beforeJan',
  'beforeMakerName',
  'beforeItemName',
  'beforeKikaku',
  'beforeIrisu',
  'beforeJodai',
  'beforeSyubaiFlg',
  'beforeJyodaiFlg',
  'beforeKikakuFlg',
  'beforeNyusatsuFlg',
  'beforeBikou',

  'afterHatsubaiDate',
  'afterJan',
  'afterMakerName',
  'afterItemName',
  'afterKikaku',
  'afterIrisu',
  'afterJodai',
  'afterJanhenFlg',
  'afterBikou',
];

export const headersCol = [
  {
    no: "No.",

    beforeSyubaiDate: "変更前/廃止商品",
    beforeJan: "変更前/廃止商品",
    beforeMakerName: "変更前/廃止商品",
    beforeItemName: "変更前/廃止商品",
    beforeKikaku: "変更前/廃止商品",
    beforeIrisu: "変更前/廃止商品",
    beforeJodai: "変更前/廃止商品",
    beforeSyubaiFlg: "変更理由",
    beforeJyodaiFlg: "変更理由",
    beforeKikakuFlg: "変更理由",
    beforeNyusatsuFlg: "変更理由",
    beforeBikou: "　",
   
    afterHatsubaiDate: "変更後/新商品",
    afterJan: "変更後/新商品",
    afterMakerName: "変更後/新商品",
    afterItemName: "変更後/新商品",
    afterKikaku: "変更後/新商品",
    afterIrisu: "変更後/新商品",
    afterJodai: "変更後/新商品",
    afterJanhenFlg: "変更後/新商品",
    afterBikou: "変更後/新商品",
  },
  {
    no: "",

    beforeSyubaiDate: "終売日",
    beforeJan: "JANコード",
    beforeMakerName: "メーカー名",
    beforeItemName: "商品名",
    beforeKikaku: "規格",
    beforeIrisu: "入数",
    beforeJodai: "上代",
    beforeSyubaiFlg: "終売",
    beforeJyodaiFlg: "上代",
    beforeKikakuFlg: "規格",
    beforeNyusatsuFlg: "入数",
    beforeBikou: "備考",

    afterHatsubaiDate: "発売日",
    afterJan: "JANコード",
    afterMakerName: "メーカー名",
    afterItemName: "商品名",
    afterKikaku: "規格",
    afterIrisu: "入数",
    afterJodai: "上代",
    afterJanhenFlg: "JAN変",
    afterBikou: "備考",
  },
];


export const headerColClasses = [
  {
    no: "kaihai_H",

    beforeSyubaiDate: "kaihai_H_before",
    beforeJan: "kaihai_H_before",
    beforeMakerName: "kaihai_H_before",
    beforeItemName: "kaihai_H_before",
    beforeKikaku: "kaihai_H_before",
    beforeIrisu: "kaihai_H_before",
    beforeJodai: "kaihai_H_before",
    beforeSyubaiFlg: "kaihai_H_before",
    beforeJyodaiFlg: "kaihai_H_before",
    beforeKikakuFlg: "kaihai_H_before",
    beforeNyusatsuFlg: "kaihai_H_before",
    beforeBikou: "kaihai_H_before",

    afterHatsubaiDate: "kaihai_H_after",
    afterJan: "kaihai_H_after",
    afterMakerName: "kaihai_H_after",
    afterItemName: "kaihai_H_after",
    afterKikaku: "kaihai_H_after",
    afterIrisu: "kaihai_H_after",
    afterJodai: "kaihai_H_after",
    afterJanhenFlg: "kaihai_H_after",
    afterBikou: "kaihai_H_after",
  },
  {
    no: "kaihai_H",

    beforeSyubaiDate: "kaihai_H_before",
    beforeJan: "kaihai_H_before",
    beforeMakerName: "kaihai_H_before",
    beforeItemName: "kaihai_H_before",
    beforeKikaku: "kaihai_H_before",
    beforeIrisu: "kaihai_H_before",
    beforeJodai: "kaihai_H_before",
    beforeSyubaiFlg: "kaihai_H_before",
    beforeJyodaiFlg: "kaihai_H_before",
    beforeKikakuFlg: "kaihai_H_before",
    beforeNyusatsuFlg: "kaihai_H_before",
    beforeBikou: "kaihai_H_before",

    afterHatsubaiDate: "kaihai_H_after",
    afterJan: "kaihai_H_after",
    afterMakerName: "kaihai_H_after",
    afterItemName: "kaihai_H_after",
    afterKikaku: "kaihai_H_after",
    afterIrisu: "kaihai_H_after",
    afterJodai: "kaihai_H_after",
    afterJanhenFlg: "kaihai_H_after",
    afterBikou: "kaihai_H_after",
  },
];

export const colWidths =
{
  no: 50,

  beforeSyubaiDate: 100,
  beforeJan: 120,
  beforeMakerName: 70,
  beforeItemName: 120,
  beforeKikaku: 70,
  beforeIrisu: 70,
  beforeJodai: 70,
  beforeSyubaiFlg: 70,
  beforeJyodaiFlg: 70,
  beforeKikakuFlg: 70,
  beforeNyusatsuFlg: 70,
  beforeBikou: 120,

  afterHatsubaiDate: 100,
  afterJan: 120,
  afterMakerName: 70,
  afterItemName: 120,
  afterKikaku: 70,
  afterIrisu: 70,
  afterJodai: 70,
  afterJanhenFlg: 70,
  afterBikou: 120,
};

export const colDataType =
{
  no: { type: 'text', readOnly: true, },

  beforeSyubaiDate: { type: 'date', dateFormat: 'YY/MM/DD', datePickerConfig: { numberOfMonths: 2, minDate: moment().startOf('day').toDate(), yearSuffix: '年', showMonthAfterYear: true, showDaysInNextAndPreviousMonths: true, i18n: { previousMonth: '前月', nextMonth: '次月', months: moment.months(), weekdays: moment.weekdays(), weekdaysShort: moment.weekdaysShort() } }, readOnly: false, className: 'htCenter' },
  beforeJan: { type: 'text', readOnly: false, className: 'htLeft', },
  beforeMakerName: { type: 'text', readOnly: false, className: 'htLeft', },
  beforeItemName: { type: 'text', readOnly: false, className: 'htLeft', },
  beforeKikaku: { type: 'text', readOnly: false, className: 'htLeft', },
  beforeIrisu: { type: 'text', readOnly: false, className: 'htLeft', },
  beforeJodai: { type: 'numeric', readOnly: false, numericFormat: { pattern: '0,0', }, },
  beforeSyubaiFlg: { type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter' },
  beforeJyodaiFlg: { type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter' },
  beforeKikakuFlg: { type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter' },
  beforeNyusatsuFlg: { type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter' },
  beforeBikou: { type: 'text', readOnly: false, className: 'htLeft', },

  afterHatsubaiDate: { type: 'date', dateFormat: 'YY/MM/DD', datePickerConfig: { numberOfMonths: 2, minDate: moment().startOf('day').toDate(), yearSuffix: '年', showMonthAfterYear: true, showDaysInNextAndPreviousMonths: true, i18n: { previousMonth: '前月', nextMonth: '次月', months: moment.months(), weekdays: moment.weekdays(), weekdaysShort: moment.weekdaysShort() } }, readOnly: false, className: 'htCenter' },
  afterJan: { type: 'text', readOnly: false, className: 'htLeft', },
  afterMakerName: { type: 'text', readOnly: false, className: 'htLeft', },
  afterItemName: { type: 'text', readOnly: false, className: 'htLeft', },
  afterKikaku: { type: 'text', readOnly: false, className: 'htLeft', },
  afterIrisu: { type: 'text', readOnly: false, className: 'htLeft', },
  afterJodai: { type: 'numeric', readOnly: false, numericFormat: { pattern: '0,0', }, },
  afterJanhenFlg: { type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter' },
  afterBikou: { type: 'text', readOnly: false, className: 'htLeft', },
};

// export const rowDataType = {};

// export const rowRendererName = {};

//列定義情報（動的パラメータ）
interface KaihaiHandyImportColRowModelInterface {
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[],
  //テーブルの各行のキー
  rowKeys: string[],
}

export class KaihaiHandyImportColRowModel implements KaihaiHandyImportColRowModelInterface {
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  //テーブルの各列のキー
  rowKeys: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  nestedHeadersBackup; //any[][]
  //ヘッダー区切り
  nestedHeadersGroupIndex: number[];
  //テーブルの各列の逆引き用マップ
  colKeysMap;
  //テーブルの各行の逆引き用マップ
  rowKeysMap;

  constructor(param: KaihaiHandyImportColRowModelInterface) {
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;
    this.rowKeys = param.rowKeys;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;
    //テーブルの各行のキー逆引き
    const rowKeysMap = {};
    param.rowKeys.forEach((rowKey, index) => {
      rowKeysMap[rowKey] = index;
    });
    this.rowKeysMap = rowKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    const nestedHeadersGroupIndex = [];
    // const customBorders = [];
    headersCol.forEach((header, index) => {
      const lastHeader = index == headersCol.length - 1;
      const nestedHeader = [];

      let colspan = 0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey];
        if (lastHeader) {
          nestedHeader.push(headerVal);
        }
        else {
          if (preHeaderVal !== null && preHeaderVal != headerVal) {
            nestedHeader.push(colspan == 1 ? preHeaderVal : { label: preHeaderVal, colspan: colspan });
            colspan = 0;
          }
          colspan++;
          preHeaderVal = headerVal;
        }
        //0行目でグループ
        if (index == 0) {
          nestedHeadersGroupIndex.push(colspan - 1);
        }
      });
      if (!lastHeader && colspan > 0) {
        nestedHeader.push(colspan == 1 ? preHeaderVal : { label: preHeaderVal, colspan: colspan });
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
    this.nestedHeadersBackup = nestedHeaders.map(nestedHeadersSub => [...nestedHeadersSub]);
    this.nestedHeadersGroupIndex = nestedHeadersGroupIndex;
  }

  //function
  keyFromCol(column: number): string {
    return this.colKeys[column];
  }
  keyFromCols(columns: number[]): string[] {
    const vals: string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey: string): number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys: string[]): number[] {
    const vals: number[] = [];
    colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    return vals;
  }
  //function
  keyFromRow(row: number): string {
    const rowKeys = this.rowKeys;
    return rowKeys[row];
  }
  keyFromRows(rows: number[]): string[] {
    const rowKeys = this.rowKeys;
    const vals: string[] = [];
    rows.forEach(row => vals.push(rowKeys[row]))
    return vals;
  }
  rowFromKey(rowKey: string): number {
    const rowKeysMap = this.rowKeysMap;
    return rowKeysMap[rowKey];
  }
  rowFromKeys(rowKeys: string[]): number[] {
    const rowKeysMap = this.rowKeysMap;
    const vals: number[] = [];
    rowKeys.forEach(rowKey => vals.push(rowKeysMap[rowKey]))
    return vals;
  }
}

//kaihai用の列モデル
export const KaihaiHandyImportColRowModelDefault: KaihaiHandyImportColRowModel = new KaihaiHandyImportColRowModel({
  //列固定
  fixedColumnsLeft: 1, //headerColKeys.length + rowHeaderColKeys.length,

  //テーブルの各列のキー
  colKeys: colKeys,
  rowKeys: [],
});
