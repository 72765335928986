import moment from "moment";
moment.updateLocale("ja", {
  months: ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月",],
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

//ヘッダー列
export const headerColKeys = [
  'no',
  'targetFlg',
  'checkMessage',
];
//行ヘッダー列
export const rowHeaderColKeys = [
  // 'rowHeader', 
];
//ヘッダー列
export const bodyColKeys = [
  'beforeSyubaiDate',
  'beforeJan',
  'beforeItemFlg',
  'beforeItemCd',
  'beforeMakerName',
  'beforeItemName',
  'beforeKikaku',
  'beforeIrisu',
  'beforeJodai',
  'beforeSyubaiFlg',
  'beforeJyodaiFlg',
  'beforeKikakuFlg',
  'beforeNyusatsuFlg',
  'beforeBikou',
  'afterHatsubaiDate',
  'afterJan',
  'afterItemFlg',
  'afterItemCd',
  'afterMakerName',
  'afterItemName',
  'afterKikaku',
  'afterIrisu',
  'afterJodai',
  'afterJanhenFlg',
  'afterBikou',

  'fileName',
  'sheetName',
  'rowNo',

  // 'createDate',
  // // 'createUserid',
  // 'createUsername',
  // 'updateDate',
  // // 'updateUserid',
  // 'updateUsername',
];
export const mergeColKeys = [
  'targetFlg',
  'checkMessage',

  'fileName',
  'sheetName',
  'rowNo',

  'beforeSyubaiDate',
  'beforeJan',
  'beforeSyubaiFlg',
  'beforeJyodaiFlg',
  'beforeKikakuFlg',
  'beforeNyusatsuFlg',
  'beforeBikou',
  'afterHatsubaiDate',
  'afterJan',
  'afterJanhenFlg',
  'afterBikou',
  // // 'createUserid',
  // 'createUsername',
  // 'updateDate',
  // // 'updateUserid',
  // 'updateUsername',
];
export const rowKeys:string[] = [
  'dummy',
];

export const headersCol = [
  {
    no : "No.",
    targetFlg : "取込対象",
    checkMessage : "取込対象",
    fileName : "Excel情報",
    sheetName : "Excel情報",
    rowNo : "Excel情報",
  
    beforeSyubaiDate : "変更前/廃止商品",
    beforeJan : "変更前/廃止商品",
    beforeItemFlg : "変更前/廃止商品",
    beforeItemCd : "変更前/廃止商品",
    beforeMakerName : "変更前/廃止商品",
    beforeItemName : "変更前/廃止商品",
    beforeKikaku : "変更前/廃止商品",
    beforeIrisu : "変更前/廃止商品",
    beforeJodai : "変更前/廃止商品",
    beforeSyubaiFlg : "変更前/廃止商品",
    beforeJyodaiFlg : "変更前/廃止商品",
    beforeKikakuFlg : "変更前/廃止商品",
    beforeNyusatsuFlg : "変更前/廃止商品",
    beforeBikou : "変更前/廃止商品",
    afterHatsubaiDate : "変更後/新商品",
    afterJan : "変更後/新商品",
    afterItemFlg : "変更後/新商品",
    afterItemCd : "変更後/新商品",
    afterMakerName : "変更後/新商品",
    afterItemName : "変更後/新商品",
    afterKikaku : "変更後/新商品",
    afterIrisu : "変更後/新商品",
    afterJodai : "変更後/新商品",
    afterJanhenFlg : "変更後/新商品",
    afterBikou : "変更後/新商品",
    createDate : "作成情報",
    createUserid : "作成情報",
    createUsername : "作成情報",
    updateDate : "更新情報",
    updateUserid : "更新情報",
    updateUsername : "更新情報",
  },
  {
    no : "",
    targetFlg : "対象",
    checkMessage : "チェック結果",
    fileName : "ファイル名",
    sheetName : "シート名",
    rowNo : "行番号",

    beforeSyubaiDate : "終売日",
    beforeJan : "JANコード",
    beforeItemFlg : "対象",
    beforeItemCd : "商品CD",
    beforeMakerName : "メーカー名",
    beforeItemName : "商品名",
    beforeKikaku : "規格",
    beforeIrisu : "入数",
    beforeJodai : "上代",
    beforeSyubaiFlg : "終売変更",
    beforeJyodaiFlg : "上代変更",
    beforeKikakuFlg : "規格変更",
    beforeNyusatsuFlg : "入数変更",
    beforeBikou : "備考",
    afterHatsubaiDate : "発売日",
    afterJan : "JANコード",
    afterItemFlg : "対象",
    afterItemCd : "商品CD",
    afterMakerName : "メーカー名",
    afterItemName : "商品名",
    afterKikaku : "規格",
    afterIrisu : "入数",
    afterJodai : "上代",
    afterJanhenFlg : "JAN変更",
    afterBikou : "備考",
    createDate : "作成日時",
    createUserid : "作成者ID",
    createUsername : "作成者",
    updateDate : "更新日時",
    updateUserid : "更新者ID",
    updateUsername : "更新者",
  },
];

export const headersRow = {
  dummy: '-',
};

export const selectableCol =
{
  no : false,
  targetFlg : false,
  checkMessage : false,
  fileName : false,
  sheetName : false,
  rowNo : false,

  beforeSyubaiDate : false,
  beforeJan : false,
  afterHatsubaiDate : false,
  afterJan : false,
  rnk : false,
  beforeItemFlg : false,
  beforeItemCd : true,
  beforeMakerName : true,
  beforeItemName : true,
  beforeKikaku : true,
  beforeIrisu : true,
  beforeJodai : true,
  beforeSyubaiFlg : true,
  beforeJyodaiFlg : true,
  beforeKikakuFlg : true,
  beforeNyusatsuFlg : true,
  beforeBikou : true,
  afterItemFlg : false,
  afterItemCd : true,
  afterMakerName : true,
  afterItemName : true,
  afterKikaku : true,
  afterIrisu : true,
  afterJodai : true,
  afterJanhenFlg : true,
  afterBikou : true,
  createDate : true,
  createUserid : true,
  createUsername : true,
  updateDate : true,
  updateUserid : true,
  updateUsername : true,  
}
export const selectableRow = {
  dummy: true,
};



export const headerColClasses = [
  {
    no : "kaihai_H",
    targetFlg : "kaihai_H",
    checkMessage : "kaihai_H",
    fileName : "kaihai_H",
    sheetName : "kaihai_H",
    rowNo : "kaihai_H",

    beforeSyubaiDate : "kaihai_H_before",
    beforeJan : "kaihai_H_before",
    afterHatsubaiDate : "kaihai_H_after",
    afterJan : "kaihai_H_after",
    rnk : "kaihai_H_before",
    beforeItemFlg : "kaihai_H_before",
    beforeItemCd : "kaihai_H_before",
    beforeMakerName : "kaihai_H_before",
    beforeItemName : "kaihai_H_before",
    beforeKikaku : "kaihai_H_before",
    beforeIrisu : "kaihai_H_before",
    beforeJodai : "kaihai_H_before",
    beforeSyubaiFlg : "kaihai_H_before",
    beforeJyodaiFlg : "kaihai_H_before",
    beforeKikakuFlg : "kaihai_H_before",
    beforeNyusatsuFlg : "kaihai_H_before",
    beforeBikou : "kaihai_H_before",
    afterItemFlg : "kaihai_H_after",
    afterItemCd : "kaihai_H_after",
    afterMakerName : "kaihai_H_after",
    afterItemName : "kaihai_H_after",
    afterKikaku : "kaihai_H_after",
    afterIrisu : "kaihai_H_after",
    afterJodai : "kaihai_H_after",
    afterJanhenFlg : "kaihai_H_after",
    afterBikou : "kaihai_H_after",
    createDate : "kaihai_H",
    createUserid : "kaihai_H",
    createUsername : "kaihai_H",
    updateDate : "kaihai_H",
    updateUserid : "kaihai_H",
    updateUsername : "kaihai_H",
  },
  {
    no : "kaihai_H",
    targetFlg : "kaihai_H",
    checkMessage : "kaihai_H",
    fileName : "kaihai_H",
    sheetName : "kaihai_H",
    rowNo : "kaihai_H",

    beforeSyubaiDate : "kaihai_H_before",
    beforeJan : "kaihai_H_before",
    afterHatsubaiDate : "kaihai_H_after",
    afterJan : "kaihai_H_after",
    rnk : "kaihai_H_before",
    beforeItemFlg : "kaihai_H_before",
    beforeItemCd : "kaihai_H_before",
    beforeMakerName : "kaihai_H_before",
    beforeItemName : "kaihai_H_before",
    beforeKikaku : "kaihai_H_before",
    beforeIrisu : "kaihai_H_before",
    beforeJodai : "kaihai_H_before",
    beforeSyubaiFlg : "kaihai_H_before",
    beforeJyodaiFlg : "kaihai_H_before",
    beforeKikakuFlg : "kaihai_H_before",
    beforeNyusatsuFlg : "kaihai_H_before",
    beforeBikou : "kaihai_H_before",
    afterItemFlg : "kaihai_H_after",
    afterItemCd : "kaihai_H_after",
    afterMakerName : "kaihai_H_after",
    afterItemName : "kaihai_H_after",
    afterKikaku : "kaihai_H_after",
    afterIrisu : "kaihai_H_after",
    afterJodai : "kaihai_H_after",
    afterJanhenFlg : "kaihai_H_after",
    afterBikou : "kaihai_H_after",
    createDate : "kaihai_H",
    createUserid : "kaihai_H",
    createUsername : "kaihai_H",
    updateDate : "kaihai_H",
    updateUserid : "kaihai_H",
    updateUsername : "kaihai_H",
  },
];
export const headerRowClasses = {
  dummy: 'kaihai_RH',
};

export const colWidths =
{
  no : 50,
  targetFlg : 60,
  checkMessage : 200,
  fileName : 100,
  sheetName : 100,
  rowNo : 50,

  beforeSyubaiDate : 80,
  beforeJan : 120,
  afterHatsubaiDate : 80,
  afterJan : 120,
  rnk : 70,
  beforeItemFlg : 60,
  beforeItemCd : 100,
  beforeMakerName : 70,
  beforeItemName : 120,
  beforeKikaku : 70,
  beforeIrisu : 70,
  beforeJodai : 70,
  beforeSyubaiFlg : 70,
  beforeJyodaiFlg : 70,
  beforeKikakuFlg : 70,
  beforeNyusatsuFlg : 70,
  beforeBikou : 120,
  afterItemFlg : 60,
  afterItemCd : 100,
  afterMakerName : 70,
  afterItemName : 120,
  afterKikaku : 70,
  afterIrisu : 70,
  afterJodai : 70,
  afterJanhenFlg : 70,
  afterBikou : 120,
  createDate : 120,
  createUserid : 70,
  createUsername : 100,
  updateDate : 120,
  updateUserid : 70,
  updateUsername : 100,
};

export const colDataType =
{
  no : {type: 'text', readOnly: true,},
  targetFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
  checkMessage : {type: 'text', readOnly: true,},
  fileName : {type: 'text', readOnly: true,},
  sheetName : {type: 'text', readOnly: true,},
  rowNo : {type: 'text', readOnly: true,},

  beforeSyubaiDate : {type: 'text', readOnly: true, className: 'htCenter',},
  beforeJan : {type: 'text', readOnly: true, className: 'htLeft',},
  afterHatsubaiDate : {type: 'text', readOnly: true, className: 'htCenter',},
  afterJan : {type: 'text', readOnly: true, className: 'htLeft',},
  rnk : {type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  beforeItemFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
  beforeItemCd : {type: 'text', readOnly: true, className: 'htCenter',},
  beforeMakerName : {type: 'text', readOnly: true, className: 'htLeft',},
  beforeItemName : {type: 'text', readOnly: true, className: 'htLeft',},
  beforeKikaku : {type: 'text', readOnly: true, className: 'htLeft',},
  beforeIrisu : {type: 'text', readOnly: true, className: 'htLeft',},
  beforeJodai : {type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  beforeSyubaiFlg : {type: 'text', readOnly: true, className: 'htCenter',},
  beforeJyodaiFlg : {type: 'text', readOnly: true, className: 'htCenter',},
  beforeKikakuFlg : {type: 'text', readOnly: true, className: 'htCenter',},
  beforeNyusatsuFlg : {type: 'text', readOnly: true, className: 'htCenter',},
  beforeBikou : {type: 'text', readOnly: true, className: 'htLeft',},
  afterItemFlg: {type: 'checkbox', checkedTemplate: true, uncheckedTemplate: false, readOnly: false, className: 'htCenter'},
  afterItemCd : {type: 'text', readOnly: true, className: 'htCenter',},
  afterMakerName : {type: 'text', readOnly: true, className: 'htLeft',},
  afterItemName : {type: 'text', readOnly: true, className: 'htLeft',},
  afterKikaku : {type: 'text', readOnly: true, className: 'htLeft',},
  afterIrisu : {type: 'text', readOnly: true, className: 'htLeft',},
  afterJodai : {type: 'numeric', readOnly: true, numericFormat: { pattern: '0,0', }, },
  afterJanhenFlg : {type: 'text', readOnly: true, className: 'htCenter',},
  afterBikou : {type: 'text', readOnly: true, className: 'htLeft',},
  createDate : {type: 'text', readOnly: true, className: 'htCenter',},
  createUserid : {type: 'text', readOnly: true, className: 'htLeft',},
  createUsername : {type: 'text', readOnly: true, className: 'htLeft',},
  updateDate : {type: 'text', readOnly: true, className: 'htCenter',},
  updateUserid : {type: 'text', readOnly: true, className: 'htLeft',},
  updateUsername : {type: 'text', readOnly: true, className: 'htLeft',},
};

export const rowDataType = {};

export const rowRendererName =
{
};

//列定義情報（動的パラメータ）
interface KaihaiExcelImportColRowModelInterface {
  //列固定
  fixedColumnsLeft: number,

  //テーブルの各列のキー
  colKeys: string[],
  //テーブルの各行のキー
  rowKeys: string[],
}

export class KaihaiExcelImportColRowModel implements KaihaiExcelImportColRowModelInterface {
  //列固定
  fixedColumnsLeft: number;
  //テーブルの各列のキー
  colKeys: string[];
  //テーブルの各列のキー
  rowKeys: string[];
  //ヘッダー
  nestedHeaders; //any[][]
  nestedHeadersBackup; //any[][]
  //ヘッダー区切り
  nestedHeadersGroupIndex: number[];
  //テーブルの各列の逆引き用マップ
  colKeysMap;
  //テーブルの各行の逆引き用マップ
  rowKeysMap;

  constructor(param: KaihaiExcelImportColRowModelInterface) {
    this.fixedColumnsLeft = param.fixedColumnsLeft;
    this.colKeys = param.colKeys;
    this.rowKeys = param.rowKeys;

    //テーブルの各列のキー逆引き
    const colKeysMap = {};
    param.colKeys.forEach((colKey, index) => {
      colKeysMap[colKey] = index;
    });
    this.colKeysMap = colKeysMap;
    //テーブルの各行のキー逆引き
    const rowKeysMap = {};
    param.rowKeys.forEach((rowKey, index) => {
      rowKeysMap[rowKey] = index;
    });
    this.rowKeysMap = rowKeysMap;

    //nestedHeaders初期化
    this.initNestedHeaders();
  }

  //nestedHeaders初期化
  initNestedHeaders() {
    //nestedHeaders
    const nestedHeaders = [];
    const nestedHeadersGroupIndex = [];
    // const customBorders = [];
    headersCol.forEach((header, index) => {
      const lastHeader = index == headersCol.length - 1;
      const nestedHeader = [];

      let colspan=0;
      let preHeaderVal = null;
      this.colKeys.forEach((colKey) => {
        const headerVal = header[colKey];
        if(lastHeader) {
          nestedHeader.push(headerVal);
        }
        else {
          if(preHeaderVal !== null && preHeaderVal != headerVal){
            nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
            colspan=0;
          }
          colspan++;
          preHeaderVal = headerVal;
        }
        //0行目でグループ
        if(index == 0) {
          nestedHeadersGroupIndex.push(colspan-1);
        }
      });
      if(!lastHeader && colspan > 0) {
        nestedHeader.push(colspan == 1 ? preHeaderVal : {label: preHeaderVal, colspan: colspan});
      }

      nestedHeaders.push(nestedHeader);
    });

    this.nestedHeaders = nestedHeaders;
    this.nestedHeadersBackup = nestedHeaders.map(nestedHeadersSub => [...nestedHeadersSub]);
    this.nestedHeadersGroupIndex = nestedHeadersGroupIndex;
  }

  //function
  keyFromCol(column:number):string {
    return this.colKeys[column];
  }
  keyFromCols(columns:number[]):string[] {
    const vals:string[] = [];
    columns.forEach(column => vals.push(this.colKeys[column]))
    return vals;
  }
  colFromKey(colKey:string):number {
    return this.colKeysMap[colKey];
  }
  colFromKeys(colKeys:string[]):number[] {
    const vals:number[] = [];
    colKeys.forEach(colKey => vals.push(this.colKeysMap[colKey]))
    return vals;
  }
  //function
  keyFromRow(row:number):string {
    const rowKeys = this.rowKeys;
    return rowKeys[row];
  }
  keyFromRows(rows:number[]):string[] {
    const rowKeys = this.rowKeys;
    const vals:string[] = [];
    rows.forEach(row => vals.push(rowKeys[row]))
    return vals;
  }
  rowFromKey(rowKey:string):number {
    const rowKeysMap = this.rowKeysMap;
    return rowKeysMap[rowKey];
  }
  rowFromKeys(rowKeys:string[]):number[] {
    const rowKeysMap = this.rowKeysMap;
    const vals:number[] = [];
    rowKeys.forEach(rowKey => vals.push(rowKeysMap[rowKey]))
    return vals;
  }
}

//kaihai用の列モデル
export const KaihaiExcelImportColRowModelDefault:KaihaiExcelImportColRowModel = new KaihaiExcelImportColRowModel({
  //列固定
  fixedColumnsLeft: headerColKeys.length + rowHeaderColKeys.length,

  //テーブルの各列のキー
  colKeys: [
    ...headerColKeys,
    ...rowHeaderColKeys,
    ...bodyColKeys,
  ],
  rowKeys: rowKeys,
});
