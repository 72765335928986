import moment from 'moment';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/store/common";
import * as arrayUtil from "@/util/arrayUtil";
import { KaihaiExcelImportColRowModel, KaihaiExcelImportColRowModelDefault } from "@/components/kaihai/KaihaiExcelImportTableModel";
import { PageProps } from "@/assets/pageprops/kaihai";

//強制表示列
export const mustVisibleColumnsKey:string[] = [
  "no", 
  "beforeSyubaiDate",
  "beforeJan",
  "afterHatsubaiDate",
  "afterJan",
  // "rnk",
  // "beforeItemCd",
  // "beforeMakerName",
  // "beforeItemName",
  // "beforeKikaku",
  // "beforeIrisu",
  // "beforeJodai",
  // "beforeSyubaiFlg",
  // "beforeJyodaiFlg",
  // "beforeKikakuFlg",
  // "beforeNyusatsuFlg",
  // "beforeBikou",
  // "afterItemCd",
  // "afterMakerName",
  // "afterItemName",
  // "afterKikaku",
  // "afterIrisu",
  // "afterJodai",
  // "afterJanhenFlg",
  // "afterBikou",
  // "createDate",
  // "createUserid",
  // "createUsername",
  // "updateDate",
  // "updateUserid",
  // "updateUsername",
];
//デフォルト表示列
export const defaultVisibleColumnsKey:string[] = [
  // "beforeSyubaiDate",
  // "beforeJan",
  // "afterHatsubaiDate",
  // "afterJan",
  // "rnk",
  "beforeItemCd",
  "beforeMakerName",
  "beforeItemName",
  "beforeKikaku",
  "beforeIrisu",
  "beforeJodai",
  "beforeSyubaiFlg",
  "beforeJyodaiFlg",
  "beforeKikakuFlg",
  "beforeNyusatsuFlg",
  "beforeBikou",
  "afterItemCd",
  "afterMakerName",
  "afterItemName",
  "afterKikaku",
  "afterIrisu",
  "afterJodai",
  "afterJanhenFlg",
  "afterBikou",
  // "createDate",
  // "createUserid",
  // "createUsername",
  "updateDate",
  // "updateUserid",
  "updateUsername",
];

//Page State
export type KaihaiImportExcelSaveState = {
  // hiddenColumns: number[],
  // hiddenColumnsKey: string[],
  // visibleColumnsKey: string[],
};

export const initialkaihaiState: KaihaiImportExcelSaveState = {
  // hiddenColumns: [],
  // hiddenColumnsKey: [],
  // visibleColumnsKey: defaultVisibleColumnsKey,
};

//Page Slice
export type KaihaiImportExcelSaveReducer = {
  initOnDidMount: (state:KaihaiImportExcelSaveState, action: PayloadAction<PageProps>) => void,
  resetOnWillUnmount: (state:KaihaiImportExcelSaveState) => void,
  // hideColumnKeysChange: (state:KaihaiImportExcelSaveState, action: PayloadAction<string[]>) => void,
}

const createReducerContent = (name:string, colRowModel:KaihaiExcelImportColRowModel, initialState: KaihaiImportExcelSaveState):KaihaiImportExcelSaveReducer => {return {
  //componentDidMount
  initOnDidMount(state:KaihaiImportExcelSaveState, action: PayloadAction<PageProps>) {
    const props:PageProps = action.payload;

    // //初期表示列0配列は、常に全項目表示とする
    // if(initialState.visibleColumnsKey.length == 0) {
    //   state.visibleColumnsKey = colRowModel.colKeys;
    // }
    // else if(!state.visibleColumnsKey) {
    //   state.visibleColumnsKey = initialState.visibleColumnsKey;
    // }
    // //強制選択列
    // state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colRowModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
    // //非表示列
    // state.hiddenColumnsKey = arrayUtil.not(colRowModel.colKeys, state.visibleColumnsKey);
  },
  //componentWillUnmount
  resetOnWillUnmount(state:KaihaiImportExcelSaveState) {
    // //初期表示列0配列は、常に全項目表示とするため、記憶しない
    // if(initialState.visibleColumnsKey.length == 0) {
    //   state.visibleColumnsKey = [];
    // }
  },
  // hideColumnKeysChange(state:KaihaiImportExcelSaveState, action: PayloadAction<string[]>){
  //   console.log('importExcelStore.hideColumnKeysChange');
  //   //store更新
  //   state.hiddenColumns = colRowModel.colFromKeys(action.payload);
  //   state.hiddenColumnsKey = action.payload;
  //   state.visibleColumnsKey = arrayUtil.not(colRowModel.colKeys, action.payload);
  // },
}};

const createSliceContent = (name:string, colRowModel:KaihaiExcelImportColRowModel, initialState: KaihaiImportExcelSaveState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colRowModel, initialState),
});

//Page Slice Export
//KaihaiImportExcelSaveSlice
export const kaihaiImportExcelSaveSlice = createSliceContent("kaihaiImportExcelSave", KaihaiExcelImportColRowModelDefault, initialkaihaiState);
export const kaihaiImportHandyExcelSaveSlice = createSliceContent("kaihaiImportHandyExcelSave", KaihaiExcelImportColRowModelDefault, initialkaihaiState);
