import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { SalesListColModel, drillDownMaxLv, drillDownLv1MaxLv, drillDownLv2MaxLv, colKeysKeihi, colKeysShiire } from "@/components/saleslist/SalesListTableModel";
import { RequestParam } from "@/assets/apitype/salesList";
import { YM, YW, CodeName } from "@/store/common";

import * as calcUtil from "@/util/calcUtil";
import * as compareUtil from "@/util/compareUtil";

export type RetrievedId = {uuid: string, lv:number};
export interface RowData {
  TP?: "total" | "grouping1" | "grouping2" | "lv1" | "lv2" | "lv3" | "lv4" | "lv5" | "lv6" | "lv7" | "lv8",
  drillDowned?: boolean,
  no?: string,
  CAT?: "def" | "sej" | "mix", //一般/SEJ/混在
  YMID?: number | null, //年月/年週
  H1ID?: string | null, H2ID?: string | null, H3ID?: string | null, H4ID?: string | null, H5ID?: string | null, H6ID?: string | null, H7ID?: string | null, H8ID?: string | null
  //以下に貼り付け
  H1CD?: string | null, H1NM?: string | null, H2CD?: string | null, H2NM?: string | null, H3CD?: string | null, H3NM?: string | null, H4CD?: string | null, H4NM?: string | null, H5CD?: string | null, H5NM?: string | null, H6CD?: string | null, H6NM?: string | null, H7CD?: string | null, H7NM?: string | null, H8CD?: string | null, H8NM?: string | null,
  JJT?: number | null, JJP?: number | null, JJR?: number | null, JJD?: number | null, 
  JKT?: number | null, JKP?: number | null, JKR?: number | null, JKD?: number | null, 
  JRT?: number | null, JRP?: number | null, JRD?: number | null, 
  //欠品責任別
  KI1KA?: number | null, KI1KR?: number | null, KI1KK?: number | null, 
  KI2KA?: number | null, KI2KR?: number | null, KI2KK?: number | null, 
  KI3KA?: number | null, KI3KR?: number | null, KI3KK?: number | null, 
  KITKA?: number | null,
  //欠品区分別
  K10KA?: number | null, K10KR?: number | null, K10KK?: number | null, 
  K11KA?: number | null, K11KR?: number | null, K11KK?: number | null, 
  K12KA?: number | null, K12KR?: number | null, K12KK?: number | null, 
  K13KA?: number | null, K13KR?: number | null, K13KK?: number | null, 
  K14KA?: number | null, K14KR?: number | null, K14KK?: number | null, 
  K15KA?: number | null, K15KR?: number | null, K15KK?: number | null, 
  K16KA?: number | null, K16KR?: number | null, K16KK?: number | null, 
  K17KA?: number | null, K17KR?: number | null, K17KK?: number | null, 
  K19KA?: number | null, K19KR?: number | null, K19KK?: number | null, 
  K20KA?: number | null, K20KR?: number | null, K20KK?: number | null, 
  K21KA?: number | null, K21KR?: number | null, K21KK?: number | null, 
  K22KA?: number | null, K22KR?: number | null, K22KK?: number | null, 
  K29KA?: number | null, K29KR?: number | null, K29KK?: number | null, 
  K30KA?: number | null, K30KR?: number | null, K30KK?: number | null, 
  K31KA?: number | null, K31KR?: number | null, K31KK?: number | null, 
  K32KA?: number | null, K32KR?: number | null, K32KK?: number | null, 
  K33KA?: number | null, K33KR?: number | null, K33KK?: number | null, 
  K41KA?: number | null, K41KR?: number | null, K41KK?: number | null, 
  K34KA?: number | null, K34KR?: number | null, K34KK?: number | null, 
  K39KA?: number | null, K39KR?: number | null, K39KK?: number | null, 
  K40KA?: number | null, K40KR?: number | null, K40KK?: number | null, 
  K42KA?: number | null, K42KR?: number | null, K42KK?: number | null, 
  K43KA?: number | null, K43KR?: number | null, K43KK?: number | null, 
  K44KA?: number | null, K44KR?: number | null, K44KK?: number | null, 
  K49KA?: number | null, K49KR?: number | null, K49KK?: number | null, 
  K50KA?: number | null, K50KR?: number | null, K50KK?: number | null, 
  
  SLT?: number | null, SLP?: number | null, SLR?: number | null, SLD?: number | null, 
  SDT?: number | null, SDP?: number | null, SDR?: number | null, SDD?: number | null, 
  SRT?: number | null, SRP?: number | null, SRR?: number | null, SRD?: number | null, 
  STT?: number | null, STP?: number | null, STR?: number | null, STD?: number | null, STB?: number | null, STBR?: number | null, STBD?: number | null,
  PBLT?: number | null, PBLP?: number | null, PBLR?: number | null, PBLD?: number | null, 
  PBDT?: number | null, PBDP?: number | null, PBDR?: number | null, PBDD?: number | null, 
  PBRT?: number | null, PBRP?: number | null, PBRR?: number | null, PBRD?: number | null, 
  PBTT?: number | null, PBTP?: number | null, PBTR?: number | null, PBTD?: number | null, 
  PBHT?: number | null, PBHP?: number | null, PBHD?: number | null,
  YHLT?: number | null, YHLP?: number | null, YHLR?: number | null, YHLD?: number | null, 
  YHDT?: number | null, YHDP?: number | null, YHDR?: number | null, YHDD?: number | null, 
  YHRT?: number | null, YHRP?: number | null, YHRR?: number | null, YHRD?: number | null, 
  YHTT?: number | null, YHTP?: number | null, YHTR?: number | null, YHTD?: number | null, 
  YHHT?: number | null, YHHP?: number | null, YHHD?: number | null,
  PTT?: number | null, PTP?: number | null, PTR?: number | null, PTD?: number | null, PTB?: number | null, PTBR?: number | null, PTBD?: number | null, 
  PRT?: number | null, PRP?: number | null, PRD?: number | null,
  DDT?: number | null, DDP?: number | null, DDR?: number | null, DDD?: number | null, 
  DBT?: number | null, DBP?: number | null, DBR?: number | null, DBD?: number | null, 
  DQT?: number | null, DQP?: number | null, DQR?: number | null, DQD?: number | null, 
  DFT?: number | null, DFP?: number | null, DFR?: number | null, DFD?: number | null, 
  DTT?: number | null, DTP?: number | null, DTR?: number | null, DTD?: number | null, DTB?: number | null, DTBR?: number | null, DTBD?: number | null, 
  DRT?: number | null, DRP?: number | null, DRD?: number | null,
  NTT?: number | null, NTP?: number | null, NTR?: number | null, NTD?: number | null, NTB?: number | null, NTBR?: number | null, NTBD?: number | null,
  CIT?: number | null, CIP?: number | null, CIR?: number | null, CID?: number | null, 
  CST?: number | null, CSP?: number | null, CSR?: number | null, CSD?: number | null, 
  CTT?: number | null, CTP?: number | null, CTR?: number | null, CTD?: number | null, CTB?: number | null, CTBR?: number | null, CTBD?: number | null,
  OCT?: number | null, OCP?: number | null, OCR?: number | null, OCD?: number | null,
  TST?: number | null, TSP?: number | null, TSR?: number | null, TSD?: number | null, 
  CSTT?: number | null, CSTP?: number | null, CSTR?: number | null, CSTD?: number | null, CSTB?: number | null, CSTBR?: number | null, CSTBD?: number | null,
  CSTRT?: number | null, CSTRP?: number | null, CSTRD?: number | null,
  OPT?: number | null, OPP?: number | null, OPR?: number | null, OPD?: number | null,
  TPT?: number | null, TPP?: number | null, TPR?: number | null, TPD?: number | null, 
  PPTT?: number | null, PPTP?: number | null, PPTR?: number | null, PPTD?: number | null, PPTB?: number | null, PPTBR?: number | null, PPTBD?: number | null,
  PPTRT?: number | null, PPTRP?: number | null, PPTRD?: number | null,
  OET?: number | null, OEP?: number | null, OER?: number | null, OED?: number | null,
  TET?: number | null, TEP?: number | null, TER?: number | null, TED?: number | null, 
  ETT?: number | null, ETP?: number | null, ETR?: number | null, ETD?: number | null, ETB?: number | null, ETBR?: number | null, ETBD?: number | null,
  ETRT?: number | null, ETRP?: number | null, ETRD?: number | null,
  KTT?: number | null, KTP?: number | null, KTR?: number | null, KTD?: number | null, 
  KRT?: number | null, KRP?: number | null, KRD?: number | null,
  BTT?: number | null, BTP?: number | null, BTR?: number | null, BTD?: number | null, 
  BRT?: number | null, BRP?: number | null, BRD?: number | null, 
  ATT?: number | null, ATP?: number | null, ATR?: number | null, ATD?: number | null, 
  ART?: number | null, ARP?: number | null, ARD?: number | null, 
  GTT?: number | null, GTP?: number | null, GTR?: number | null, GTD?: number | null, GTB?: number | null, GTBR?: number | null, GTBD?: number | null, 
  GRT?: number | null, GRP?: number | null, GRD?: number | null, 
  ZTT?: number | null, ZTP?: number | null, ZTR?: number | null, ZTD?: number | null, ZTB?: number | null, ZTBR?: number | null, ZTBD?: number | null, 
  ZRT?: number | null, ZRP?: number | null, ZRD?: number | null,
  ZCT?: number | null, ZCP?: number | null, ZCR?: number | null, ZCD?: number | null, 
  ZCRT?: number | null, ZCRP?: number | null, ZCRD?: number | null,
  CCDT?: number | null, CDDP?: number | null, CDDR?: number | null, CDDD?: number | null, 
  CDBT?: number | null, CDBP?: number | null, CDBR?: number | null, CDBD?: number | null, 
  CDFT?: number | null, CDFP?: number | null, CDFR?: number | null, CDFD?: number | null, 
  COCT?: number | null, COCP?: number | null, COCR?: number | null, COCD?: number | null, 
  COPT?: number | null, COPP?: number | null, COPR?: number | null, COPD?: number | null, 
  COET?: number | null, COEP?: number | null, COER?: number | null, COED?: number | null, 
  CTPT?: number | null, CTPP?: number | null, CTPR?: number | null, CTPD?: number | null, 
  CTST?: number | null, CTSP?: number | null, CTSR?: number | null, CTSD?: number | null, 
  CTET?: number | null, CTEP?: number | null, CTER?: number | null, CTED?: number | null, 
  KKT?: number | null, 
  KJT?: number | null, KKR?: number | null,
  PBT?: number | null, PBR?: number | null,

  //構成比
  JJTK?: number | null, JJPK?: number | null, 
  JKTK?: number | null, JKPK?: number | null, 
  SLTK?: number | null, SLPK?: number | null, SDTK?: number | null, SDPK?: number | null, SRTK?: number | null, SRPK?: number | null, STTK?: number | null, STPK?: number | null,
  PBLTK?: number | null, PBLPK?: number | null, PBDTK?: number | null, PBDPK?: number | null, PBRTK?: number | null, PBRPK?: number | null, PBTTK?: number | null, PBTPK?: number | null,
  YHLTK?: number | null, YHLPK?: number | null, YHDTK?: number | null, YHDPK?: number | null, YHRTK?: number | null, YHRPK?: number | null, YHTTK?: number | null, YHTPK?: number | null,
  PTTK?: number | null, PTPK?: number | null,
  DDTK?: number | null, DDPK?: number | null, DBTK?: number | null, DBPK?: number | null, DQTK?: number | null, DQPK?: number | null, DFTK?: number | null, DFPK?: number | null, DTTK?: number | null, DTPK?: number | null,
  NTTK?: number | null, NTPK?: number | null,
  CITK?: number | null, CIPK?: number | null, CSTK?: number | null, CSPK?: number | null, CTTK?: number | null, CTPK?: number | null, 
  OCTK?: number | null, OCPK?: number | null, 
  TSTK?: number | null, TSPK?: number | null,
  CSTTK?: number | null, CSTPK?: number | null,
  OPTK?: number | null, OPPK?: number | null,
  TPTK?: number | null, TPPK?: number | null,
  PPTTK?: number | null, PPTPK?: number | null,
  OETK?: number | null, OEPK?: number | null,
  TETK?: number | null, TEPK?: number | null,
  ETTK?: number | null, ETPK?: number | null,
  KTTK?: number | null, KTPK?: number | null,
  BTTK?: number | null, BTPK?: number | null,
  ATTK?: number | null, ATPK?: number | null,
  GTTK?: number | null, GTPK?: number | null,
  ZTTK?: number | null, ZTPK?: number | null, ZCTK?: number | null, ZCPK?: number | null,
  CCDTK?: number | null, CDDPK?: number | null, CDBTK?: number | null, CDBPK?: number | null, CDFTK?: number | null, CDFPK?: number | null, COCTK?: number | null, COCPK?: number | null, COPTK?: number | null, COPPK?: number | null, COETK?: number | null, COEPK?: number | null, CTPTK?: number | null, CTPPK?: number | null, CTSTK?: number | null, CTSPK?: number | null, CTETK?: number | null, CTEPK?: number | null,
  //予算構成比
  STBK?: number | null, PTBK?: number | null, DTBK?: number | null, NTBK?: number | null, CTBK?: number | null, GTBK?: number | null, ZTBK?: number | null, 

  CNDT?: number | null, CNDP?: number | null, CNDR?: number | null, CNDD?: number | null, CNDRT?: number | null, CNDRP?: number | null, CNDRD?: number | null,
  CNPT?: number | null, CNPP?: number | null, CNPR?: number | null, CNPD?: number | null, CNPRT?: number | null, CNPRP?: number | null, CNPRD?: number | null,
  CNGT?: number | null, CNGP?: number | null, CNGR?: number | null, CNGD?: number | null, CNGRT?: number | null, CNGRP?: number | null, CNGRD?: number | null,
  CNAT?: number | null, CNAP?: number | null, CNAR?: number | null, CNAD?: number | null, CNART?: number | null, CNARP?: number | null, CNARD?: number | null,
  CNET?: number | null, CNEP?: number | null, CNER?: number | null, CNED?: number | null, CNRT?: number | null, CNRP?: number | null, CNRD?: number | null,
  SNET?: number | null, SNEP?: number | null, SNER?: number | null, SNED?: number | null, SNRT?: number | null, SNRP?: number | null, SNRD?: number | null,
  ORST?: number | null, ORSP?: number | null, ORSR?: number | null, ORSD?: number | null, ORSRT?: number | null, ORSRP?: number | null, ORSRD?: number | null,
  ORPT?: number | null, ORPP?: number | null, ORPR?: number | null, ORPD?: number | null, ORPRT?: number | null, ORPRP?: number | null, ORPRD?: number | null,
  SHCT?: number | null, SHCP?: number | null, SHCR?: number | null, SHCD?: number | null, 
  SHDT?: number | null, SHDP?: number | null, SHDR?: number | null, SHDD?: number | null, 
  SHRCT?: number | null, SHRCP?: number | null, SHRCR?: number | null, SHRCD?: number | null, 
  SHRDT?: number | null, SHRDP?: number | null, SHRDR?: number | null, SHRDD?: number | null, 
  SHT?: number | null, SHP?: number | null, SHR?: number | null, SHD?: number | null, 

  //ワースト上位5位
  keppinRnkColKeys?: string[],
  //TC/DC,自社/委託
  CDIV?: string | null,
  TDIV?: string | null,

  NoResult?: boolean | null, //本年実績なし
}
export interface RowAttr {
  H1CD?: string | null,
  H1NM?: string | null,
  CDIV?: string | null, //TC/DC
  TDIV?: string | null, //自社/委託
}

export type findedCell = {
  row: number,
  col: number,
  data,
  text: string,
}

//Page State
export type SalesListTmpState = {
  //年月
  ymList: YM[],
  ywList: YW[],
  group2List: CodeName[],
  shitenList: CodeName[],
  centerList: CodeName[],
  makerList: CodeName[],
  categoryList: CodeName[],

  accordionOpen: boolean,
  //検索する条件
  requestParam : RequestParam,
  requestParamQueue : RequestParam[],
  //検索終了した条件
  retrievedIDs: RetrievedId[],
  //共有URL
  shareURL: string,

  progress: Record<string, unknown>,
  datasAll: RowData[],
  datas: RowData[],
  rows: [][],
  mergeCells: [],
  selectionRowStart: number,
  selectionRowEnd: number,

  findKeyword:string,
  findedCellSelect: findedCell,
  findedCells: findedCell[],
  findedCellsOver: boolean,

  lastUpdate: string | null,
  adjus0Imported: boolean | null,
  errorMessage: string | null,
};

export const initialState: SalesListTmpState = {
  //年月
  ymList: [],
  //年週
  ywList: [],

  group2List: [],
  shitenList: [],
  centerList: [],
  makerList: [],
  categoryList: [],

  accordionOpen: true,
  requestParam : {},
  requestParamQueue: [],
  retrievedIDs: [],
  //共有URL
  shareURL: '',

  progress: {},
  datasAll: [],
  datas: [],
  rows: [],
  mergeCells: null,
  selectionRowStart: -1,
  selectionRowEnd: -1,

  findKeyword:'',
  findedCellSelect: null,
  findedCells: [],
  findedCellsOver: false,

  lastUpdate: null,
  adjus0Imported: false,
  errorMessage: null,
};

//Page Slice
export type SalesListTmpReducer = {
  setYMList: (state:SalesListTmpState, action: PayloadAction<YM[]>) => void,
  setYWList: (state:SalesListTmpState, action: PayloadAction<YW[]>) => void,
  setGroup2List: (state: SalesListTmpState, action: PayloadAction<CodeName[]>) => void,
  setShitenList: (state: SalesListTmpState, action: PayloadAction<CodeName[]>) => void,
  setCenterList: (state: SalesListTmpState, action: PayloadAction<CodeName[]>) => void,
  setMakerList: (state: SalesListTmpState, action: PayloadAction<CodeName[]>) => void,
  setCategoryList: (state: SalesListTmpState, action: PayloadAction<CodeName[]>) => void,
  setAccordionOpen: (state:SalesListTmpState, action: PayloadAction<boolean>) => void,
  setRequestParam: (state:SalesListTmpState, action: PayloadAction<RequestParam>) => void,
  addRequestParamQueue: (state:SalesListTmpState, action: PayloadAction<RequestParam[]>) => void,
  setRequestParamQueue: (state:SalesListTmpState, action: PayloadAction<RequestParam[]>) => void,
  clearRequestParamQueue: (state:SalesListTmpState, action: PayloadAction<RequestParam[]>) => void,
  setRetrievedID: (state:SalesListTmpState, action: PayloadAction<RetrievedId>) => void,
  addRetrievedID: (state:SalesListTmpState, action: PayloadAction<RetrievedId>) => void,
  removeRetrievedIDsOfLv: (state:SalesListTmpState, action: PayloadAction<number>) => void,
  setShareURL: (state:SalesListTmpState, action: PayloadAction<string>) => void,
  putProgress: (state:SalesListTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state:SalesListTmpState, action: PayloadAction<string>) => void,
  searched: (state: SalesListTmpState, action: PayloadAction<{
    param: RequestParam, datas: RowData[], sort: { key: string, asc: boolean }, colModel: SalesListColModel,
    attrs: RowAttr[], 
    visibleNoResult: boolean
    grouping1: boolean, grouping2: boolean,
  }>) => void,
  execSort: (state: SalesListTmpState, action: PayloadAction<{
    sort: { key: string, asc: boolean }, colModel: SalesListColModel, dataType: "daily" | "quick" | "commit" | "weekly",
    visibleNoResult: boolean
    grouping1: boolean, grouping2: boolean
  }>) => void,
  setDatas: (state:SalesListTmpState, action: PayloadAction<{datasAll:RowData[], datas:RowData[], colModel:SalesListColModel, dataType: "daily" | "quick" | "commit" | "weekly"}>) => void,
  editRowDatas: (state:SalesListTmpState, action: PayloadAction<{row:number, key:string, value:string|number|object|null, relatedValues?: {key:string, value:string|number|object|null}[]}[]>) => void,
  rowSelectionChange: (state:SalesListTmpState, action: PayloadAction<{start:number,end:number}>) => void,
  refreshTable: (state: SalesListTmpState, action: PayloadAction<{
    colModel: SalesListColModel, dataType: "daily" | "quick" | "commit" | "weekly",
    visibleNoResult: boolean
    grouping1: boolean, grouping2: boolean, sort: { key: string, asc: boolean }, param: RequestParam
  }>) => void,
  clearFindKeyword: (state) => void,
  setFindKeyword: (state:SalesListTmpState, action: PayloadAction<string>) => void,
  setFindedCellSelect: (state:SalesListTmpState, action: PayloadAction<findedCell>) => void,
  setFindedCells: (state:SalesListTmpState, action: PayloadAction<{findedCells:findedCell[], over:boolean}>) => void,
  setLastUpdate: (state:SalesListTmpState, action: PayloadAction<string | null>) => void,
  setAdjus0Imported: (state:SalesListTmpState, action: PayloadAction<boolean | null>) => void,
  setErrorMessage: (state:SalesListTmpState, action: PayloadAction<string>) => void,
}

const createReducerContent = ():SalesListTmpReducer => {return {

    // Option YM
    setYMList(state:SalesListTmpState, action: PayloadAction<YM[]>) {
      state.ymList = action.payload;
    },
    // Option YM
    setYWList(state:SalesListTmpState, action: PayloadAction<YW[]>) {
      state.ywList = action.payload;
    },
    setGroup2List(state: SalesListTmpState, action: PayloadAction<CodeName[]>) {
      state.group2List = action.payload;
    },
    setShitenList(state: SalesListTmpState, action: PayloadAction<CodeName[]>) {
      state.shitenList = action.payload;
    },
    setCenterList(state: SalesListTmpState, action: PayloadAction<CodeName[]>) {
      state.centerList = action.payload;
    },
    setMakerList(state: SalesListTmpState, action: PayloadAction<CodeName[]>) {
      state.makerList = action.payload;
    },
    setCategoryList(state: SalesListTmpState, action: PayloadAction<CodeName[]>) {
      state.categoryList = action.payload;
    },
    setAccordionOpen(state:SalesListTmpState, action: PayloadAction<boolean>) {
      state.accordionOpen = action.payload;
    },
    //検索条件
    setRequestParam(state:SalesListTmpState, action: PayloadAction<RequestParam>) {
      state.requestParam = action.payload;
    },
    addRequestParamQueue(state:SalesListTmpState, action: PayloadAction<RequestParam[]>) {
      state.requestParamQueue = [...state.requestParamQueue, ...action.payload];
    },
    setRequestParamQueue(state:SalesListTmpState, action: PayloadAction<RequestParam[]>) {
      state.requestParamQueue = action.payload;
    },
    clearRequestParamQueue(state) {
      state.requestParamQueue = [];
    },
    setRetrievedID(state:SalesListTmpState, action: PayloadAction<RetrievedId>) {
      state.retrievedIDs = [action.payload];
    },
    addRetrievedID(state:SalesListTmpState, action: PayloadAction<RetrievedId>) {
      const retrievedIDs = [...state.retrievedIDs];
      retrievedIDs.push(action.payload);
      state.retrievedIDs = retrievedIDs;
    },
    removeRetrievedIDsOfLv(state:SalesListTmpState, action: PayloadAction<number>) {
      let retrievedIDs = [...state.retrievedIDs];
      const lv = action.payload;
      retrievedIDs = retrievedIDs.filter(retrievedID => retrievedID.lv < lv);
      state.retrievedIDs = retrievedIDs;
    },

    setShareURL(state:SalesListTmpState, action: PayloadAction<string>) {
      state.shareURL = action.payload;
    },

    putProgress(state:SalesListTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:SalesListTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    searched(state: SalesListTmpState, action: PayloadAction<{
      param: RequestParam, datas: RowData[], attrs: RowAttr[], sort: { key: string, asc: boolean }, colModel: SalesListColModel,
      visibleNoResult: boolean
      grouping1: boolean, grouping2: boolean,
    }>) {
      const sort = action.payload.sort;
      //計算
      let datas = action.payload.datas;
      let attrs = action.payload.attrs;
      const grouping1 = action.payload.grouping1;
      const grouping2 = action.payload.grouping2;
      const visibleNoResult = action.payload.visibleNoResult;
      const param = action.payload.param;
      let lvMax = 1;
      for (let i = drillDownMaxLv; i >= 2; i--) {
        if (param['d' + i + '_axis']) {
          lvMax = i;
          break;
        }
      }
      const colModel = action.payload.colModel;
      const colKeys = colModel.colKeys;

      //URL共有判定
      const notShare = !param.shareid;

      datas = parseData(datas);
      datas = parseAttr(datas, attrs);
      datas = makeDatas(datas, grouping1, grouping2);

      //ドリルダウンかどうか
      const drillDown = notShare && drillDownLv2MaxLv.indexOf(param.drillDownType) !== -1;
      if(drillDown) {
        const drillDownLv = drillDownLv2MaxLv.indexOf(param.drillDownType)  + 2;
        datas = calcDatas(datas, state.datasAll,
          [param.axisType, param.d2_axis, param.d3_axis, param.d4_axis, param.d5_axis, param.d6_axis, param.d7_axis, param.d8_axis, ]  
        ); //計算項目の計算
        datas = doSort(datas, sort.key, sort.asc, true, false);  //一旦LVでソート
        const dataMap = {};
        datas.forEach(data => {
          //CAT:H1CD:H2CD:H3CD,CAT:H1CD:H2CD:H3CD, 形式
          let parentKey: string = data.CAT;
          for (let i = 2; i <= drillDownLv; i++) {
            parentKey = parentKey + ':' + data['H' + (i - 1) + 'ID'];
          }
          let subDatas = dataMap[parentKey];
          if(!subDatas) {
            subDatas = [];
            dataMap[parentKey] = subDatas;
          }
          subDatas.push(data);
        });

        datas = [...state.datasAll];
        Object.keys(dataMap).forEach(parentKey => {
          let subDatas = dataMap[parentKey];
          if(subDatas && subDatas.length > 0) {
            //CAT:H1CD:H2CD:H3CD 形式
            const parentKeys = parentKey.split(':');
            //差し込み位置の取得
            const parentIndex = 
              drillDownLv == 8 ? dataIndexOfLv7(datas, parentKeys[0], parentKeys[1], parentKeys[2], parentKeys[3], parentKeys[4], parentKeys[5], parentKeys[6], parentKeys[7]) :
              drillDownLv == 7 ? dataIndexOfLv6(datas, parentKeys[0], parentKeys[1], parentKeys[2], parentKeys[3], parentKeys[4], parentKeys[5], parentKeys[6]) :
              drillDownLv == 6 ? dataIndexOfLv5(datas, parentKeys[0], parentKeys[1], parentKeys[2], parentKeys[3], parentKeys[4], parentKeys[5]) :
              drillDownLv == 5 ? dataIndexOfLv4(datas, parentKeys[0], parentKeys[1], parentKeys[2], parentKeys[3], parentKeys[4]) :
              drillDownLv == 4 ? dataIndexOfLv3(datas, parentKeys[0], parentKeys[1], parentKeys[2], parentKeys[3]) :
              drillDownLv == 3 ? dataIndexOfLv2(datas, parentKeys[0], parentKeys[1], parentKeys[2]) :
              drillDownLv == 2 ? dataIndexOfLv1(datas, parentKeys[0], parentKeys[1]) :
              -1;
            if(parentIndex >= 0) {
              const parent = datas[parentIndex];
              subDatas = doSort(subDatas, sort.key, sort.asc, false, false);  //ソート
              subDatas = resetRowNo(subDatas, datas[parentIndex], lvMax, grouping1, grouping2); //列番号の振りなおし
              datas = spliceData(parentIndex+1, subDatas, datas); //差し込み
              parent.drillDowned = true; //ドリルダウン済みにマーク
            }
          }
        });
      }
      else {
        datas = calcTotal(datas); //合計行の作成
        datas = calcDatas(datas, datas,
          [param.axisType, param.d2_axis, param.d3_axis, param.d4_axis, param.d5_axis, param.d6_axis, param.d7_axis, param.d8_axis, ]  
          ); //計算項目の計算
        datas = doSort(datas, sort.key, sort.asc, false, !notShare);  //ソート
        datas = resetRowNo(datas, null, lvMax, grouping1, grouping2); //列番号の振りなおし
      }

      const {datasAll, datasFiltered} = filterDatas(datas, visibleNoResult);

      Object.assign(state, {
        datasAll: datasAll,
        datas: datasFiltered,
        rows: convertRows(datasFiltered, colKeys),
        // mergeCells: createMergeCells(datas, colModel),
      });
    },
    execSort(state: SalesListTmpState, action: PayloadAction<{
      sort: { key: string, asc: boolean }, colModel: SalesListColModel, dataType: "daily" | "quick" | "commit" | "weekly",
      visibleNoResult: boolean
      grouping1: boolean, grouping2: boolean
    }>) {
      const colModel = action.payload.colModel;
      const colKeys = colModel.colKeys;
      const sort = action.payload.sort;
      const key = sort.key;
      const asc = sort.asc;
      const grouping1 = action.payload.grouping1;
      const grouping2 = action.payload.grouping2;
      const visibleNoResult = action.payload.visibleNoResult;

      let datas = [...state.datasAll];
      datas = doSort(datas, key, asc, false, false);
      datas = resetRowNo(datas, null, drillDownMaxLv, grouping1, grouping2); //列番号の振りなおし

      const {datasAll, datasFiltered} = filterDatas(datas, visibleNoResult);

      Object.assign(state, {
        datasAll: datasAll,
        datas: datasFiltered,
        rows: convertRows(datasFiltered, colKeys),
      });
    },
    setDatas(state:SalesListTmpState, action: PayloadAction<{datasAll:RowData[], datas:RowData[], colModel:SalesListColModel, dataType: "daily" | "quick" | "commit" | "weekly"}>) {
      const colModel = action.payload.colModel;
      const colKeys = colModel.colKeys;
      let datasAll = action.payload.datasAll;
      let datas = action.payload.datas;

      // const {datasAll, datasFiltered} = filterDatas(datas, visibleNoResult);

      Object.assign(state, {
        datasAll: datasAll,
        datas: datas,
        rows: convertRows(datas, colKeys),
      });
    },
    editRowDatas(state:SalesListTmpState, action: PayloadAction<{row:number, key:string, value:string|number|object|null, relatedValues?: {key:string, value:string|number|object|null}[]}[]>) {
      const newData = [...state.rows];
      action.payload.forEach((editData)=>{
        const data = newData[editData.row];
        data[editData.key] = editData.value;
        //関連データの更新
        if(editData.relatedValues) {
          editData.relatedValues.forEach(relatedValue => {
            data[relatedValue.key] = relatedValue.value;
          })
        }
      })
      state.rows = newData;
    },
    // setRowDatas(state:SalesListTmpState, action: PayloadAction<RowData[]>) {
    //   state.rows = action.payload;
    // },
    rowSelectionChange(state:SalesListTmpState, action: PayloadAction<{start:number,end:number}>){
      state.selectionRowStart = action.payload.start;
      state.selectionRowEnd = action.payload.end;
    },
    refreshTable(state: SalesListTmpState, action: PayloadAction<{
      colModel: SalesListColModel, dataType: "daily" | "quick" | "commit" | "weekly",
      visibleNoResult: boolean
      grouping1: boolean, grouping2: boolean, sort: { key: string, asc: boolean }, param: RequestParam
    }>) {
      console.log('refreshTable');
      const colModel = action.payload.colModel;
      const colKeys = colModel.colKeys;
      const sort = action.payload.sort;
      const key = sort.key;
      const asc = sort.asc;
      const grouping1 = action.payload.grouping1;
      const grouping2 = action.payload.grouping2;
      const visibleNoResult = action.payload.visibleNoResult;
      const param = action.payload.param;

      let datas = [...state.datasAll];
      datas = makeDatas(datas, grouping1, grouping2);

      datas = calcDatas(datas, datas,
        [param.axisType, param.d2_axis, param.d3_axis, param.d4_axis, param.d5_axis, param.d6_axis, param.d7_axis, param.d8_axis, ]  
        ); //計算項目の計算

      datas = doSort(datas, key, asc, false, false);
      datas = resetRowNo(datas, null, drillDownMaxLv, grouping1, grouping2); //列番号の振りなおし

      const {datasAll, datasFiltered} = filterDatas(datas, visibleNoResult);

      Object.assign(state, {
        datasAll: datasAll,
        datas: datasFiltered,
        rows: convertRows(datasFiltered, colKeys),
      });
    },
    clearFindKeyword(state) {
      Object.assign(state, {
        findKeyword: '',
        findedCellSelect: null,
        findedCells: [],
        findedCellsOver: false,
      });
    },
    setFindKeyword(state:SalesListTmpState, action: PayloadAction<string>) {
      state.findKeyword = action.payload;
    },
    setFindedCellSelect(state:SalesListTmpState, action: PayloadAction<findedCell>) {
      state.findedCellSelect = action.payload;
    },
    setFindedCells(state:SalesListTmpState, action: PayloadAction<{findedCells:findedCell[], over:boolean}>) {
      state.findedCells = action.payload.findedCells;
      state.findedCellsOver = action.payload.over;
    },
    setLastUpdate(state:SalesListTmpState, action: PayloadAction<string | null>) {
      state.lastUpdate = action.payload;
    },
    setAdjus0Imported(state:SalesListTmpState, action: PayloadAction<boolean | null>) {
      state.adjus0Imported = action.payload;
    },
    setErrorMessage(state:SalesListTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    }
}};

const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

//数値のパース(数値が文字列で返ってくる)
export const parseData = (datas:RowData[]): RowData[] => {
  //set No.
  datas.forEach((data) => {
    if(typeof data.JJT === 'string') data.JJT = parseInt(data.JJT);
    if(typeof data.JJP === 'string') data.JJP = parseInt(data.JJP);
    if(typeof data.JKT === 'string') data.JKT = parseInt(data.JKT);
    if(typeof data.JKP === 'string') data.JKP = parseInt(data.JKP);
    //欠品責任別
    if(typeof data.KI1KA === 'string') data.KI1KA = parseInt(data.KI1KA);
    if(typeof data.KI2KA === 'string') data.KI2KA = parseInt(data.KI2KA);
    if(typeof data.KI3KA === 'string') data.KI3KA = parseInt(data.KI3KA);
    //欠品区分別
    if (typeof data.K10KA === 'string') data.K10KA = parseInt(data.K10KA);
    if (typeof data.K11KA === 'string') data.K11KA = parseInt(data.K11KA);
    if (typeof data.K12KA === 'string') data.K12KA = parseInt(data.K12KA);
    if (typeof data.K13KA === 'string') data.K13KA = parseInt(data.K13KA);
    if (typeof data.K14KA === 'string') data.K14KA = parseInt(data.K14KA);
    if (typeof data.K15KA === 'string') data.K15KA = parseInt(data.K15KA);
    if (typeof data.K16KA === 'string') data.K16KA = parseInt(data.K16KA);
    if (typeof data.K17KA === 'string') data.K17KA = parseInt(data.K17KA);
    if (typeof data.K19KA === 'string') data.K19KA = parseInt(data.K19KA);
    if (typeof data.K20KA === 'string') data.K20KA = parseInt(data.K20KA);
    if (typeof data.K21KA === 'string') data.K21KA = parseInt(data.K21KA);
    if (typeof data.K22KA === 'string') data.K22KA = parseInt(data.K22KA);
    if (typeof data.K29KA === 'string') data.K29KA = parseInt(data.K29KA);
    if (typeof data.K30KA === 'string') data.K30KA = parseInt(data.K30KA);
    if (typeof data.K31KA === 'string') data.K31KA = parseInt(data.K31KA);
    if (typeof data.K32KA === 'string') data.K32KA = parseInt(data.K32KA);
    if (typeof data.K33KA === 'string') data.K33KA = parseInt(data.K33KA);
    if (typeof data.K41KA === 'string') data.K41KA = parseInt(data.K41KA);
    if (typeof data.K34KA === 'string') data.K34KA = parseInt(data.K34KA);
    if (typeof data.K39KA === 'string') data.K39KA = parseInt(data.K39KA);
    if (typeof data.K40KA === 'string') data.K40KA = parseInt(data.K40KA);
    if (typeof data.K42KA === 'string') data.K42KA = parseInt(data.K42KA);
    if (typeof data.K43KA === 'string') data.K43KA = parseInt(data.K43KA);
    if (typeof data.K44KA === 'string') data.K44KA = parseInt(data.K44KA);
    if (typeof data.K49KA === 'string') data.K49KA = parseInt(data.K49KA);
    if (typeof data.K50KA === 'string') data.K50KA = parseInt(data.K50KA);

    if(typeof data.SLT === 'string') data.SLT = parseInt(data.SLT);
    if(typeof data.SLP === 'string') data.SLP = parseInt(data.SLP);
    if(typeof data.SDT === 'string') data.SDT = parseInt(data.SDT);
    if(typeof data.SDP === 'string') data.SDP = parseInt(data.SDP);
    if(typeof data.SRT === 'string') data.SRT = parseInt(data.SRT);
    if(typeof data.SRP === 'string') data.SRP = parseInt(data.SRP);
    if(typeof data.STT === 'string') data.STT = parseInt(data.STT);
    if(typeof data.STP === 'string') data.STP = parseInt(data.STP);
    if(typeof data.STB === 'string') data.STB = parseInt(data.STB);
    if(typeof data.PBLT === 'string') data.PBLT = parseInt(data.PBLT);
    if(typeof data.PBLP === 'string') data.PBLP = parseInt(data.PBLP);
    if(typeof data.PBDT === 'string') data.PBDT = parseInt(data.PBDT);
    if(typeof data.PBDP === 'string') data.PBDP = parseInt(data.PBDP);
    if(typeof data.PBRT === 'string') data.PBRT = parseInt(data.PBRT);
    if(typeof data.PBRP === 'string') data.PBRP = parseInt(data.PBRP);
    if(typeof data.YHLT === 'string') data.YHLT = parseInt(data.YHLT);
    if(typeof data.YHLP === 'string') data.YHLP = parseInt(data.YHLP);
    if(typeof data.YHDT === 'string') data.YHDT = parseInt(data.YHDT);
    if(typeof data.YHDP === 'string') data.YHDP = parseInt(data.YHDP);
    if(typeof data.YHRT === 'string') data.YHRT = parseInt(data.YHRT);
    if(typeof data.YHRP === 'string') data.YHRP = parseInt(data.YHRP);
    if(typeof data.PTT === 'string') data.PTT = parseInt(data.PTT);
    if(typeof data.PTP === 'string') data.PTP = parseInt(data.PTP);
    if(typeof data.PTB === 'string') data.PTB = parseInt(data.PTB);
    if(typeof data.DDT === 'string') data.DDT = parseInt(data.DDT);
    if(typeof data.DDP === 'string') data.DDP = parseInt(data.DDP);
    if(typeof data.DBT === 'string') data.DBT = parseInt(data.DBT);
    if(typeof data.DBP === 'string') data.DBP = parseInt(data.DBP);
    if(typeof data.DQT === 'string') data.DQT = parseInt(data.DQT);
    if(typeof data.DQP === 'string') data.DQP = parseInt(data.DQP);
    if(typeof data.DFT === 'string') data.DFT = parseInt(data.DFT);
    if(typeof data.DFP === 'string') data.DFP = parseInt(data.DFP);
    if(typeof data.DTT === 'string') data.DTT = parseInt(data.DTT);
    if(typeof data.DTP === 'string') data.DTP = parseInt(data.DTP);
    if(typeof data.DTB === 'string') data.DTB = parseInt(data.DTB);
    if(typeof data.NTT === 'string') data.NTT = parseInt(data.NTT);
    if(typeof data.NTP === 'string') data.NTP = parseInt(data.NTP);
    if(typeof data.NTB === 'string') data.NTB = parseInt(data.NTB);
    if(typeof data.CIT === 'string') data.CIT = parseInt(data.CIT);
    if(typeof data.CIP === 'string') data.CIP = parseInt(data.CIP);
    if(typeof data.CST === 'string') data.CST = parseInt(data.CST);
    if(typeof data.CSP === 'string') data.CSP = parseInt(data.CSP);
    if(typeof data.CTB === 'string') data.CTB = parseInt(data.CTB);
    if(typeof data.OCT === 'string') data.OCT = parseInt(data.OCT);
    if(typeof data.OCP === 'string') data.OCP = parseInt(data.OCP);
    if(typeof data.CSTB === 'string') data.CSTB = parseInt(data.CSTB);
    if(typeof data.OPT === 'string') data.OPT = parseInt(data.OPT);
    if(typeof data.OPP === 'string') data.OPP = parseInt(data.OPP);
    if(typeof data.PPTB === 'string') data.PPTB = parseInt(data.PPTB);
    if(typeof data.OET === 'string') data.OET = parseInt(data.OET);
    if(typeof data.OEP === 'string') data.OEP = parseInt(data.OEP);
    if(typeof data.ETB === 'string') data.ETB = parseInt(data.ETB);
    if(typeof data.TPT === 'string') data.TPT = parseInt(data.TPT);
    if(typeof data.TPP === 'string') data.TPP = parseInt(data.TPP);
    if(typeof data.TST === 'string') data.TST = parseInt(data.TST);
    if(typeof data.TSP === 'string') data.TSP = parseInt(data.TSP);
    if(typeof data.TET === 'string') data.TET = parseInt(data.TET);
    if(typeof data.TEP === 'string') data.TEP = parseInt(data.TEP);
    if(typeof data.BTT === 'string') data.BTT = parseInt(data.BTT);
    if(typeof data.BTP === 'string') data.BTP = parseInt(data.BTP);
    if(typeof data.ATT === 'string') data.ATT = parseInt(data.ATT);
    if(typeof data.ATP === 'string') data.ATP = parseInt(data.ATP);
    if(typeof data.GTT === 'string') data.GTT = parseInt(data.GTT);
    if(typeof data.GTP === 'string') data.GTP = parseInt(data.GTP);
    if(typeof data.GTB === 'string') data.GTB = parseInt(data.GTB);
    if(typeof data.ZTT === 'string') data.ZTT = parseInt(data.ZTT);
    if(typeof data.ZTP === 'string') data.ZTP = parseInt(data.ZTP);
    if(typeof data.ZTB === 'string') data.ZTB = parseInt(data.ZTB);
    if(typeof data.ETT === 'string') data.ETT = parseInt(data.ETT);
    if(typeof data.ETP === 'string') data.ETP = parseInt(data.ETP);
    if(typeof data.CNDT === 'string') data.CNDT = parseInt(data.CNDT);
    if(typeof data.CNDP === 'string') data.CNDP = parseInt(data.CNDP);
    if(typeof data.CNPT === 'string') data.CNPT = parseInt(data.CNPT);
    if(typeof data.CNPP === 'string') data.CNPP = parseInt(data.CNPP);
    if(typeof data.CNGT === 'string') data.CNGT = parseInt(data.CNGT);
    if(typeof data.CNGP === 'string') data.CNGP = parseInt(data.CNGP);
    if(typeof data.CNAT === 'string') data.CNAT = parseInt(data.CNAT);
    if(typeof data.CNAP === 'string') data.CNAP = parseInt(data.CNAP);
    if(typeof data.CNET === 'string') data.CNET = parseInt(data.CNET);
    if(typeof data.CNEP === 'string') data.CNEP = parseInt(data.CNEP);
    if(typeof data.SNET === 'string') data.SNET = parseInt(data.SNET);
    if(typeof data.SNEP === 'string') data.SNEP = parseInt(data.SNEP);
    if(typeof data.ORST === 'string') data.ORST = parseInt(data.ORST);
    if(typeof data.ORSP === 'string') data.ORSP = parseInt(data.ORSP);
    if(typeof data.SHCT === 'string') data.SHCT = parseInt(data.SHCT);
    if(typeof data.SHCP === 'string') data.SHCP = parseInt(data.SHCP);
    if(typeof data.SHDT === 'string') data.SHDT = parseInt(data.SHDT);
    if(typeof data.SHDP === 'string') data.SHDP = parseInt(data.SHDP);
    if(typeof data.SHRCT === 'string') data.SHRCT = parseInt(data.SHRCT);
    if(typeof data.SHRCP === 'string') data.SHRCP = parseInt(data.SHRCP);
    if(typeof data.SHRDT === 'string') data.SHRDT = parseInt(data.SHRDT);
    if(typeof data.SHRDP === 'string') data.SHRDP = parseInt(data.SHRDP);
    if(typeof data.SHT === 'string') data.SHT = parseInt(data.SHT);
    if(typeof data.SHP === 'string') data.SHP = parseInt(data.SHP);
    if(typeof data.CCDT === 'string') data.CCDT = parseInt(data.CCDT);
    if(typeof data.CDDP === 'string') data.CDDP = parseInt(data.CDDP);
    if(typeof data.CDBT === 'string') data.CDBT = parseInt(data.CDBT);
    if(typeof data.CDBP === 'string') data.CDBP = parseInt(data.CDBP);
    if(typeof data.CDFT === 'string') data.CDFT = parseInt(data.CDFT);
    if(typeof data.CDFP === 'string') data.CDFP = parseInt(data.CDFP);
    if(typeof data.COCT === 'string') data.COCT = parseInt(data.COCT);
    if(typeof data.COCP === 'string') data.COCP = parseInt(data.COCP);
    if(typeof data.COPT === 'string') data.COPT = parseInt(data.COPT);
    if(typeof data.COPP === 'string') data.COPP = parseInt(data.COPP);
    if(typeof data.COET === 'string') data.COET = parseInt(data.COET);
    if(typeof data.COEP === 'string') data.COEP = parseInt(data.COEP);
    if(typeof data.CTPT === 'string') data.CTPT = parseInt(data.CTPT);
    if(typeof data.CTPP === 'string') data.CTPP = parseInt(data.CTPP);
    if(typeof data.CTST === 'string') data.CTST = parseInt(data.CTST);
    if(typeof data.CTSP === 'string') data.CTSP = parseInt(data.CTSP);
    if(typeof data.CTET === 'string') data.CTET = parseInt(data.CTET);
    if(typeof data.CTEP === 'string') data.CTEP = parseInt(data.CTEP);
    if(typeof data.CSTT === 'string') data.CSTT = parseInt(data.CSTT);
    if(typeof data.CSTP === 'string') data.CSTP = parseInt(data.CSTP);
    if(typeof data.PPTT === 'string') data.PPTT = parseInt(data.PPTT);
    if(typeof data.PPTP === 'string') data.PPTP = parseInt(data.PPTP);
    if(typeof data.KKT === 'string') data.KKT = parseInt(data.KKT);
    if(typeof data.KJT === 'string') data.KJT = parseInt(data.KJT);
    if(typeof data.PBT === 'string') data.PBT = parseInt(data.PBT);
  });
  return datas;
}
const parseAttr = (datas: RowData[], attrs: RowAttr[]): RowData[] => {
  datas.forEach((data) => {
    const attr = attrs.find(attr => attr.H1CD == data.H1CD);
    data.CDIV = attr?.CDIV ?? '-';
    data.TDIV = attr?.TDIV ?? '-';
  });
  return datas;
}

 const makeDatas = (datas: RowData[], grouping1: boolean, grouping2: boolean): RowData[] => {
  datas = datas.filter(data => data.TP != "grouping1"); //TC/DC小計行の削除
  datas = datas.filter(data => data.TP != "grouping2"); //自社/委託小計行の削除
  const doGrouping = grouping1;
  if (doGrouping) {
    datas = calcGrouping(datas, grouping1, grouping2); //小計行の作成
  }

  return datas;
};

const calcGrouping = (datas: RowData[], grouping1: boolean, grouping2: boolean): RowData[] => {
  if (!grouping1) {
    return;
  }

  const fncMakeGroupData = (
    data: RowData,
    TP: "grouping1" | "grouping2",
    groupingDiv: 'CDIV' | 'TDIV' | string,
    groupingMap,
    GCD_parent: string,
    groupingDataParent,
  ): [string, RowData] => {
    const groupingCD = getGroupingCD(groupingDiv, data);
    const GCD = (GCD_parent ? (GCD_parent + '_') : '') + groupingCD;
    let groupingData: RowData = groupingMap[GCD];
    if (!groupingData) {
      groupingData = {
        ...{
          TP: TP,
          CAT:  "def",  //暫定的にdefにする
          drillDowned: true,
          CDIV: data.CDIV,
          TDIV: TP === 'grouping2' ? data.TDIV : '',
          H1NM: data[groupingDiv],
        }
      };
      if (TP === 'grouping1') {
        groupingMap[GCD] = groupingData;
      }
      else {
        //子がいなければ追加しない
        const length1 = datas.filter(d => {
          const flg1 = getGroupingCD('CDIV', data) == getGroupingCD('CDIV', d);
          return drillDownLv1MaxLv.indexOf(d.TP) !== -1 && flg1;
        }).length;
        const length2 = datas.filter(d => {
          const flg1 = getGroupingCD('CDIV', data) == getGroupingCD('CDIV', d);
          const flg2 = getGroupingCD('TDIV', data) == getGroupingCD('TDIV', d);
          return drillDownLv1MaxLv.indexOf(d.TP) !== -1 && flg1 && flg2;
        }).length;

        if (length1 != length2) {
          groupingMap[GCD] = groupingData;
        }
      }
    }
    calcTotalSub(groupingData, data);

    return [GCD, groupingData];
  };

  const grouping1Map = {};
  const grouping2Map = {};
  datas = datas.filter(data => data.TP != "grouping1"); //TC/DC小計行の削除
  datas = datas.filter(data => data.TP != "grouping2"); //自社/委託小計行の削除

  //set No.
  const hasItemSubTotal = [grouping1 ? '' : 'item', grouping2 ? '' : 'item',].indexOf('item') != -1;
  const subToralLevel = (grouping2 ? 2 : grouping1 ? 1 : 0) - (hasItemSubTotal ? 1 : 0);

  datas.forEach((data) => {
    if (drillDownLv1MaxLv.indexOf(data.TP) !== -1) {
      let [GCD1, grouping1Data] = fncMakeGroupData(data, "grouping1", "CDIV", grouping1Map, null, {});

      //小計もしくは、内訳で次の階層がある
      if (grouping2 && subToralLevel >= 2) {
        fncMakeGroupData(data, "grouping2", "TDIV", grouping2Map, GCD1, grouping1Data);
      }
    }
  });

  //あとのソートのために、makerは上に入れる。
  const newDatas = [];
  Object.keys(grouping1Map).forEach(GCD1 => newDatas.push(grouping1Map[GCD1]));
  if (grouping2) {
    Object.keys(grouping2Map).forEach(GCD2 => newDatas.push(grouping2Map[GCD2]));
  }
  return [...newDatas, ...datas];
}
const getGroupingCD = (key: 'CDIV' | 'TDIV' | string, data: RowData): string => {
  if (!data) {
    return '';
  }
  switch (key) {
    case 'CDIV':
      return data.CDIV;
    case 'TDIV':
      return data.TDIV;
    default:
      return "";
  }
}

//データをフィルタ
const filterDatas = (datas:RowData[], visibleNoResult:boolean): {datasAll:RowData[], datasFiltered:RowData[]} => {
  const datasFiltered = visibleNoResult ? datas : datas.filter(data => !data.NoResult); //本年実績なしは非表示
  return {datasAll:datas, datasFiltered:datasFiltered};
}
//合計行作成
const calcTotal = (datas:RowData[]): RowData[] => {
  const total:RowData = {
    TP: "total",
    CAT:  "def",  //暫定的にdefにする
    drillDowned: true,
    no:"合計",
  };

  //set No.
  datas.forEach((data) => {
    if(data.TP == "lv1") {
      calcTotalSub(total, data);
    }
  });

  if(datas.length > 0 && datas[0].TP == "total") {
    datas[0] = total;
  }
  else {
    datas = [total, ...datas];
  }

  return datas;
}
//合計計算
export const calcTotalSub = (total:RowData, data:RowData): RowData => {
  //以下に貼り付け
  total.JJT = calcUtil.plus(total.JJT, data.JJT);
  total.JJP = calcUtil.plus(total.JJP, data.JJP);
  total.JKT = calcUtil.plus(total.JKT, data.JKT);
  total.JKP = calcUtil.plus(total.JKP, data.JKP);
  //欠品責任別
  total.KI1KA = calcUtil.plus(total.KI1KA, data.KI1KA);
  total.KI2KA = calcUtil.plus(total.KI2KA, data.KI2KA);
  total.KI3KA = calcUtil.plus(total.KI3KA, data.KI3KA);
  //欠品区分別
  total.K10KA = calcUtil.plus(total.K10KA, data.K10KA);
  total.K11KA = calcUtil.plus(total.K11KA, data.K11KA);
  total.K12KA = calcUtil.plus(total.K12KA, data.K12KA);
  total.K13KA = calcUtil.plus(total.K13KA, data.K13KA);
  total.K14KA = calcUtil.plus(total.K14KA, data.K14KA);
  total.K15KA = calcUtil.plus(total.K15KA, data.K15KA);
  total.K16KA = calcUtil.plus(total.K16KA, data.K16KA);
  total.K17KA = calcUtil.plus(total.K17KA, data.K17KA);
  total.K19KA = calcUtil.plus(total.K19KA, data.K19KA);
  total.K20KA = calcUtil.plus(total.K20KA, data.K20KA);
  total.K21KA = calcUtil.plus(total.K21KA, data.K21KA);
  total.K22KA = calcUtil.plus(total.K22KA, data.K22KA);
  total.K29KA = calcUtil.plus(total.K29KA, data.K29KA);
  total.K30KA = calcUtil.plus(total.K30KA, data.K30KA);
  total.K31KA = calcUtil.plus(total.K31KA, data.K31KA);
  total.K32KA = calcUtil.plus(total.K32KA, data.K32KA);
  total.K33KA = calcUtil.plus(total.K33KA, data.K33KA);
  total.K41KA = calcUtil.plus(total.K41KA, data.K41KA);
  total.K34KA = calcUtil.plus(total.K34KA, data.K34KA);
  total.K39KA = calcUtil.plus(total.K39KA, data.K39KA);
  total.K40KA = calcUtil.plus(total.K40KA, data.K40KA);
  total.K42KA = calcUtil.plus(total.K42KA, data.K42KA);
  total.K43KA = calcUtil.plus(total.K43KA, data.K43KA);
  total.K44KA = calcUtil.plus(total.K44KA, data.K44KA);
  total.K49KA = calcUtil.plus(total.K49KA, data.K49KA);
  total.K50KA = calcUtil.plus(total.K50KA, data.K50KA);

  total.SLT = calcUtil.plus(total.SLT, data.SLT);
  total.SLP = calcUtil.plus(total.SLP, data.SLP);
  total.SDT = calcUtil.plus(total.SDT, data.SDT);
  total.SDP = calcUtil.plus(total.SDP, data.SDP);
  total.SRT = calcUtil.plus(total.SRT, data.SRT);
  total.SRP = calcUtil.plus(total.SRP, data.SRP);
  total.STT = calcUtil.plus(total.STT, data.STT);
  total.STP = calcUtil.plus(total.STP, data.STP);
  total.STB = calcUtil.plus(total.STB, data.STB);
  total.PBLT = calcUtil.plus(total.PBLT, data.PBLT);
  total.PBLP = calcUtil.plus(total.PBLP, data.PBLP);
  total.PBDT = calcUtil.plus(total.PBDT, data.PBDT);
  total.PBDP = calcUtil.plus(total.PBDP, data.PBDP);
  total.PBRT = calcUtil.plus(total.PBRT, data.PBRT);
  total.PBRP = calcUtil.plus(total.PBRP, data.PBRP);
  total.PBTT = calcUtil.plus(total.PBTT, data.PBTT);
  total.PBTP = calcUtil.plus(total.PBTP, data.PBTP);
  total.YHLT = calcUtil.plus(total.YHLT, data.YHLT);
  total.YHLP = calcUtil.plus(total.YHLP, data.YHLP);
  total.YHDT = calcUtil.plus(total.YHDT, data.YHDT);
  total.YHDP = calcUtil.plus(total.YHDP, data.YHDP);
  total.YHRT = calcUtil.plus(total.YHRT, data.YHRT);
  total.YHRP = calcUtil.plus(total.YHRP, data.YHRP);
  total.YHTT = calcUtil.plus(total.YHTT, data.YHTT);
  total.YHTP = calcUtil.plus(total.YHTP, data.YHTP);
  total.PTT = calcUtil.plus(total.PTT, data.PTT);
  total.PTP = calcUtil.plus(total.PTP, data.PTP);
  total.PTB = calcUtil.plus(total.PTB, data.PTB);
  total.DDT = calcUtil.plus(total.DDT, data.DDT);
  total.DDP = calcUtil.plus(total.DDP, data.DDP);
  total.DBT = calcUtil.plus(total.DBT, data.DBT);
  total.DBP = calcUtil.plus(total.DBP, data.DBP);
  total.DQT = calcUtil.plus(total.DQT, data.DQT);
  total.DQP = calcUtil.plus(total.DQP, data.DQP);
  total.DFT = calcUtil.plus(total.DFT, data.DFT);
  total.DFP = calcUtil.plus(total.DFP, data.DFP);
  total.DTT = calcUtil.plus(total.DTT, data.DTT);
  total.DTP = calcUtil.plus(total.DTP, data.DTP);
  total.DTB = calcUtil.plus(total.DTB, data.DTB);
  total.NTT = calcUtil.plus(total.NTT, data.NTT);
  total.NTP = calcUtil.plus(total.NTP, data.NTP);
  total.NTB = calcUtil.plus(total.NTB, data.NTB);
  total.CIT = calcUtil.plus(total.CIT, data.CIT);
  total.CIP = calcUtil.plus(total.CIP, data.CIP);
  total.CST = calcUtil.plus(total.CST, data.CST);
  total.CSP = calcUtil.plus(total.CSP, data.CSP);
  total.CTB = calcUtil.plus(total.CTB, data.CTB);
  total.OCT = calcUtil.plus(total.OCT, data.OCT);
  total.OCP = calcUtil.plus(total.OCP, data.OCP);
  total.CSTB = calcUtil.plus(total.CSTB, data.CSTB);
  total.OPT = calcUtil.plus(total.OPT, data.OPT);
  total.OPP = calcUtil.plus(total.OPP, data.OPP);
  total.PPTB = calcUtil.plus(total.PPTB, data.PPTB);
  total.OET = calcUtil.plus(total.OET, data.OET);
  total.OEP = calcUtil.plus(total.OEP, data.OEP);
  total.ETB = calcUtil.plus(total.ETB, data.ETB);
  total.TPT = calcUtil.plus(total.TPT, data.TPT);
  total.TPP = calcUtil.plus(total.TPP, data.TPP);
  total.TST = calcUtil.plus(total.TST, data.TST);
  total.TSP = calcUtil.plus(total.TSP, data.TSP);
  total.TET = calcUtil.plus(total.TET, data.TET);
  total.TEP = calcUtil.plus(total.TEP, data.TEP);
  total.BTT = calcUtil.plus(total.BTT, data.BTT);
  total.BTP = calcUtil.plus(total.BTP, data.BTP);
  total.ATT = calcUtil.plus(total.ATT, data.ATT);
  total.ATP = calcUtil.plus(total.ATP, data.ATP);
  total.GTT = calcUtil.plus(total.GTT, data.GTT);
  total.GTP = calcUtil.plus(total.GTP, data.GTP);
  total.GTB = calcUtil.plus(total.GTB, data.GTB);
  total.ZTT = calcUtil.plus(total.ZTT, data.ZTT);
  total.ZTP = calcUtil.plus(total.ZTP, data.ZTP);
  total.ZTB = calcUtil.plus(total.ZTB, data.ZTB);
  total.ETT = calcUtil.plus(total.ETT, data.ETT);
  total.ETP = calcUtil.plus(total.ETP, data.ETP);
  total.CNDT = calcUtil.plus(total.CNDT, data.CNDT);
  total.CNDP = calcUtil.plus(total.CNDP, data.CNDP);
  total.CNPT = calcUtil.plus(total.CNPT, data.CNPT);
  total.CNPP = calcUtil.plus(total.CNPP, data.CNPP);
  total.CNGT = calcUtil.plus(total.CNGT, data.CNGT);
  total.CNGP = calcUtil.plus(total.CNGP, data.CNGP);
  total.CNAT = calcUtil.plus(total.CNAT, data.CNAT);
  total.CNAP = calcUtil.plus(total.CNAP, data.CNAP);
  total.CNET = calcUtil.plus(total.CNET, data.CNET);
  total.CNEP = calcUtil.plus(total.CNEP, data.CNEP);
  total.SNET = calcUtil.plus(total.SNET, data.SNET);
  total.SNEP = calcUtil.plus(total.SNEP, data.SNEP);
  total.ORST = calcUtil.plus(total.ORST, data.ORST);
  total.ORSP = calcUtil.plus(total.ORSP, data.ORSP);
  total.SHCT = calcUtil.plus(total.SHCT, data.SHCT);
  total.SHCP = calcUtil.plus(total.SHCP, data.SHCP);
  total.SHDT = calcUtil.plus(total.SHDT, data.SHDT);
  total.SHDP = calcUtil.plus(total.SHDP, data.SHDP);
  total.SHRCT = calcUtil.plus(total.SHRCT, data.SHRCT);
  total.SHRCP = calcUtil.plus(total.SHRCP, data.SHRCP);
  total.SHRDT = calcUtil.plus(total.SHRDT, data.SHRDT);
  total.SHRDP = calcUtil.plus(total.SHRDP, data.SHRDP);
  total.SHT = calcUtil.plus(total.SHT, data.SHT);
  total.SHP = calcUtil.plus(total.SHP, data.SHP);
  total.CCDT = calcUtil.plus(total.CCDT, data.CCDT);
  total.CDDP = calcUtil.plus(total.CDDP, data.CDDP);
  total.CDBT = calcUtil.plus(total.CDBT, data.CDBT);
  total.CDBP = calcUtil.plus(total.CDBP, data.CDBP);
  total.CDFT = calcUtil.plus(total.CDFT, data.CDFT);
  total.CDFP = calcUtil.plus(total.CDFP, data.CDFP);
  total.COCT = calcUtil.plus(total.COCT, data.COCT);
  total.COCP = calcUtil.plus(total.COCP, data.COCP);
  total.COPT = calcUtil.plus(total.COPT, data.COPT);
  total.COPP = calcUtil.plus(total.COPP, data.COPP);
  total.COET = calcUtil.plus(total.COET, data.COET);
  total.COEP = calcUtil.plus(total.COEP, data.COEP);
  total.CTPT = calcUtil.plus(total.CTPT, data.CTPT);
  total.CTPP = calcUtil.plus(total.CTPP, data.CTPP);
  total.CTST = calcUtil.plus(total.CTST, data.CTST);
  total.CTSP = calcUtil.plus(total.CTSP, data.CTSP);
  total.CTET = calcUtil.plus(total.CTET, data.CTET);
  total.CTEP = calcUtil.plus(total.CTEP, data.CTEP);
  total.CSTT = calcUtil.plus(total.CSTT, data.CSTT);
  total.CSTP = calcUtil.plus(total.CSTP, data.CSTP);
  total.PPTT = calcUtil.plus(total.PPTT, data.PPTT);
  total.PPTP = calcUtil.plus(total.PPTP, data.PPTP);
  total.KKT = calcUtil.plus(total.KKT, data.KKT);
  total.KJT = calcUtil.plus(total.KJT, data.KJT);
  total.PBT = calcUtil.plus(total.PBT, data.PBT);

  //sej含む場合はmix
  if(data.CAT == "mix" || data.CAT == "sej" ) {
    total.CAT = 'mix';
  }

  return total;
};

//計算
export const calcDatas = (datas: RowData[], datasAll: RowData[], axisTypes: ("group1" | "group2" | "shiten" | "center" | "shiire" | "maker" | "tokuisaki" | "category" | string)[]): RowData[] => {
  const total = datasAll.find(data => data.TP == "total");

  const itemAttrFlgs:boolean[] = [];
  axisTypes.forEach((axisType, index) => {
    itemAttrFlgs.push(itemAttrFlgs[index-1] || ["maker","category",].indexOf(axisType) >= 0);
  });

  datas.forEach((data) => {

    const lv = 
      data.TP == "lv8" ? 8 :
      data.TP == "lv7" ? 7 :
      data.TP == "lv6" ? 6 :
      data.TP == "lv5" ? 5 :
      data.TP == "lv4" ? 4 :
      data.TP == "lv3" ? 3 :
      data.TP == "lv2" ? 2 :
      1;
    //構成比の計算
    const parentIndex = 
      data.TP == "lv8" ? dataIndexOfLv7(datasAll, data.CAT, data.H1ID, data.H2ID, data.H3ID, data.H4ID, data.H5ID, data.H6ID, data.H7ID) :
      data.TP == "lv7" ? dataIndexOfLv6(datasAll, data.CAT, data.H1ID, data.H2ID, data.H3ID, data.H4ID, data.H5ID, data.H6ID) :
      data.TP == "lv6" ? dataIndexOfLv5(datasAll, data.CAT, data.H1ID, data.H2ID, data.H3ID, data.H4ID, data.H5ID) :
      data.TP == "lv5" ? dataIndexOfLv4(datasAll, data.CAT, data.H1ID, data.H2ID, data.H3ID, data.H4ID) :
      data.TP == "lv4" ? dataIndexOfLv3(datasAll, data.CAT, data.H1ID, data.H2ID, data.H3ID) :
      data.TP == "lv3" ? dataIndexOfLv2(datasAll, data.CAT, data.H1ID, data.H2ID) :
      data.TP == "lv2" ? dataIndexOfLv1(datasAll, data.CAT, data.H1ID) :
      -1;

    const parent = parentIndex == -1 ? total : datasAll[parentIndex];
    const itemAttrFlg:boolean = itemAttrFlgs[lv-1];

    calcData(data, parentIndex, parent, itemAttrFlg);
    calcDataPl(data, lv, axisTypes.slice(0, lv));
    data.keppinRnkColKeys = calcDataKeppinRnk(data);

    data.NoResult = (
      !data.JJT &&
      !data.JKT &&
      !data.JRT &&
      !data.SLT &&
      !data.SDT &&
      !data.SRT &&
      !data.STT &&
      !data.PBLT &&
      !data.PBDT &&
      !data.PBRT &&
      !data.PBTT &&
      !data.PBHT &&
      !data.YHLT &&
      !data.YHDT &&
      !data.YHRT &&
      !data.YHTT &&
      !data.YHHT &&
      !data.PTT &&
      !data.PRT &&
      !data.DDT &&
      !data.DBT &&
      !data.DQT &&
      !data.DFT &&
      !data.DTT &&
      !data.DRT &&
      !data.NTT &&
      !data.CIT &&
      !data.CST &&
      !data.CTT &&
      !data.OCT &&
      !data.TST &&
      !data.CSTT &&
      !data.CSTRT &&
      !data.OPT &&
      !data.TPT &&
      !data.PPTT &&
      !data.PPTRT &&
      !data.OET &&
      !data.TET &&
      !data.ETT &&
      !data.ETRT &&
      !data.KTT &&
      !data.KRT &&
      !data.BTT &&
      !data.BRT &&
      !data.ATT &&
      !data.ART &&
      !data.GTT &&
      !data.GRT &&
      !data.ZTT &&
      !data.ZRT &&
      !data.ZCT &&
      !data.ZCRT &&
      !data.CNDT &&
      !data.CNDRT &&
      !data.CNPT &&
      !data.CNPRT &&
      !data.CNGT &&
      !data.CNGRT &&
      !data.CNAT &&
      !data.CNART &&
      !data.CNET &&
      !data.CNRT &&
      !data.SNET &&
      !data.SNRT &&
      !data.ORST &&
      !data.ORSRT &&
      !data.ORPT &&
      !data.ORPRT &&
      !data.SHCT &&
      !data.SHDT &&
      !data.SHRCT &&
      !data.SHRDT &&
      !data.SHT &&
      !data.CCDT &&
      !data.CDBT &&
      !data.CDFT &&
      !data.COCT &&
      !data.COPT &&
      !data.COET &&
      !data.CTPT &&
      !data.CTST &&
      !data.CTET
    );
  });

  return datas;
}

export const calcData = (data:RowData, parentIndex:number, parent:RowData, itemAttrFlg:boolean): RowData => {
  //商品属性の場合、得意先課金を売上比按分する
  if(itemAttrFlg && parentIndex >= 0 && parent) {

    const STTK = calcUtil.divide(data.STT, parent.STT);
    const STPK = calcUtil.divide(data.STP, parent.STP);

    data.DDT = calcUtil.multiply(parent.DDT, STTK);// DDT: "値引",	
    data.DDP = calcUtil.multiply(parent.DDP, STPK);// DDP: "値引",	
    data.DBT = calcUtil.multiply(parent.DBT, STTK);// DBT: "リベート",	
    data.DBP = calcUtil.multiply(parent.DBP, STPK);// DBP: "リベート",	
    data.DQT = calcUtil.multiply(parent.DQT, STTK);// DQT: "リベート建値",	
    data.DQP = calcUtil.multiply(parent.DQP, STPK);// DQP: "リベート建値",	
    data.DFT = calcUtil.multiply(parent.DFT, STTK);// DFT: "振込リベート",	
    data.DFP = calcUtil.multiply(parent.DFP, STPK);// DFP: "振込リベート",	
    data.DTT = calcUtil.multiply(parent.DTT, STTK);// DTT: "値引計",	
    data.DTP = calcUtil.multiply(parent.DTP, STPK);// DTP: "値引計",	

    data.OCT = calcUtil.multiply(parent.OCT, STTK);// OCT: "センターフィ（相殺）",	
    data.OCP = calcUtil.multiply(parent.OCP, STPK);// OCP: "センターフィ（相殺）",	
    data.TST = calcUtil.multiply(parent.TST, STTK);// TST: "業務委託費（振込）",	
    data.TSP = calcUtil.multiply(parent.TSP, STPK);// TSP: "業務委託費（振込）",	
    data.OPT = calcUtil.multiply(parent.OPT, STTK);// OPT: "販促費（相殺）",	
    data.OPP = calcUtil.multiply(parent.OPP, STPK);// OPP: "販促費（相殺）",	
    data.TPT = calcUtil.multiply(parent.TPT, STTK);// TPT: "販促費（振込）",	
    data.TPP = calcUtil.multiply(parent.TPP, STPK);// TPP: "販促費（振込）",	
    data.OET = calcUtil.multiply(parent.OET, STTK);// OET: "ＥＯＳ費・他（相殺）",	
    data.OEP = calcUtil.multiply(parent.OEP, STPK);// OEP: "ＥＯＳ費・他（相殺）",	
    data.TET = calcUtil.multiply(parent.TET, STTK);// TET: "ＥＯＳ費・他（振込）",	
    data.TEP = calcUtil.multiply(parent.TEP, STPK);// TEP: "ＥＯＳ費・他（振込）",	
  }

  //ここに貼り付け
  data.JJR = calcUtil.divide(data.JJT, data.JJP);
  data.JJD = calcUtil.minus(data.JJT, data.JJP);

  data.JKT = calcUtil.greater(0, calcUtil.minus(data.JJT, data.SLT)); //=【受注額】本年実績-【配送売上】本年実績 //0未満は0表記
  data.JKP = calcUtil.greater(0, calcUtil.minus(data.JJP, data.SLP)); //=【受注額】前年実績-【配送売上】前年実績 //0未満は0表記

  data.JKR = calcUtil.divide(data.JKT, data.JKP);
  data.JKD = calcUtil.minus(data.JKT, data.JKP);
  data.JRT = calcUtil.divide(data.JKT, data.JJT);
  data.JRP = calcUtil.divide(data.JKP, data.JJP);
  data.JRD = calcUtil.multiply(calcUtil.minus(data.JRT, data.JRP),100);

  //1.自社責に寄せる //0未満は0表記
  data.KI1KA = calcUtil.greater(0, calcUtil.minus(data.JKT, calcUtil.sum([data.KI2KA, data.KI3KA])));
  //10.未入力に寄せる //0未満は0表記
  data.K10KA = calcUtil.greater(0, calcUtil.minus(data.JKT, calcUtil.sum([
    data.K11KA, data.K12KA, data.K13KA, data.K14KA, data.K15KA, data.K16KA, data.K17KA, data.K19KA,
    data.K20KA, data.K21KA, data.K22KA, data.K29KA,
    data.K30KA, data.K31KA, data.K32KA, data.K33KA, data.K34KA, data.K39KA,
    data.K40KA, data.K41KA, data.K42KA, data.K43KA, data.K44KA, data.K49KA,
    data.K50KA,
  ])));

  //欠品責任別
  data.KI1KR = calcUtil.divide(data.KI1KA, data.JJT);
  data.KI2KR = calcUtil.divide(data.KI2KA, data.JJT);
  data.KI3KR = calcUtil.divide(data.KI3KA, data.JJT);
  //欠品区分別
  data.K10KR = calcUtil.divide(data.K10KA, data.JJT);
  data.K11KR = calcUtil.divide(data.K11KA, data.JJT);
  data.K12KR = calcUtil.divide(data.K12KA, data.JJT);
  data.K13KR = calcUtil.divide(data.K13KA, data.JJT);
  data.K14KR = calcUtil.divide(data.K14KA, data.JJT);
  data.K15KR = calcUtil.divide(data.K15KA, data.JJT);
  data.K16KR = calcUtil.divide(data.K16KA, data.JJT);
  data.K17KR = calcUtil.divide(data.K17KA, data.JJT);
  data.K19KR = calcUtil.divide(data.K19KA, data.JJT);
  data.K20KR = calcUtil.divide(data.K20KA, data.JJT);
  data.K21KR = calcUtil.divide(data.K21KA, data.JJT);
  data.K22KR = calcUtil.divide(data.K22KA, data.JJT);
  data.K29KR = calcUtil.divide(data.K29KA, data.JJT);
  data.K30KR = calcUtil.divide(data.K30KA, data.JJT);
  data.K31KR = calcUtil.divide(data.K31KA, data.JJT);
  data.K32KR = calcUtil.divide(data.K32KA, data.JJT);
  data.K33KR = calcUtil.divide(data.K33KA, data.JJT);
  data.K41KR = calcUtil.divide(data.K41KA, data.JJT);
  data.K34KR = calcUtil.divide(data.K34KA, data.JJT);
  data.K39KR = calcUtil.divide(data.K39KA, data.JJT);
  data.K40KR = calcUtil.divide(data.K40KA, data.JJT);
  data.K42KR = calcUtil.divide(data.K42KA, data.JJT);
  data.K43KR = calcUtil.divide(data.K43KA, data.JJT);
  data.K44KR = calcUtil.divide(data.K44KA, data.JJT);
  data.K49KR = calcUtil.divide(data.K49KA, data.JJT);
  data.K50KR = calcUtil.divide(data.K50KA, data.JJT);  
  
  data.SLR = calcUtil.divide(data.SLT, data.SLP);
  data.SLD = calcUtil.minus(data.SLT, data.SLP);
  data.SDR = calcUtil.divide(data.SDT, data.SDP);
  data.SDD = calcUtil.minus(data.SDT, data.SDP);
  data.SRR = calcUtil.divide(data.SRT, data.SRP);
  data.SRD = calcUtil.minus(data.SRT, data.SRP);
  data.STR = calcUtil.divide(data.STT, data.STP);
  data.STD = calcUtil.minus(data.STT, data.STP);
  data.STBR = calcUtil.divide(data.STT, data.STB);
  data.STBD = calcUtil.minus(data.STT, data.STB);
  data.PBTT = calcUtil.plus(calcUtil.plus(data.PBLT, data.PBDT), data.PBRT);
  data.PBTP = calcUtil.plus(calcUtil.plus(data.PBLP, data.PBDP), data.PBRP);
  data.PBLR = calcUtil.divide(data.PBLT, data.PBLP);
  data.PBLD = calcUtil.minus(data.PBLT, data.PBLP);
  data.PBDR = calcUtil.divide(data.PBDT, data.PBDP);
  data.PBDD = calcUtil.minus(data.PBDT, data.PBDP);
  data.PBRR = calcUtil.divide(data.PBRT, data.PBRP);
  data.PBRD = calcUtil.minus(data.PBRT, data.PBRP);
  data.PBTR = calcUtil.divide(data.PBTT, data.PBTP);
  data.PBTD = calcUtil.minus(data.PBTT, data.PBTP);
  data.PBHT = calcUtil.divide(data.PBTT, data.STT);
  data.PBHP = calcUtil.divide(data.PBTP, data.STP);
  data.PBHD = calcUtil.multiply(calcUtil.minus(data.PBHT, data.PBHP),100);
  data.YHTT = calcUtil.plus(calcUtil.plus(data.YHLT, data.YHDT), data.YHRT);
  data.YHTP = calcUtil.plus(calcUtil.plus(data.YHLP, data.YHDP), data.YHRP);
  data.YHLR = calcUtil.divide(data.YHLT, data.YHLP);
  data.YHLD = calcUtil.minus(data.YHLT, data.YHLP);
  data.YHDR = calcUtil.divide(data.YHDT, data.YHDP);
  data.YHDD = calcUtil.minus(data.YHDT, data.YHDP);
  data.YHRR = calcUtil.divide(data.YHRT, data.YHRP);
  data.YHRD = calcUtil.minus(data.YHRT, data.YHRP);
  data.YHTR = calcUtil.divide(data.YHTT, data.YHTP);
  data.YHTD = calcUtil.minus(data.YHTT, data.YHTP);
  data.YHHT = calcUtil.divide(data.YHTT, data.STT);
  data.YHHP = calcUtil.divide(data.YHTP, data.STP);
  data.YHHD = calcUtil.multiply(calcUtil.minus(data.YHHT, data.YHHP),100);
  data.PTR = calcUtil.divide(data.PTT, data.PTP);
  data.PTD = calcUtil.minus(data.PTT, data.PTP);
  data.PTBR = calcUtil.divide(data.PTT, data.PTB);
  data.PTBD = calcUtil.minus(data.PTT, data.PTB);
  data.PRT = calcUtil.divide(data.PTT, data.STT);
  data.PRP = calcUtil.divide(data.PTP, data.STP);
  data.PRD = calcUtil.multiply(calcUtil.minus(data.PRT, data.PRP),100);
  data.DDR = calcUtil.divide(data.DDT, data.DDP);
  data.DDD = calcUtil.minus(data.DDT, data.DDP);
  data.DBR = calcUtil.divide(data.DBT, data.DBP);
  data.DBD = calcUtil.minus(data.DBT, data.DBP);
  data.DQR = calcUtil.divide(data.DQT, data.DQP);
  data.DQD = calcUtil.minus(data.DQT, data.DQP);
  data.DFR = calcUtil.divide(data.DFT, data.DFP);
  data.DFD = calcUtil.minus(data.DFT, data.DFP);
  data.DTR = calcUtil.divide(data.DTT, data.DTP);
  data.DTD = calcUtil.minus(data.DTT, data.DTP);
  data.DTBR = calcUtil.divide(data.DTT, data.DTB);
  data.DTBD = calcUtil.minus(data.DTT, data.DTB);
  data.DRT = calcUtil.divide(data.DTT, data.STT);
  data.DRP = calcUtil.divide(data.DTP, data.STP);
  data.DRD = calcUtil.multiply(calcUtil.minus(data.DRT, data.DRP),100);
  data.NTR = calcUtil.divide(data.NTT, data.NTP);
  data.NTD = calcUtil.minus(data.NTT, data.NTP);
  data.NTBR = calcUtil.divide(data.NTT, data.NTB);
  data.NTBD = calcUtil.minus(data.NTT, data.NTB);
  data.CIR = calcUtil.divide(data.CIT, data.CIP);
  data.CID = calcUtil.minus(data.CIT, data.CIP);
  data.CSR = calcUtil.divide(data.CST, data.CSP);
  data.CSD = calcUtil.minus(data.CST, data.CSP);
  data.CTT = calcUtil.sum([data.CIT, data.CST]);
  data.CTP = calcUtil.sum([data.CIP, data.CSP]);
  data.CTR = calcUtil.divide(data.CTT, data.CTP);
  data.CTD = calcUtil.minus(data.CTT, data.CTP);
  data.CTBR = calcUtil.divide(data.CTT, data.CTB);
  data.CTBD = calcUtil.minus(data.CTT, data.CTB);
  data.OCR = calcUtil.divide(data.OCT, data.OCP);
  data.OCD = calcUtil.minus(data.OCT, data.OCP);
  data.TSR = calcUtil.divide(data.TST, data.TSP);
  data.TSD = calcUtil.minus(data.TST, data.TSP);
  data.CSTT = calcUtil.sum([data.OCT, data.TST]);
  data.CSTP = calcUtil.sum([data.OCP, data.TSP]);
  data.CSTR = calcUtil.divide(data.CSTT, data.CSTP);
  data.CSTD = calcUtil.minus(data.CSTT, data.CSTP);
  data.CSTBR = calcUtil.divide(data.CSTT, data.CSTB);
  data.CSTBD = calcUtil.minus(data.CSTT, data.CSTB);
  data.CSTRT = calcUtil.divide(data.CSTT, data.STT);
  data.CSTRP = calcUtil.divide(data.CSTP, data.STP);
  data.CSTRD = calcUtil.multiply(calcUtil.minus(data.CSTRT, data.CSTRP), 100);
  data.OPR = calcUtil.divide(data.OPT, data.OPP);
  data.OPD = calcUtil.minus(data.OPT, data.OPP);
  data.TPR = calcUtil.divide(data.TPT, data.TPP);
  data.TPD = calcUtil.minus(data.TPT, data.TPP);
  data.PPTT = calcUtil.sum([data.OPT, data.TPT]);
  data.PPTP = calcUtil.sum([data.OPP, data.TPP]);
  data.PPTR = calcUtil.divide(data.PPTT, data.PPTP);
  data.PPTD = calcUtil.minus(data.PPTT, data.PPTP);
  data.PPTBR = calcUtil.divide(data.PPTT, data.PPTB);
  data.PPTBD = calcUtil.minus(data.PPTT, data.PPTB);
  data.PPTRT = calcUtil.divide(data.PPTT, data.STT);
  data.PPTRP = calcUtil.divide(data.PPTP, data.STP);
  data.PPTRD = calcUtil.multiply(calcUtil.minus(data.PPTRT, data.PPTRP), 100);
  data.OER = calcUtil.divide(data.OET, data.OEP);
  data.OED = calcUtil.minus(data.OET, data.OEP);
  data.TER = calcUtil.divide(data.TET, data.TEP);
  data.TED = calcUtil.minus(data.TET, data.TEP);
  data.ETT = calcUtil.sum([data.OET, data.TET]);
  data.ETP = calcUtil.sum([data.OEP, data.TEP]);
  data.ETR = calcUtil.divide(data.ETT, data.ETP);
  data.ETD = calcUtil.minus(data.ETT, data.ETP);
  data.ETBR = calcUtil.divide(data.ETT, data.ETB);
  data.ETBD = calcUtil.minus(data.ETT, data.ETB);
  data.ETRT = calcUtil.divide(data.ETT, data.STT);
  data.ETRP = calcUtil.divide(data.ETP, data.STP);
  data.ETRD = calcUtil.multiply(calcUtil.minus(data.ETRT, data.ETRP), 100);
  data.KTT = calcUtil.sum([data.CSTT, data.PPTT, data.ETT]);
  data.KTP = calcUtil.sum([data.CSTP, data.PPTP, data.ETP]);
  data.KTR = calcUtil.divide(data.KTT, data.KTP);
  data.KTD = calcUtil.minus(data.KTT, data.KTP);
  data.KRT = calcUtil.divide(data.KTT, data.STT);
  data.KRP = calcUtil.divide(data.KTP, data.STP);
  data.KRD = calcUtil.multiply(calcUtil.minus(data.KRT, data.KRP),100);

  //商品属性の場合、//修正前企業粗利 = 売買差益-値引計-得意先課金計
  if(itemAttrFlg && parentIndex >= 0 && parent) {
    data.BTT = calcUtil.minus(calcUtil.minus(data.PTT, data.DTT), data.KTT)
    data.BTP = calcUtil.minus(calcUtil.minus(data.PTP, data.DTP), data.KTP)
  }

  data.BTR = calcUtil.divide(data.BTT, data.BTP);
  data.BTD = calcUtil.minus(data.BTT, data.BTP);
  data.BRT = calcUtil.divide(data.BTT, data.STT);
  data.BRP = calcUtil.divide(data.BTP, data.STP);
  data.BRD = calcUtil.multiply(calcUtil.minus(data.BRT, data.BRP),100);
  data.ATR = calcUtil.divide(data.ATT, data.ATP);
  data.ATD = calcUtil.minus(data.ATT, data.ATP);
  data.ART = calcUtil.divide(data.ATT, data.STT);
  data.ARP = calcUtil.divide(data.ATP, data.STP);
  data.ARD = calcUtil.multiply(calcUtil.minus(data.ART, data.ARP),100);
  data.GTT = calcUtil.sum([data.BTT, data.DTT, data.CTT, data.CSTT, data.PPTT, data.ETT]);
  data.GTP = calcUtil.sum([data.BTP, data.DTP, data.CTP, data.CSTP, data.PPTP, data.ETP]);
  data.GTR = calcUtil.divide(data.GTT, data.GTP);
  data.GTD = calcUtil.minus(data.GTT, data.GTP);
  data.GTBR = calcUtil.divide(data.GTT, data.GTB);
  data.GTBD = calcUtil.minus(data.GTT, data.GTB);
  data.GRT = calcUtil.divide(data.GTT, data.STT);
  data.GRP = calcUtil.divide(data.GTP, data.STP);
  data.GRD = calcUtil.multiply(calcUtil.minus(data.GRT, data.GRP),100);
  data.ZTT = calcUtil.sum([data.BTT, data.CTT, data.CSTT, data.PPTT, data.ETT]);
  data.ZTP = calcUtil.sum([data.BTP, data.CTP, data.CSTP, data.PPTP, data.ETP]);
  data.ZTR = calcUtil.divide(data.ZTT, data.ZTP);
  data.ZTD = calcUtil.minus(data.ZTT, data.ZTP);
  data.ZTBR = calcUtil.divide(data.ZTT, data.ZTB);
  data.ZTBD = calcUtil.minus(data.ZTT, data.ZTB);
  data.ZRT = calcUtil.divide(data.ZTT, data.NTT);
  data.ZRP = calcUtil.divide(data.ZTP, data.NTP);
  data.ZRD = calcUtil.multiply(calcUtil.minus(data.ZRT, data.ZRP),100);
  data.ZCT = calcUtil.minus(data.ZTT, data.KTT);
  data.ZCP = calcUtil.minus(data.ZTP, data.KTP);
  data.ZCR = calcUtil.divide(data.ZCT, data.ZCP);
  data.ZCD = calcUtil.minus(data.ZCT, data.ZCP);
  data.ZCRT = calcUtil.divide(data.ZCT, data.NTT);
  data.ZCRP = calcUtil.divide(data.ZCP, data.NTP);
  data.ZCRD = calcUtil.multiply(calcUtil.minus(data.ZCRT, data.ZCRP),100);
  data.CNDR = calcUtil.divide(data.CNDT, data.CNDP);
  data.CNDD = calcUtil.minus(data.CNDT, data.CNDP);
  data.CNPR = calcUtil.divide(data.CNPT, data.CNPP);
  data.CNPD = calcUtil.minus(data.CNPT, data.CNPP);
  data.CNGR = calcUtil.divide(data.CNGT, data.CNGP);
  data.CNGD = calcUtil.minus(data.CNGT, data.CNGP);
  data.CNAR = calcUtil.divide(data.CNAT, data.CNAP);
  data.CNAD = calcUtil.minus(data.CNAT, data.CNAP);
  data.CNER = calcUtil.divide(data.CNET, data.CNEP);
  data.CNED = calcUtil.minus(data.CNET, data.CNEP);
  data.CNDRT = calcUtil.divide(data.CNDT, calcUtil.plus(data.SLT, data.SRT));
  data.CNDRP = calcUtil.divide(data.CNDP, data.SLP);
  data.CNDRD = calcUtil.multiply(calcUtil.minus(data.CNDRT, data.CNDRP), 100);
  data.CNPRT = calcUtil.divide(data.CNPT, calcUtil.plus(data.SLT, data.SRT));
  data.CNPRP = calcUtil.divide(data.CNPP, data.SLP);
  data.CNPRD = calcUtil.multiply(calcUtil.minus(data.CNPRT, data.CNPRP), 100);
  data.CNGRT = calcUtil.divide(data.CNGT, calcUtil.plus(data.SLT, data.SRT));
  data.CNGRP = calcUtil.divide(data.CNGP, data.SLP);
  data.CNGRD = calcUtil.multiply(calcUtil.minus(data.CNGRT, data.CNGRP), 100);
  data.CNART = calcUtil.divide(data.CNAT, calcUtil.plus(data.SLT, data.SRT));
  data.CNARP = calcUtil.divide(data.CNAP, data.SLP);
  data.CNARD = calcUtil.multiply(calcUtil.minus(data.CNART, data.CNARP), 100);
  data.CNRT = calcUtil.divide(data.CNET, calcUtil.plus(data.SLT, data.SRT));
  data.CNRP = calcUtil.divide(data.CNEP, data.SLP);
  data.CNRD = calcUtil.multiply(calcUtil.minus(data.CNRT, data.CNRP), 100);
  data.SNER = calcUtil.divide(data.SNET, data.SNEP);
  data.SNED = calcUtil.minus(data.SNET, data.SNEP);
  data.SNRT = calcUtil.divide(data.SNET, data.STT);
  data.SNRP = calcUtil.divide(data.SNEP, data.STP);
  data.SNRD = calcUtil.multiply(calcUtil.minus(data.SNRT, data.SNRP), 100);
  data.ORSR = calcUtil.divide(data.ORST, data.ORSP);
  data.ORSD = calcUtil.minus(data.ORST, data.ORSP);
  data.ORSRT = calcUtil.divide(data.ORST, data.STT);
  data.ORSRP = calcUtil.divide(data.ORSP, data.STP);
  data.ORSRD = calcUtil.multiply(calcUtil.minus(data.ORSRT, data.ORSRP), 100);
  data.ORPT = calcUtil.minus(data.ZCT, data.ORST);
  data.ORPP = calcUtil.minus(data.ZCP, data.ORSP);
  data.ORPR = calcUtil.divide(data.ORPT, data.ORPP);
  data.ORPD = calcUtil.minus(data.ORPT, data.ORPP);
  data.ORPRT = calcUtil.divide(data.ORPT, data.STT);
  data.ORPRP = calcUtil.divide(data.ORPP, data.STP);
  data.ORPRD = calcUtil.multiply(calcUtil.minus(data.ORPRT, data.ORPRP), 100);
  data.SHCR = calcUtil.divide(data.SHCT, data.SHCP);
  data.SHCD = calcUtil.minus(data.SHCT, data.SHCP);
  data.SHDR = calcUtil.divide(data.SHDT, data.SHDP);
  data.SHDD = calcUtil.minus(data.SHDT, data.SHDP);
  data.SHRCR = calcUtil.divide(data.SHRCT, data.SHRCP);
  data.SHRCD = calcUtil.minus(data.SHRCT, data.SHRCP);
  data.SHRDR = calcUtil.divide(data.SHRDT, data.SHRDP);
  data.SHRDD = calcUtil.minus(data.SHRDT, data.SHRDP);
  data.SHR = calcUtil.divide(data.SHT, data.SHP);
  data.SHD = calcUtil.minus(data.SHT, data.SHP);
  data.CDDR = calcUtil.divide(data.CCDT, data.CDDP);
  data.CDDD = calcUtil.minus(data.CCDT, data.CDDP);
  data.CDBR = calcUtil.divide(data.CDBT, data.CDBP);
  data.CDBD = calcUtil.minus(data.CDBT, data.CDBP);
  data.CDFR = calcUtil.divide(data.CDFT, data.CDFP);
  data.CDFD = calcUtil.minus(data.CDFT, data.CDFP);
  data.COCR = calcUtil.divide(data.COCT, data.COCP);
  data.COCD = calcUtil.minus(data.COCT, data.COCP);
  data.COPR = calcUtil.divide(data.COPT, data.COPP);
  data.COPD = calcUtil.minus(data.COPT, data.COPP);
  data.COER = calcUtil.divide(data.COET, data.COEP);
  data.COED = calcUtil.minus(data.COET, data.COEP);
  data.CTPR = calcUtil.divide(data.CTPT, data.CTPP);
  data.CTPD = calcUtil.minus(data.CTPT, data.CTPP);
  data.CTSR = calcUtil.divide(data.CTST, data.CTSP);
  data.CTSD = calcUtil.minus(data.CTST, data.CTSP);
  data.CTER = calcUtil.divide(data.CTET, data.CTEP);
  data.CTED = calcUtil.minus(data.CTET, data.CTEP);
  data.KKR = calcUtil.divide(data.KKT, data.KJT);
  data.PBR = calcUtil.divide(data.PBT, data.STT);

  if(parent) {
    data.JJTK = calcUtil.divide(data.JJT, parent.JJT);
    data.JJPK = calcUtil.divide(data.JJP, parent.JJP);
    data.JKTK = calcUtil.divide(data.JKT, parent.JKT);
    data.JKPK = calcUtil.divide(data.JKP, parent.JKP);
  
    data.SLTK = calcUtil.divide(data.SLT, parent.SLT);
    data.SLPK = calcUtil.divide(data.SLP, parent.SLP);
    data.SDTK = calcUtil.divide(data.SDT, parent.SDT);
    data.SDPK = calcUtil.divide(data.SDP, parent.SDP);
    data.SRTK = calcUtil.divide(data.SRT, parent.SRT);
    data.SRPK = calcUtil.divide(data.SRP, parent.SRP);
    data.STTK = calcUtil.divide(data.STT, parent.STT);
    data.STPK = calcUtil.divide(data.STP, parent.STP);
    
    data.PBLTK = calcUtil.divide(data.PBLT, parent.PBLT);
    data.PBLPK = calcUtil.divide(data.PBLP, parent.PBLP);
    data.PBDTK = calcUtil.divide(data.PBDT, parent.PBDT);
    data.PBDPK = calcUtil.divide(data.PBDP, parent.PBDP);
    data.PBRTK = calcUtil.divide(data.PBRT, parent.PBRT);
    data.PBRPK = calcUtil.divide(data.PBRP, parent.PBRP);
    data.PBTTK = calcUtil.divide(data.PBTT, parent.PBTT);
    data.PBTPK = calcUtil.divide(data.PBTP, parent.PBTP);
    data.YHLTK = calcUtil.divide(data.YHLT, parent.YHLT);
    data.YHLPK = calcUtil.divide(data.YHLP, parent.YHLP);
    data.YHDTK = calcUtil.divide(data.YHDT, parent.YHDT);
    data.YHDPK = calcUtil.divide(data.YHDP, parent.YHDP);
    data.YHRTK = calcUtil.divide(data.YHRT, parent.YHRT);
    data.YHRPK = calcUtil.divide(data.YHRP, parent.YHRP);
    data.YHTTK = calcUtil.divide(data.YHTT, parent.YHTT);
    data.YHTPK = calcUtil.divide(data.YHTP, parent.YHTP);
    data.PTTK = calcUtil.divide(data.PTT, parent.PTT);
    data.PTPK = calcUtil.divide(data.PTP, parent.PTP);
    
    data.DDTK = calcUtil.divide(data.DDT, parent.DDT);
    data.DDPK = calcUtil.divide(data.DDP, parent.DDP);
    data.DBTK = calcUtil.divide(data.DBT, parent.DBT);
    data.DBPK = calcUtil.divide(data.DBP, parent.DBP);
    data.DQTK = calcUtil.divide(data.DQT, parent.DQT);
    data.DQPK = calcUtil.divide(data.DQP, parent.DQP);
    data.DFTK = calcUtil.divide(data.DFT, parent.DFT);
    data.DFPK = calcUtil.divide(data.DFP, parent.DFP);
    data.DTTK = calcUtil.divide(data.DTT, parent.DTT);
    data.DTPK = calcUtil.divide(data.DTP, parent.DTP);
    
    data.NTTK = calcUtil.divide(data.NTT, parent.NTT);
    data.NTPK = calcUtil.divide(data.NTP, parent.NTP);
    
    data.CITK = calcUtil.divide(data.CIT, parent.CIT);
    data.CIPK = calcUtil.divide(data.CIP, parent.CIP);
    data.CSTK = calcUtil.divide(data.CST, parent.CST);
    data.CSPK = calcUtil.divide(data.CSP, parent.CSP);
    data.CTTK = calcUtil.divide(data.CTT, parent.CTT);
    data.CTPK = calcUtil.divide(data.CTP, parent.CTP);
    
    data.OCTK = calcUtil.divide(data.OCT, parent.OCT);
    data.OCPK = calcUtil.divide(data.OCP, parent.OCP);
    
    data.TSTK = calcUtil.divide(data.TST, parent.TST);
    data.TSPK = calcUtil.divide(data.TSP, parent.TSP);
    
    data.CSTTK = calcUtil.divide(data.CSTT, parent.CSTT);
    data.CSTPK = calcUtil.divide(data.CSTP, parent.CSTP);
    
    data.OPTK = calcUtil.divide(data.OPT, parent.OPT);
    data.OPPK = calcUtil.divide(data.OPP, parent.OPP);
    
    data.TPTK = calcUtil.divide(data.TPT, parent.TPT);
    data.TPPK = calcUtil.divide(data.TPP, parent.TPP);
    
    data.PPTTK = calcUtil.divide(data.PPTT, parent.PPTT);
    data.PPTPK = calcUtil.divide(data.PPTP, parent.PPTP);
    
    data.OETK = calcUtil.divide(data.OET, parent.OET);
    data.OEPK = calcUtil.divide(data.OEP, parent.OEP);
    
    data.TETK = calcUtil.divide(data.TET, parent.TET);
    data.TEPK = calcUtil.divide(data.TEP, parent.TEP);
    
    data.ETTK = calcUtil.divide(data.ETT, parent.ETT);
    data.ETPK = calcUtil.divide(data.ETP, parent.ETP);
    
    data.KTTK = calcUtil.divide(data.KTT, parent.KTT);
    data.KTPK = calcUtil.divide(data.KTP, parent.KTP);
    
    data.BTTK = calcUtil.divide(data.BTT, parent.BTT);
    data.BTPK = calcUtil.divide(data.BTP, parent.BTP);
    
    data.ATTK = calcUtil.divide(data.ATT, parent.ATT);
    data.ATPK = calcUtil.divide(data.ATP, parent.ATP);
    
    data.GTTK = calcUtil.divide(data.GTT, parent.GTT);
    data.GTPK = calcUtil.divide(data.GTP, parent.GTP);
    
    data.ZTTK = calcUtil.divide(data.ZTT, parent.ZTT);
    data.ZTPK = calcUtil.divide(data.ZTP, parent.ZTP);
    data.ZCTK = calcUtil.divide(data.ZCT, parent.ZCT);
    data.ZCPK = calcUtil.divide(data.ZCP, parent.ZCP);
    
    data.CCDTK = calcUtil.divide(data.CCDT, parent.CCDT);
    data.CDDPK = calcUtil.divide(data.CDDP, parent.CDDP);
    data.CDBTK = calcUtil.divide(data.CDBT, parent.CDBT);
    data.CDBPK = calcUtil.divide(data.CDBP, parent.CDBP);
    data.CDFTK = calcUtil.divide(data.CDFT, parent.CDFT);
    data.CDFPK = calcUtil.divide(data.CDFP, parent.CDFP);
    data.COCTK = calcUtil.divide(data.COCT, parent.COCT);
    data.COCPK = calcUtil.divide(data.COCP, parent.COCP);
    data.COPTK = calcUtil.divide(data.COPT, parent.COPT);
    data.COPPK = calcUtil.divide(data.COPP, parent.COPP);
    data.COETK = calcUtil.divide(data.COET, parent.COET);
    data.COEPK = calcUtil.divide(data.COEP, parent.COEP);
    data.CTPTK = calcUtil.divide(data.CTPT, parent.CTPT);
    data.CTPPK = calcUtil.divide(data.CTPP, parent.CTPP);
    data.CTSTK = calcUtil.divide(data.CTST, parent.CTST);
    data.CTSPK = calcUtil.divide(data.CTSP, parent.CTSP);
    data.CTETK = calcUtil.divide(data.CTET, parent.CTET);
    data.CTEPK = calcUtil.divide(data.CTEP, parent.CTEP);
    
    data.STBK = calcUtil.divide(data.STB, parent.STB);
    data.PTBK = calcUtil.divide(data.PTB, parent.PTB);
    data.DTBK = calcUtil.divide(data.DTB, parent.DTB);
    data.NTBK = calcUtil.divide(data.NTB, parent.NTB);
    data.CTBK = calcUtil.divide(data.CTB, parent.CTB);
    data.GTBK = calcUtil.divide(data.GTB, parent.GTB);
    data.ZTBK = calcUtil.divide(data.ZTB, parent.ZTB);
  }


  //欠品構成比
  // 合計
  data.KITKA = calcUtil.sum([data.KI1KA, data.KI2KA, data.KI3KA, ]);
  //欠品責任別
  data.KI1KK = calcUtil.divide(data.KI1KA, data.KITKA);
  data.KI2KK = calcUtil.divide(data.KI2KA, data.KITKA);
  data.KI3KK = calcUtil.divide(data.KI3KA, data.KITKA);
  //欠品区分別
  data.K10KK = calcUtil.divide(data.K10KA, data.KITKA);
  data.K11KK = calcUtil.divide(data.K11KA, data.KITKA);
  data.K12KK = calcUtil.divide(data.K12KA, data.KITKA);
  data.K13KK = calcUtil.divide(data.K13KA, data.KITKA);
  data.K14KK = calcUtil.divide(data.K14KA, data.KITKA);
  data.K15KK = calcUtil.divide(data.K15KA, data.KITKA);
  data.K16KK = calcUtil.divide(data.K16KA, data.KITKA);
  data.K17KK = calcUtil.divide(data.K17KA, data.KITKA);
  data.K19KK = calcUtil.divide(data.K19KA, data.KITKA);
  data.K20KK = calcUtil.divide(data.K20KA, data.KITKA);
  data.K21KK = calcUtil.divide(data.K21KA, data.KITKA);
  data.K22KK = calcUtil.divide(data.K22KA, data.KITKA);
  data.K29KK = calcUtil.divide(data.K29KA, data.KITKA);
  data.K30KK = calcUtil.divide(data.K30KA, data.KITKA);
  data.K31KK = calcUtil.divide(data.K31KA, data.KITKA);
  data.K32KK = calcUtil.divide(data.K32KA, data.KITKA);
  data.K33KK = calcUtil.divide(data.K33KA, data.KITKA);
  data.K41KK = calcUtil.divide(data.K41KA, data.KITKA);
  data.K34KK = calcUtil.divide(data.K34KA, data.KITKA);
  data.K39KK = calcUtil.divide(data.K39KA, data.KITKA);
  data.K40KK = calcUtil.divide(data.K40KA, data.KITKA);
  data.K42KK = calcUtil.divide(data.K42KA, data.KITKA);
  data.K43KK = calcUtil.divide(data.K43KA, data.KITKA);
  data.K44KK = calcUtil.divide(data.K44KA, data.KITKA);
  data.K49KK = calcUtil.divide(data.K49KA, data.KITKA);
  data.K50KK = calcUtil.divide(data.K50KA, data.KITKA);

  return data;
}
export const calcDataPl = (data: RowData, lv: number, axisTypes: ("group1" | "group2" | "shiten" | "center" | "shiire" | "maker" | "tokuisaki" | "category" | string)[]): RowData => {
  const hasMaker = axisTypes.indexOf('maker') !== -1 || axisTypes.indexOf('shiire') !== -1 || axisTypes.indexOf('category') !== -1;
  if (hasMaker) {
    colKeysKeihi.forEach(colKey => data[colKey] = null);
  }
  return data;
}
export const calcDataKeppinRnk = (data: RowData): string[] => {
  const rnks = [
    "K10KK", "K11KK", "K12KK", "K14KK", "K15KK", "K16KK", "K17KK", "K19KK",
    "K20KK", "K21KK", "K22KK", "K29KK",
    "K30KK", "K31KK", "K34KK",
    "K40KK", "K41KK", "K42KK", "K43KK",
    "K50KK",
  ]
    .filter(colKey => data[colKey])
    .map(colKey => { return { colKey: colKey, value: data[colKey] } })
    .sort((a, b) => compareUtil.compareAny(a.value, b.value, false))
    .slice(0, 5) //ワースト上位5位
    .map(d => d.colKey);
  return rnks;
}

//no振りなおし
const resetRowNo = (datas: RowData[], parent: RowData, maxLv: number, grouping1: boolean, grouping2: boolean): RowData[] => {
  //set No.
  const TPs = []; //'lv1','lv2','lv3','lv4'
  if (grouping1) {
    TPs.push('grouping1');
  }
  if (grouping2) {
    TPs.push('grouping2');
  }
  for(let l = 1; l<=maxLv; l++){
    TPs.push('lv' + l);
  }
  let nos = [];
  TPs.forEach((TP, index) => {
    const indexOf = parent ? TPs.indexOf(parent.TP) : -1;
    const no = parent && indexOf >= index ? parseInt(parent.no.split("-")[index]) : 0;
    nos.push(no);
  });
  datas = datas.map((row) => {
    let no = row.TP == "total" ? "合計" : "";
    const indexOf = TPs.indexOf(row.TP);
    nos = nos.map((n, index) => {
      if (index < indexOf && n !== 0) {
        no = no + "" + n + "-";
      }
      else if(index == indexOf){
        no = no + "" + (++n);
      }
      else {
        n = 0;
      }
      return n;
    });

    return {
      ...row,
      no: no,
    }
  });
  return datas;
}

//ドリルダウンの差し込み
const spliceData = (addIndex: number, addDatas:RowData[], destDatas:RowData[]): RowData[] => {
  const args = [].concat([addIndex,0]).concat(addDatas);
  Array.prototype.splice.apply(destDatas,args);
  return destDatas;
}


//配列データに変換
const convertRows = (datas:RowData[], colKeys:string[]): [][] => {
  const rows = [];
  //set No.
  datas.forEach((data) => {
    const r = [];
    colKeys.forEach((colKey) => {
      r.push(data[colKey]);
    });
    rows.push(r);
  });

  return rows;
}

//ソート
export const doSort = (datas: RowData[], colKey: string, asc: boolean, lvKeySort: boolean, equalsIsCode: boolean): RowData[] => {
  datas.sort((a, b) => {
    //合計行は常に上
    if (a.TP == "total" || b.TP == "total") {
      return a.TP == "total" ? -1 : 1;
    }

    let objA = a;
    let objB = b;
    if (lvKeySort) {
      const lvA = drillDownLv1MaxLv.indexOf(objA.TP);
      let comp = 0;
      if (lvA >= 0) {
        for (let i = 1; i <= drillDownMaxLv; i++) {
          comp = compareUtil.compareString(objA ? objA['H' + i + 'ID'] : null, objB ? objB['H' + i + 'ID'] : null, asc);
          if (!(comp == 0 && lvA >= i)) {
            break;
          }
        }
      }
      return comp;
    }

    //小計行は常に上
    if (a.CDIV == b.CDIV && (a.TP == "grouping1" || b.TP == "grouping1")) {
      return a.TP == "grouping1" ? -1 : 1;
    }
    if (a.TDIV == b.TDIV && (a.TP == "grouping2" || b.TP == "grouping2")) {
      return a.TP == "grouping2" ? -1 : 1;
    }

    //複数階層（親でソート）
    const lvA = ['grouping1', 'grouping2', ...drillDownLv1MaxLv].indexOf(a.TP) - 1;
    const lvB = ['grouping1', 'grouping2', ...drillDownLv1MaxLv].indexOf(b.TP) - 1;
    const minLv = Math.min(lvA, lvB);
    const findfunc = (data, lv, a): boolean => {
      if (lv === -1 ? (data.TP !== 'grouping1') : lv === 0 ? (data.TP !== 'grouping2') : (data.TP != 'lv' + lv)) {
        return false;
      }
      for (let i = -1; i <= lv; i++) {
        if (lv === -1 ? (data.CDIV != a.CDIV) : lv === 0 ? (data.TDIV != a.TDIV) : (data['H' + i + 'ID'] != a['H' + i + 'ID'])) {
          return false;
        }
      }
      return true;
    };

    //階層ごとに比較する
    for (let j = -1; j <= minLv; j++) {
      objA = datas.find(data => findfunc(data, j, a));
      objB = datas.find(data => findfunc(data, j, b));

      let comp = 0;
      const key = j === -1 ? 'CDIV' : j === 0 ? 'TDIV' : colKey;
      const va = objA ? objA[key] : null;
      const vb = objB ? objB[key] : null;

      if (key === 'CDIV') {
        const ia = ['TC', 'DC', '特殊', '-'].indexOf(va);
        const ib = ['TC', 'DC', '特殊', '-'].indexOf(vb);
        comp = compareUtil.compareNumber(ia, ib, colKey === 'CDIV' ? asc : true);
      } else if (key === 'TDIV') {
        const ia = ['自社', '委託F', '委託H', '大創', '-'].indexOf(va);
        const ib = ['自社', '委託F', '委託H', '大創', '-'].indexOf(vb);
        comp = compareUtil.compareNumber(ia, ib, colKey === 'TDIV' ? asc : true);
      }
      //数値型
      else if (typeof va === 'number' || typeof vb === 'number') {
        comp = compareUtil.compareNumber2(va, vb, asc);
      }
      else if (typeof va === 'string' || typeof vb === 'string') {
        comp = compareUtil.compareString(va, vb, asc);
      }
      if (comp != 0) {
        return comp;
      }
      //次の階層で比較
    }

    let comp = 0;
    //それでもソートできない場合はコード順
    if (equalsIsCode) {
      const lvA = drillDownLv1MaxLv.indexOf(objA.TP);
      if (lvA >= 0) {
        for (let i = 1; i <= drillDownMaxLv; i++) {
          comp = compareUtil.compareString(objA ? objA['H' + i + 'ID'] : null, objB ? objB['H' + i + 'ID'] : null, asc);
          if (!(comp == 0 && lvA >= i)) {
            break;
          }
        }
      }
    }
    return comp;
  });

  return datas;
}

//データの位置取得
export const dataIndexOfLv1 = (datas:RowData[], CAT:string, H1:string):number => {
  //差し込み位置の取得
  let parentIndex;
  datas.find((data, index) => {
    const match = data.TP == "lv1"
      && CAT == data.CAT
      && H1 == data.H1ID
    ;
    if(match) {
      parentIndex = index;
    }
    return match;
  });
  return parentIndex;
}
export const dataIndexOfLv2 = (datas:RowData[], CAT:string, H1:string, H2:string):number => {
  //差し込み位置の取得
  let parentIndex;
  datas.find((data, index) => {
    const match = data.TP == "lv2"
      && CAT == data.CAT
      && H1 == data.H1ID
      && H2 == data.H2ID
    ;
    if(match) {
      parentIndex = index;
    }
    return match;
  });
  return parentIndex;
}
export const dataIndexOfLv3 = (datas:RowData[], CAT:string, H1:string, H2:string, H3:string):number => {
  //差し込み位置の取得
  let parentIndex;
  datas.find((data, index) => {
    const match = data.TP == "lv3"
      && CAT == data.CAT
      && H1 == data.H1ID
      && H2 == data.H2ID
      && H3 == data.H3ID
    ;
    if(match) {
      parentIndex = index;
    }
    return match;
  });
  return parentIndex;
}
export const dataIndexOfLv4 = (datas:RowData[], CAT:string, H1:string, H2:string, H3:string, H4:string):number => {
  //差し込み位置の取得
  let parentIndex;
  datas.find((data, index) => {
    const match = data.TP == "lv4"
      && CAT == data.CAT
      && H1 == data.H1ID
      && H2 == data.H2ID
      && H3 == data.H3ID
      && H4 == data.H4ID
    ;
    if(match) {
      parentIndex = index;
    }
    return match;
  });
  return parentIndex;
}
export const dataIndexOfLv5 = (datas:RowData[], CAT:string, H1:string, H2:string, H3:string, H4:string, H5:string):number => {
  //差し込み位置の取得
  let parentIndex;
  datas.find((data, index) => {
    const match = data.TP == "lv5"
      && CAT == data.CAT
      && H1 == data.H1ID
      && H2 == data.H2ID
      && H3 == data.H3ID
      && H4 == data.H4ID
      && H5 == data.H5ID
    ;
    if(match) {
      parentIndex = index;
    }
    return match;
  });
  return parentIndex;
}
export const dataIndexOfLv6 = (datas:RowData[], CAT:string, H1:string, H2:string, H3:string, H4:string, H5:string, H6:string):number => {
  //差し込み位置の取得
  let parentIndex;
  datas.find((data, index) => {
    const match = data.TP == "lv6"
      && CAT == data.CAT
      && H1 == data.H1ID
      && H2 == data.H2ID
      && H3 == data.H3ID
      && H4 == data.H4ID
      && H5 == data.H5ID
      && H6 == data.H6ID
    ;
    if(match) {
      parentIndex = index;
    }
    return match;
  });
  return parentIndex;
}
export const dataIndexOfLv7 = (datas:RowData[], CAT:string, H1:string, H2:string, H3:string, H4:string, H5:string, H6:string, H7:string):number => {
  //差し込み位置の取得
  let parentIndex;
  datas.find((data, index) => {
    const match = data.TP == "lv7"
      && CAT == data.CAT
      && H1 == data.H1ID
      && H2 == data.H2ID
      && H3 == data.H3ID
      && H4 == data.H4ID
      && H5 == data.H5ID
      && H6 == data.H6ID
      && H7 == data.H7ID
    ;
    if(match) {
      parentIndex = index;
    }
    return match;
  });
  return parentIndex;
}

export const getOptionLabel = (option: CodeName | string) => {
  if(typeof option == "string") {
    return option;
  }
  return option && option.name ? (option.code + ' ' + option.name) : "";
}


//Page Slice Export
//salesListTmp
export const salesListTmpSlice = createSliceContent("salesListTmp");
export const salesListReport10TmpSlice = createSliceContent("salesListReport10Tmp");
export const salesListReport20TmpSlice = createSliceContent("salesListReport20Tmp");
export const salesListReport21TmpSlice = createSliceContent("salesListReport21Tmp");
export const salesListReport30TmpSlice = createSliceContent("salesListReport30Tmp");
export const salesListReport40TmpSlice = createSliceContent("salesListReport40Tmp");
