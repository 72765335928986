import moment from 'moment';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/store/common";

import { KaihaiImportExcelGroup } from '@/store/kaihai/kaihaiCommon'
import * as kaihaiExcelImportPreview from "@/assets/apitype/kaihaiExcelImportPreview";
import { KaihaiExcelImportColRowModel, rowKeys, headersRow, colDataType, rowDataType, mergeColKeys } from "@/components/kaihai/KaihaiExcelImportTableModel";

import * as editorUtil from "@/util/editorUtil";

//0.00フォーマット
const formatterP00 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
//0,0フォーマット
const formatterN0 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 });

export type RowDataGroup = {
  kaihaiGroup: KaihaiImportExcelGroup,
  maxkigenDatasSize?: number,

}

export type RowInfo = {
  TP: "item" | "group",
  dataIndex: number,
  no?: string,
  subno?: string,
  dataGroup:RowDataGroup,
  rowKey?: string,

  rowIndex?:number,  //同一集計行内でのrowKeyのindex
  rowIndexLast?:boolean, //同一集計行内での最後のrowKeyフラグ
}

//Page State
export type KaihaiImportExcelTmpState = {
  progress: Record<string, unknown>,
  retrieveParam: kaihaiExcelImportPreview.RequestParam,  //検索条件(検索済み)
  retrievedParam: kaihaiExcelImportPreview.RequestParam,  //検索条件(検索済み)

  dataGroups: RowDataGroup[],
  rowInfos: RowInfo[],
  fixedRowsTop :number,
  rows: any[][],
  mergeCells: {row: number, col: number, rowspan: number, colspan: number}[],

  errorMessage: string | null,
};

export const initialState: KaihaiImportExcelTmpState = {
  progress: {},
  retrieveParam: null,
  retrievedParam: null,

  dataGroups: [],
  rowInfos: [],
  fixedRowsTop :0,
  rows: [],
  mergeCells: null,

  errorMessage: null,
};

//Page Slice
export type KaihaiImportExcelTmpReducer = {
  putProgress: (state:KaihaiImportExcelTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state:KaihaiImportExcelTmpState, action: PayloadAction<string>) => void,
  setErrorMessage: (state:KaihaiImportExcelTmpState, action: PayloadAction<string>) => void,
  setRetrieveParam: (state:KaihaiImportExcelTmpState, action: PayloadAction<kaihaiExcelImportPreview.RequestParam>) => void,
  setRetrievedParam: (state:KaihaiImportExcelTmpState, action: PayloadAction<kaihaiExcelImportPreview.RequestParam>) => void,

  searched: (state:KaihaiImportExcelTmpState, action: PayloadAction<{param: kaihaiExcelImportPreview.RequestParam, kaihaiGroups: KaihaiImportExcelGroup[], colRowModel:KaihaiExcelImportColRowModel, }>) => void,
  refreshTableWithFilter: (state:KaihaiImportExcelTmpState, action: PayloadAction<{colRowModel:KaihaiExcelImportColRowModel, }>) => void,
  refreshTable: (state:KaihaiImportExcelTmpState, action: PayloadAction<{colRowModel:KaihaiExcelImportColRowModel,}>) => void,
  editRowDatas: (state: KaihaiImportExcelTmpState, action: PayloadAction<{
    editDatas: {
      row: number, col: number, value: string | number | object | null
        , relatedValues?: { key: string, value: string | number | object | null }[]
        , uuid?: string
    }[],
    colRowModel:KaihaiExcelImportColRowModel,
  }>) => void,
}

const createReducerContent = ():KaihaiImportExcelTmpReducer => {return {
    putProgress(state:KaihaiImportExcelTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {...state.progress};
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state:KaihaiImportExcelTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if(key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    setErrorMessage(state:KaihaiImportExcelTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setRetrieveParam(state:KaihaiImportExcelTmpState, action: PayloadAction<kaihaiExcelImportPreview.RequestParam>) {
      state.retrieveParam = action.payload;
    },
    setRetrievedParam(state:KaihaiImportExcelTmpState, action: PayloadAction<kaihaiExcelImportPreview.RequestParam>) {
      state.retrievedParam = action.payload;
    },

    searched(state:KaihaiImportExcelTmpState, action: PayloadAction<{param: kaihaiExcelImportPreview.RequestParam, kaihaiGroups: KaihaiImportExcelGroup[], colRowModel:KaihaiExcelImportColRowModel, 
    }>) {
      const colRowModel = action.payload.colRowModel;
      const param = action.payload.param;

      let kaihaiGroups = parseDataKaihaiImportExcelGroup(action.payload.kaihaiGroups);

      let dataGroups = convertDataGroups(kaihaiGroups);
      dataGroups = calcDataGroups(dataGroups);

      // dataGroups = sortDataGroups(dataGroups);
      const [rowInfos, fixedRowsTop] = convertRowInfos(dataGroups);
      
      //store更新
      state.dataGroups = dataGroups;
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.mergeCells = createMergeCells(rowInfos, colRowModel);
      state.rows = convertRows(rowInfos, colRowModel);
      console.log(convertRows(rowInfos, colRowModel));
    },
    refreshTableWithFilter(state:KaihaiImportExcelTmpState, action: PayloadAction<{colRowModel:KaihaiExcelImportColRowModel, 
    }>){
      console.log('refreshTableWithFilter');
      if(!state.dataGroups || state.dataGroups.length == 0) {
        return;
      }
      const colRowModel = action.payload.colRowModel;

      let dataGroups = state.dataGroups;
      // dataGroups = sortDataGroups(dataGroups);
      const [rowInfos, fixedRowsTop] = convertRowInfos(dataGroups);
      //store更新
      state.dataGroups = dataGroups;
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.mergeCells = createMergeCells(rowInfos, colRowModel);
      state.rows = convertRows(rowInfos, colRowModel);
    },
    refreshTable(state:KaihaiImportExcelTmpState, action: PayloadAction<{colRowModel:KaihaiExcelImportColRowModel, 
      }>){
      console.log('refreshTable');
      if(!state.dataGroups || state.dataGroups.length == 0) {
        return;
      }
      const colRowModel = action.payload.colRowModel;

      let dataGroups = state.dataGroups;
      // dataGroups = sortDataGroups(dataGroups);
      const [rowInfos, fixedRowsTop] = convertRowInfos(dataGroups);
      //store更新
      state.rowInfos = rowInfos;
      state.fixedRowsTop = fixedRowsTop;
      state.mergeCells = createMergeCells(rowInfos, colRowModel);
      state.rows = convertRows(rowInfos, colRowModel);
    },
    editRowDatas(state: KaihaiImportExcelTmpState, action: PayloadAction<{
      editDatas: {
        row: number, col: number, value: string | number | object | null
          , relatedValues?: { key: string, value: string | number | object | null }[]
          , uuid?: string
      }[],
      colRowModel:KaihaiExcelImportColRowModel,
    }>) {
      console.log('store.editRowDatas');
      const editDatas = action.payload.editDatas;
      const colRowModel = action.payload.colRowModel;
      let newDatas = [...state.dataGroups];
      // const newRows = [...state.rows];

      const editDataContent = (rowInfo: RowInfo, row: number, col: number, key: string, value: string | number | boolean | object | null) => {
        if(!(row >= 0) || (!key && !(col >= 0))) {
          return;
        }
        if(!key) {
          key = colRowModel.keyFromCol(col);
        }
        if(!(col >= 0)) {
          col = colRowModel.colFromKey(key);
        }

        //parse
        const dataType = colDataType[key];
        value = editorUtil.parseValue(value, dataType.type,
          dataType.type == 'numeric' ? dataType.numericFormat.pattern :
          dataType.type == 'date' ? dataType.dateFormat :
          null);

        let data = newDatas[rowInfo.dataIndex];
        switch (key) {
          case "targetFlg":
            data.kaihaiGroup.targetFlg = typeof value == 'boolean' ? value : false;
            break;
          case "beforeItemFlg":
            data.kaihaiGroup.items[rowInfo.rowIndex].beforeItemFlg = typeof value == 'boolean' ? value : false;
            break;
          case "afterItemFlg":
            data.kaihaiGroup.items[rowInfo.rowIndex].afterItemFlg = typeof value == 'boolean' ? value : false;
            break;
          default:
            break;
        }
        // newRows[row][col] = value;
      };

      editDatas.forEach((editData) => {
        const key = colRowModel.keyFromCol(editData.col);
        const rowInfo = state.rowInfos[editData.row];
        editDataContent(rowInfo, editData.row, editData.col, key, editData.value);
        //関連データの更新
        if(editData.relatedValues) {
          editData.relatedValues.forEach(relatedValue => {
            editDataContent(rowInfo, editData.row, null, relatedValue.key, relatedValue.value);
          })
        }
        // //uuid
        // if (editData.uuid) {
        //   let data = newDatas[rowInfo.dataIndex];
        //   data.uuid = editData.uuid;
        // }
      });
      // newDatas = checkDatas(newDatas, state.editingType);
      // newDatas = doSort(newDatas, sorts, state.adms);

      //store更新
      const [rowInfos, fixedRowsTop] = convertRowInfos(newDatas);
      state.dataGroups = newDatas;
      state.rowInfos = rowInfos;
      // state.fixedRowsTop = fixedRowsTop;
      state.mergeCells = createMergeCells(rowInfos, colRowModel);
      state.rows = convertRows(rowInfos, colRowModel);
    },
}};

const parseDataKaihaiImportExcelGroup = (datas:KaihaiImportExcelGroup[]): KaihaiImportExcelGroup[] => {
  datas = [...datas];
  datas.forEach((data) => {
    // if(typeof data.termStartDate === 'string') data.blIrisu = parseInt(data.blIrisu);
    // if(typeof data.termEndDate === 'string') data.blIrisu = parseInt(data.blIrisu);

    // if(typeof data.blIrisu === 'string') data.blIrisu = parseInt(data.blIrisu);
    // if(typeof data.csIrisu === 'string') data.csIrisu = parseInt(data.csIrisu);
    // if(typeof data.bestBefore === 'string') data.bestBefore = parseInt(data.bestBefore);
    // if(typeof data.teika === 'string') data.teika = parseInt(data.teika);
    // if(typeof data.tatene === 'string') data.tatene = parseFloat(data.tatene);

    // if(typeof data.zaikoQty1 === 'string') data.zaikoQty1 = parseFloat(data.zaikoQty1);
    // if(typeof data.zaikoQty2 === 'string') data.zaikoQty2 = parseFloat(data.zaikoQty2);
    // if(typeof data.zaikoQty3 === 'string') data.zaikoQty3 = parseFloat(data.zaikoQty3);
    // if(typeof data.zaikoQty4 === 'string') data.zaikoQty4 = parseFloat(data.zaikoQty4);
    // if(typeof data.zaikoQty5 === 'string') data.zaikoQty5 = parseFloat(data.zaikoQty5);
  });
  return datas;
}
//RowDataGroupに変換
const convertDataGroups = (kaihaiGroups: KaihaiImportExcelGroup[]): RowDataGroup[] => {
  // //商品、センター順でソート
  // kaihaiGroups = kaihaiGroups.sort((a,b) => {
  //   let comp = 0;
  //   comp = compareUtil.compareString(a.itemCD, b.itemCD, true)
  //   if(comp == 0) {
  //     comp = compareUtil.compareString(a.centerCD, b.centerCD, true)
  //   }
  //   return comp;
  // });

  // //返品だけは在庫から取得する
  // kaihaiGroups.filter(kaihaiGroup => kaihaiGroup.zaikoQty5).forEach(kaihaiGroup => {
  //   const key = `${kaihaiGroup.itemCD} ${kaihaiGroup.centerCD}`;
  // });

  return kaihaiGroups.map(kaihaiGroup => {
    return {
      kaihaiGroup: kaihaiGroup,
      maxkigenDatasSize: 1,
    };
  });
}
//RowDataGroupの計算
const calcDataGroups = (dataGroups:RowDataGroup[]): RowDataGroup[] => {
  /*
  //賞味期限別を合計する
  const sumKigenDatas = (kigenDatas:KigenData[]): KigenData => {
    const kigenDataTotal:KigenData = {
      zaikoQty: 0,
    };
    if(kigenDatas && kigenDatas.length > 0) {
      kigenDatas.forEach(kigenData => {
        kigenDataTotal.zaikoQty = calcUtil.plus(kigenDataTotal.zaikoQty, kigenData.zaikoQty);
      });
    }
    return kigenDataTotal;
  }
  ;

  return dataGroups.map(dataGroup => {
    dataGroup.kigenDataTotal1 = sumKigenDatas(dataGroup.kigenDatas1);
    dataGroup.kigenDataTotal2 = sumKigenDatas(dataGroup.kigenDatas2);
    dataGroup.kigenDataTotal3 = sumKigenDatas(dataGroup.kigenDatas3);
    dataGroup.kigenDataTotal4 = sumKigenDatas(dataGroup.kigenDatas4);
    dataGroup.kigenDataTotal5 = sumKigenDatas(dataGroup.kigenDatas5);
    return dataGroup;
  });
  */
  //在庫の合計を利用する
  return dataGroups.map(dataGroup => {
    return dataGroup;
  });
}

//行情報に変換
const convertRowInfos = (dataGroups:RowDataGroup[]): [RowInfo[], number] => {
  const targetRowsKeys = rowKeys;

  const rowInfos:RowInfo[] = [];
  let fixedRowsTop:number = 0;

  //明細行
  fixedRowsTop = rowInfos.length;

  let beforeDataGroup:RowDataGroup;
  let no = 0;
  let subno = 0;
  dataGroups.forEach((dataGroup, dataGroupIndex) => {
    if(rowInfos.length > 0) {
      rowInfos[rowInfos.length-1].rowIndexLast = true; //同一集計行内での最後のrowKeyフラグ
    }

    // グルーピング
    {
      no++;
      subno = 0;
      rowInfos.push({
        TP: "group",
        no: `${no}`,
        dataGroup: dataGroup,
        dataIndex: dataGroupIndex,
      });
    }

    //明細行
    let rowIndex = 0;
    dataGroup.kaihaiGroup.items?.forEach(item => {
      subno++;
      rowInfos.push({
        ...{
          TP: "item",
          no: `${no}`,
          subno: `${subno}`,
          dataGroup: dataGroup,
          dataIndex: dataGroupIndex,
        }, 
        ...{
          rowKey: `${subno}`,
  
          rowIndex: rowIndex++,  //同一集計行内でのrowKeyのindex
          rowIndexLast: false, //同一集計行内での最後のrowKeyフラグ（仮置き）
        }
      });
    });


    beforeDataGroup = dataGroup;
  });
  if(rowInfos.length > 0) {
    rowInfos[rowInfos.length-1].rowIndexLast = true; //同一集計行内での最後のrowKeyフラグ
  }

  return [rowInfos, fixedRowsTop];
}
//配列データに変換
const convertRows = (rowInfos:RowInfo[], colRowModel:KaihaiExcelImportColRowModel): any[][] => {
  return rowInfos.map((rowInfo) => convertRow(rowInfo, colRowModel));
}
//配列データに変換
const convertRow = (rowInfo:RowInfo, colRowModel:KaihaiExcelImportColRowModel): any[] => {
  //set No.
  const dataGroup:RowDataGroup = rowInfo.dataGroup;
  return colRowModel.colKeys.map(colKey => {

    //グループ行
    if(rowInfo.TP == "group") {
      switch (colKey) {
        case "no":
          return rowInfo.no;

        case "targetFlg":
          return dataGroup.kaihaiGroup?.targetFlg;
        case "checkMessage":
          return dataGroup.kaihaiGroup?.checkMessage;
  
        case "fileName":
          return dataGroup.kaihaiGroup?.filename;
        case "sheetName":
          return dataGroup.kaihaiGroup?.sheetname;
        case "rowNo":
          return dataGroup.kaihaiGroup?.rowNo;
        case "beforeSyubaiDate" : 
          return dataGroup.kaihaiGroup?.beforeSyubaiDate ? moment(dataGroup.kaihaiGroup?.beforeSyubaiDate).format('YY/MM/DD') : null;
        case "beforeJan" : 
          return dataGroup.kaihaiGroup?.beforeJan;
        case "afterHatsubaiDate" : 
          return dataGroup.kaihaiGroup?.afterHatsubaiDate ? moment(dataGroup.kaihaiGroup?.afterHatsubaiDate).format('YY/MM/DD') : null;
        case "afterJan" : 
          return dataGroup.kaihaiGroup?.afterJan;
        case "rnk" : 
          // return dataGroup.kaihaiGroup?.rnk;
          return null;
        case "beforeItemFlg" : 
          return null;
        case "beforeItemCd" : 
          return null;
        case "beforeMakerName" : 
          return dataGroup.kaihaiGroup?.beforeMakerName;
        case "beforeItemName" : 
          return dataGroup.kaihaiGroup?.beforeItemName;
        case "beforeKikaku" : 
          return dataGroup.kaihaiGroup?.beforeKikaku;
        case "beforeIrisu" : 
          return dataGroup.kaihaiGroup?.beforeIrisu;
        case "beforeJodai" : 
          return dataGroup.kaihaiGroup?.beforeJodai;
        case "beforeSyubaiFlg" : 
          return dataGroup.kaihaiGroup?.beforeSyubaiFlg ? '〇' : null;
        case "beforeJyodaiFlg" : 
          return dataGroup.kaihaiGroup?.beforeJyodaiFlg ? '〇' : null;
        case "beforeKikakuFlg" : 
          return dataGroup.kaihaiGroup?.beforeKikakuFlg ? '〇' : null;
        case "beforeNyusatsuFlg" : 
          return dataGroup.kaihaiGroup?.beforeNyusatsuFlg ? '〇' : null;
        case "beforeBikou" : 
          return dataGroup.kaihaiGroup?.beforeBikou;
        case "afterItemFlg" : 
          return null;
        case "afterItemCd" : 
          return null;
        case "afterMakerName" : 
          return dataGroup.kaihaiGroup?.afterMakerName;
        case "afterItemName" : 
          return dataGroup.kaihaiGroup?.afterItemName;
        case "afterKikaku" : 
          return dataGroup.kaihaiGroup?.afterKikaku;
        case "afterIrisu" : 
          return dataGroup.kaihaiGroup?.afterIrisu;
        case "afterJodai" : 
          return dataGroup.kaihaiGroup?.afterJodai;
        case "afterJanhenFlg" : 
          return dataGroup.kaihaiGroup?.afterJanhenFlg ? '〇' : null;
        case "afterBikou" : 
          return dataGroup.kaihaiGroup?.afterBikou;
        case "createDate" : 
          return dataGroup.kaihaiGroup?.createDate ? moment(dataGroup.kaihaiGroup?.createDate).format('YY/MM/DD HH:mm') : null;
        case "createUserid" : 
          return dataGroup.kaihaiGroup?.createUserid;
        case "createUsername" : 
          return dataGroup.kaihaiGroup?.createUsername;
        case "updateDate" : 
          return dataGroup.kaihaiGroup?.updateDate ? moment(dataGroup.kaihaiGroup?.updateDate).format('YY/MM/DD HH:mm') : null;
        case "updateUserid" : 
          return dataGroup.kaihaiGroup?.updateUserid;
        case "updateUsername" : 
          return dataGroup.kaihaiGroup?.updateUsername;
       default: {
          return null;
        }
      }
    }
    else {
      //明細行
      switch (colKey) {
        case "no":
          return `${rowInfo.no}-${rowInfo.subno}`;
        // case "beforeSyubaiDate" : 
        //   return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeSyubaiDate;
        // case "beforeJan" : 
        //   return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeJan;
        // case "afterHatsubaiDate" : 
        //   return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterHatsubaiDate;
        // case "afterJan" : 
        //   return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterJan;
        case "rnk" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.rnk;
        case "beforeItemFlg" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeItemFlg;
        case "beforeItemCd" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeItemCd;
        case "beforeMakerName" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeMakerName;
        case "beforeItemName" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeItemName;
        case "beforeKikaku" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeKikaku;
        case "beforeIrisu" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeIrisu;
        case "beforeJodai" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeJodai;
        case "beforeSyubaiFlg" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeSyubaiFlg;
          return null;
        case "beforeJyodaiFlg" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeJyodaiFlg;
          return null;
        case "beforeKikakuFlg" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeKikakuFlg;
          return null;
        case "beforeNyusatsuFlg" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeNyusatsuFlg;
          return null;
        case "beforeBikou" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.beforeBikou;
          return null;
        case "afterItemFlg" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterItemFlg;
        case "afterItemCd" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterItemCd;
        case "afterMakerName" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterMakerName;
        case "afterItemName" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterItemName;
        case "afterKikaku" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterKikaku;
        case "afterIrisu" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterIrisu;
        case "afterJodai" : 
          return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterJodai;
        case "afterJanhenFlg" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterJanhenFlg;
          return null;
        case "afterBikou" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.afterBikou;
          return null;
        case "createDate" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.createDate;
          return null;
        case "createUserid" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.createUserid;
          return null;
        case "createUsername" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.createUsername;
          return null;
        case "updateDate" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.updateDate;
          return null;
        case "updateUserid" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.updateUserid;
          return null;
        case "updateUsername" : 
          // return dataGroup.kaihaiGroup?.items?.[rowInfo.rowIndex]?.updateUsername;
          return null;
        default: {
          return dataGroup[colKey];
        }

      }

    }
  });
}

//マージを作成
const createMergeCells = (rowInfos:RowInfo[], colRowModel:KaihaiExcelImportColRowModel): {row: number, col: number, rowspan: number, colspan: number}[] => {
  let mergeCells:{row: number, col: number, rowspan: number, colspan: number}[] = [];
  mergeColKeys.forEach(colKey => {
    let col = colRowModel.colFromKey(colKey);
    rowInfos.map((rowInfo, index) => {
      if(rowInfo?.TP == 'group' && rowInfo.dataGroup.kaihaiGroup?.items?.length) {
        mergeCells.push({row: index, col: col, rowspan: rowInfo.dataGroup.kaihaiGroup?.items?.length + 1, colspan:1});
      }
    });
  });
  return mergeCells.length == 0 ? null : mergeCells;
}


export const getOptionLabel = (option: CodeName | string) => {
  if(typeof option == "string") {
    return option;
  }
  return option && option.name ? (option.code + ' ' + option.name) : "";
}

const createSliceContent = (name:string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

//Page Slice Export
//KaihaiImportExcelTmp
export const kaihaiImportExcelTmpSlice = createSliceContent("kaihaiImportExcelTmp");
export const kaihaiImportHandyExcelTmpSlice = createSliceContent("kaihaiImportHandyExcelTmp");
