import moment from 'moment';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/store/common";

// import { KaihaiImportHandyGroup } from '@/store/kaihai/kaihaiCommon'
// import * as kaihaiHandyImportPreview from "@/assets/apitype/kaihaiHandyImportPreview";
import { KaihaiHandyImportColRowModel, colKeys, colDataType } from "@/components/kaihai/KaihaiHandyImportTableModel";

import * as editorUtil from "@/util/editorUtil";

//0.00フォーマット
const formatterP00 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 });
//0,0フォーマット
const formatterN0 = new Intl.NumberFormat('ja-JP', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 });

// export type RowDataGroup = {
//   // kaihaiGroup: KaihaiImportHandyGroup,
//   maxkigenDatasSize?: number,

// }

export interface RowData {
  beforeSyubaiDate?: string | null,
  beforeJan?: string | null,
  beforeMakerName?: string | null,
  beforeItemName?: string | null,
  beforeKikaku?: string | null,
  beforeIrisu?: string | null,
  beforeJodai?: number | null,
  beforeSyubaiFlg?: boolean | null,
  beforeJyodaiFlg?: boolean | null,
  beforeKikakuFlg?: boolean | null,
  beforeNyusatsuFlg?: boolean | null,
  beforeBikou?: string | null,

  afterHatsubaiDate?: string | null,
  afterJan?: string | null,
  afterMakerName?: string | null,
  afterItemName?: string | null,
  afterKikaku?: string | null,
  afterIrisu?: string | null,
  afterJodai?: number | null,
  afterJanhenFlg?: boolean | null,
  afterBikou?: string | null,


  // seq?: number,
  // uuid?: string,

  // itemInfoRetrieve?: boolean,
  // itemInfoRetrievedId?: string,
  // itemInfos?: ItemInfo[],
  // itemInfoIndex?: number,
  // //以下に貼り付け
  // IJN?: string | null, IJC?: number | null, ICD?: string | null, ISC?: string | null, ISN?: string | null, IMC?: string | null, IMN?: string | null, INM?: string | null, ICC?: string | null, ICN?: string | null, IBC?: string | null, IBN?: string | null, ICP?: string | null, IC1?: number | null, IC2?: number | null, ILI?: number | null, IPR?: number | null, ISD?: string | null, IED?: string | null, ITN?: number | null, 

  // //明細
  // details? :RowDataDetail[],
  // // SCD?: string | null, SNM?: string | null,
  // // HPL?: number | null,
  // // OFS?: string | null, OOH?: number | null, OON?: number | null, OOZ?: number | null, OFN?: number | null, OAN?: number | null, OMM?: string | null,

  // //エラー情報
  // errorInfo?:ErrorInfoData,
  // //表示状態
  // visible?: boolean,

  // //編集
  // edited?:boolean,

  // storeOrderData?: StoreOrderData | null, //先付受注の一時置き場
}

export type RowInfo = {
  TP: "item" | "group",
  dataIndex: number,
  no?: string,
  subno?: string,
  // dataGroup: RowDataGroup,
  rowKey?: string,

  rowIndex?: number,  //同一集計行内でのrowKeyのindex
  rowIndexLast?: boolean, //同一集計行内での最後のrowKeyフラグ
}

//Page State
export type KaihaiImportHandyTmpState = {
  progress: Record<string, unknown>,
  // retrieveParam: kaihaiHandyImportPreview.RequestParam,  //検索条件(検索済み)
  // retrievedParam: kaihaiHandyImportPreview.RequestParam,  //検索条件(検索済み)

  // dataGroups: RowDataGroup[],
  datas: RowData[],
  // rowInfos: RowInfo[],
  fixedRowsTop: number,
  rows: any[][],
  mergeCells: { row: number, col: number, rowspan: number, colspan: number }[],

  errorMessage: string | null,
};

export const initialState: KaihaiImportHandyTmpState = {
  progress: {},
  // retrieveParam: null,
  // retrievedParam: null,

  // dataGroups: [],
  datas: [],
  // rowInfos: [],
  fixedRowsTop: 0,
  rows: [],
  mergeCells: null,

  errorMessage: null,
};

//Page Slice
export type KaihaiImportHandyTmpReducer = {
  putProgress: (state: KaihaiImportHandyTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state: KaihaiImportHandyTmpState, action: PayloadAction<string>) => void,
  setErrorMessage: (state: KaihaiImportHandyTmpState, action: PayloadAction<string>) => void,
  // setRetrieveParam: (state: KaihaiImportHandyTmpState, action: PayloadAction<kaihaiHandyImportPreview.RequestParam>) => void,
  // setRetrievedParam: (state: KaihaiImportHandyTmpState, action: PayloadAction<kaihaiHandyImportPreview.RequestParam>) => void,

  // searched: (state: KaihaiImportHandyTmpState, action: PayloadAction<{ param: kaihaiHandyImportPreview.RequestParam, kaihaiGroups: KaihaiImportHandyGroup[], colRowModel: KaihaiHandyImportColRowModel, }>) => void,
  refreshTableWithFilter: (state: KaihaiImportHandyTmpState, action: PayloadAction<{ colRowModel: KaihaiHandyImportColRowModel, }>) => void,
  refreshTable: (state: KaihaiImportHandyTmpState, action: PayloadAction<{ colRowModel: KaihaiHandyImportColRowModel, }>) => void,
  editRowDatas: (state: KaihaiImportHandyTmpState, action: PayloadAction<{
    editDatas: {
      row: number, col: number, value: string | number | object | null
      , relatedValues?: { key: string, value: string | number | object | null }[]
      , uuid?: string
    }[],
    colRowModel: KaihaiHandyImportColRowModel,
  }>) => void,

  //
  addRow: (state: KaihaiImportHandyTmpState, action: PayloadAction<number>) => void,
  removeEmptyRow: (state: KaihaiImportHandyTmpState) => void,
  initRow: (state: KaihaiImportHandyTmpState) => void,

}

const createReducerContent = (): KaihaiImportHandyTmpReducer => {
  return {
    putProgress(state: KaihaiImportHandyTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = { ...state.progress };
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state: KaihaiImportHandyTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if (key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    setErrorMessage(state: KaihaiImportHandyTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    // setRetrieveParam(state: KaihaiImportHandyTmpState, action: PayloadAction<kaihaiHandyImportPreview.RequestParam>) {
    //   state.retrieveParam = action.payload;
    // },
    // setRetrievedParam(state: KaihaiImportHandyTmpState, action: PayloadAction<kaihaiHandyImportPreview.RequestParam>) {
    //   state.retrievedParam = action.payload;
    // },

    // searched(state: KaihaiImportHandyTmpState, action: PayloadAction<{
    //   param: kaihaiHandyImportPreview.RequestParam, kaihaiGroups: KaihaiImportHandyGroup[], colRowModel: KaihaiHandyImportColRowModel,
    // }>) {
    //   const colRowModel = action.payload.colRowModel;
    //   const param = action.payload.param;

    //   let kaihaiGroups = parseDataKaihaiImportHandyGroup(action.payload.kaihaiGroups);

    //   let dataGroups = convertDataGroups(kaihaiGroups);
    //   dataGroups = calcDataGroups(dataGroups);

    //   // dataGroups = sortDataGroups(dataGroups);
    //   const [rowInfos, fixedRowsTop] = convertRowInfos(dataGroups);

    //   //store更新
    //   state.dataGroups = dataGroups;
    //   state.rowInfos = rowInfos;
    //   state.fixedRowsTop = fixedRowsTop;
    //   state.mergeCells = createMergeCells(rowInfos, colRowModel);
    //   state.rows = convertRows(rowInfos, colRowModel);
    //   console.log(convertRows(rowInfos, colRowModel));
    // },
    refreshTableWithFilter(state: KaihaiImportHandyTmpState, action: PayloadAction<{
      colRowModel: KaihaiHandyImportColRowModel,
    }>) {
      console.log('refreshTableWithFilter');
      // if (!state.dataGroups || state.dataGroups.length == 0) {
      //   return;
      // }
      // const colRowModel = action.payload.colRowModel;

      // let dataGroups = state.dataGroups;
      // // dataGroups = sortDataGroups(dataGroups);
      // const [rowInfos, fixedRowsTop] = convertRowInfos(dataGroups);
      // //store更新
      // state.dataGroups = dataGroups;
      // state.rowInfos = rowInfos;
      // state.fixedRowsTop = fixedRowsTop;
      // state.mergeCells = createMergeCells(rowInfos, colRowModel);
      // state.rows = convertRows(rowInfos, colRowModel);
    },
    refreshTable(state: KaihaiImportHandyTmpState, action: PayloadAction<{
      colRowModel: KaihaiHandyImportColRowModel,
    }>) {
      console.log('refreshTable');
      // if (!state.dataGroups || state.dataGroups.length == 0) {
      //   return;
      // }
      // const colRowModel = action.payload.colRowModel;

      // let dataGroups = state.dataGroups;
      // // dataGroups = sortDataGroups(dataGroups);
      // const [rowInfos, fixedRowsTop] = convertRowInfos(dataGroups);
      // //store更新
      // state.rowInfos = rowInfos;
      // state.fixedRowsTop = fixedRowsTop;
      // state.mergeCells = createMergeCells(rowInfos, colRowModel);
      // state.rows = convertRows2(rowInfos, colRowModel);
    },
    // editRowDatas(state: KaihaiImportHandyTmpState, action: PayloadAction<{
    //   editDatas: {
    //     row: number, col: number, value: string | number | object | null
    //     , relatedValues?: { key: string, value: string | number | object | null }[]
    //     , uuid?: string
    //   }[],
    //   colRowModel: KaihaiHandyImportColRowModel,
    // }>) {
    //   console.log('store.editRowDatas');
    //   // const editDatas = action.payload.editDatas;
    //   // // const colRowModel = action.payload.colRowModel;
    //   // // let newDatas = [...state.dataGroups];
    //   // // // const newRows = [...state.rows];

    //   // const editDataContent = (rowInfo: RowInfo, row: number, col: number, key: string, value: string | number | boolean | object | null) => {
    //   //   if (!(row >= 0) || (!key && !(col >= 0))) {
    //   //     return;
    //   //   }
    //   //   if (!key) {
    //   //     key = colRowModel.keyFromCol(col);
    //   //   }
    //   //   if (!(col >= 0)) {
    //   //     col = colRowModel.colFromKey(key);
    //   //   }

    //   //   //parse
    //   //   const dataType = colDataType[key];
    //   //   value = editorUtil.parseValue(value, dataType.type,
    //   //     dataType.type == 'numeric' ? dataType.numericFormat.pattern :
    //   //       dataType.type == 'date' ? dataType.dateFormat :
    //   //         null);

    //   //   let data = newDatas[rowInfo.dataIndex];
    //   //   switch (key) {
    //   //     // case "targetFlg":
    //   //     //   data.kaihaiGroup.targetFlg = typeof value == 'boolean' ? value : false;
    //   //     //   break;
    //   //     // case "beforeItemFlg":
    //   //     //   data.kaihaiGroup.items[rowInfo.rowIndex].beforeItemFlg = typeof value == 'boolean' ? value : false;
    //   //     //   break;
    //   //     // case "afterItemFlg":
    //   //     //   data.kaihaiGroup.items[rowInfo.rowIndex].afterItemFlg = typeof value == 'boolean' ? value : false;
    //   //     //   break;
    //   //     default:
    //   //       break;
    //   //   }
    //   //   // newRows[row][col] = value;
    //   // };

    //   // editDatas.forEach((editData) => {
    //   //   const key = colRowModel.keyFromCol(editData.col);
    //   //   const rowInfo = state.rowInfos[editData.row];
    //   //   editDataContent(rowInfo, editData.row, editData.col, key, editData.value);
    //   //   //関連データの更新
    //   //   if (editData.relatedValues) {
    //   //     editData.relatedValues.forEach(relatedValue => {
    //   //       editDataContent(rowInfo, editData.row, null, relatedValue.key, relatedValue.value);
    //   //     })
    //   //   }
    //   //   // //uuid
    //   //   // if (editData.uuid) {
    //   //   //   let data = newDatas[rowInfo.dataIndex];
    //   //   //   data.uuid = editData.uuid;
    //   //   // }
    //   // });
    //   // // newDatas = checkDatas(newDatas, state.editingType);
    //   // // newDatas = doSort(newDatas, sorts, state.adms);

    //   // //store更新
    //   // const [rowInfos, fixedRowsTop] = convertRowInfos(newDatas);
    //   // state.dataGroups = newDatas;
    //   // state.rowInfos = rowInfos;
    //   // // state.fixedRowsTop = fixedRowsTop;
    //   // state.mergeCells = createMergeCells(rowInfos, colRowModel);
    //   // state.rows = convertRows(rowInfos, colRowModel);
    // },
    editRowDatas(state: KaihaiImportHandyTmpState, action: PayloadAction<{
      editDatas: {
        row: number, col: number, value: string | number | object | null
        , relatedValues?: { key: string, value: string | number | object | null }[]
        , uuid?: string
      }[],
      colRowModel: KaihaiHandyImportColRowModel,
    }>) {
      console.log('store.editRowDatas');
      const editDatas = action.payload.editDatas;
      const colRowModel = action.payload.colRowModel;
      const newDatas = [...state.datas];

      const editDataContent = (row: number, col: number, key: string, value: string | number | boolean | object | null) => {
        if (!(row >= 0) || (!key && !(col >= 0))) {
          return;
        }
        if (!key) {
          key = colRowModel.keyFromCol(col);
        }
        if (!(col >= 0)) {
          col = colRowModel.colFromKey(key);
        }
        // const keyInfo = colRowModel.keyInfoFromCol(col);

        //parse
        const dataType = colDataType[key];
        value = editorUtil.parseValue(value, dataType.type,
          dataType.type == 'numeric' ? dataType.numericFormat.pattern :
            dataType.type == 'date' ? dataType.dateFormat :
              null);

        let data = newDatas[row];

        data[key] = value;
        // data = calcData(data);
        // data = checkData(data);

        // newDatas[rowInfo.dataIndex].details = calcDetails(newDatas[rowInfo.dataIndex].details);
        // newDatas[row][col] = value;
      };

      editDatas.forEach((editData) => {
        const key = colRowModel.keyFromCol(editData.col);

        editDataContent(editData.row, editData.col, key, editData.value);


        //関連データの更新
        if (editData.relatedValues) {
          editData.relatedValues.forEach(relatedValue => {
            editDataContent(editData.row, null, relatedValue.key, relatedValue.value);
          })
        }
      })
      const rowInfos = convertRows(newDatas,);
      Object.assign(state, {
        datas: newDatas,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },


    addRow(state: KaihaiImportHandyTmpState, action: PayloadAction<number>) {
      console.log('store.addRow');
      const rowCount = action.payload;

      let newDatas: RowData[] = [];
      for (let i = 0; i < rowCount; i++) {
        const data: RowData = {};
        newDatas.push(data);
      }

      // const useTeibanAll = state.centersIndex == 0;
      // newDatas = fillDatasDetailsCenter(newDatas, state.centers, useTeibanAll); //センター情報を埋める
      // newDatas = calcDatas(newDatas); //計算項目の計算
      // newDatas = checkDatas(newDatas); //データチェック
      // // newDatas = doSort(newDatas, sort.key, sort.asc, false);  //ソート

      const datas = [...state.datas, ...newDatas];

      const rowInfos = convertRows(datas);
      Object.assign(state, {
        datas: datas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    removeEmptyRow(state: KaihaiImportHandyTmpState) {
      console.log('store.removeEmptyRow');
      let datas = [...state.datas];
      // datas = datas.filter(data => !(!data.ICD));

      // datas = resetRowNo(datas, null); //列番号の振りなおし

      //0行になる場合は1行足す
      if (datas.length == 0) {
        const rowCount = 1;
        let newDatas: RowData[] = [];
        for (let i = 0; i < rowCount; i++) {
          const data: RowData = {};
          newDatas.push(data);
        }

        // const useTeibanAll = state.centersIndex == 0;
        // newDatas = fillDatasDetailsCenter(newDatas, state.centers, useTeibanAll); //センター情報を埋める
        // newDatas = calcDatas(newDatas); //計算項目の計算
        // newDatas = checkDatas(newDatas); //データチェック
        // newDatas = doSort(newDatas, sort.key, sort.asc, false);  //ソート

        datas = [...datas, ...newDatas];
      }

      // const useTeibanAll = state.centersIndex == 0;
      const rowInfos = convertRows(datas);
      Object.assign(state, {
        datas: datas,
        // dataRowIndexes: rowInfos.dataRowIndexes,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },
    initRow(state: KaihaiImportHandyTmpState) {
      console.log('store.initRow');

      let newDatas: RowData[] = [
        {
          // "filename": "メーカー改廃フォーマット_250206.xlsx",
          // "sheetname": "チェックver.",
          // "rowNo": 4,
          "beforeSyubaiDate": ("24/11/30"),
          "beforeJan": "4987206394682",
          "beforeMakerName": "浅田飴",
          "beforeItemName": "プロポリスマヌカハニー飴",
          "beforeKikaku": "60g",
          "beforeIrisu": "6×8",
          "beforeJodai": 200,
          "beforeSyubaiFlg": false,
          "beforeJyodaiFlg": true,
          "beforeKikakuFlg": false,
          "beforeNyusatsuFlg": false,
          "beforeBikou": "",
          "afterHatsubaiDate": ("24/12/01"),
          "afterJan": "4987206622594",
          "afterMakerName": "浅田飴",
          "afterItemName": "プロポリスマヌカハニー飴",
          "afterKikaku": "60g",
          "afterIrisu": "6×8",
          "afterJodai": 220,
          "afterJanhenFlg": true,
          "afterBikou": "",
          // "items": []
        },
        {
          // "filename": "メーカー改廃フォーマット_250206.xlsx",
          // "sheetname": "チェックver.",
          // "rowNo": 5,
          "beforeSyubaiDate": ("24/11/28"),
          "beforeJan": "4901035120113",
          "beforeMakerName": "天乃屋",
          "beforeItemName": "スペシャル歌舞伎揚",
          "beforeKikaku": "18枚",
          "beforeIrisu": "4×1",
          "beforeJodai": 1000,
          "beforeSyubaiFlg": false,
          "beforeJyodaiFlg": false,
          "beforeKikakuFlg": true,
          "beforeNyusatsuFlg": false,
          "beforeBikou": "リニューアル、パッケージ変更",
          "afterHatsubaiDate": ("24/11/29"),
          "afterJan": "4901035120120",
          "afterMakerName": "天乃屋",
          "afterItemName": "スペシャル歌舞伎揚",
          "afterKikaku": "16枚",
          "afterIrisu": "4×1",
          "afterJodai": 1000,
          "afterJanhenFlg": true,
          "afterBikou": "",
          // "items": []
        },
        {
          // "filename": "メーカー改廃フォーマット_250206.xlsx",
          // "sheetname": "チェックver.",
          // "rowNo": 6,
          "beforeSyubaiDate": ("24/11/28"),
          "beforeJan": "4901035210937",
          "beforeMakerName": "天乃屋",
          "beforeItemName": "ひとくち歌舞伎揚わさび",
          "beforeKikaku": "54g",
          "beforeIrisu": "12×1",
          "beforeJodai": 150,
          "beforeSyubaiFlg": true,
          "beforeJyodaiFlg": false,
          "beforeKikakuFlg": false,
          "beforeNyusatsuFlg": false,
          "beforeBikou": "季節品終売のため差し替え",
          "afterHatsubaiDate": ("24/11/29"),
          "afterJan": "4901035210531",
          "afterMakerName": "天乃屋",
          "afterItemName": "ひとくち歌舞伎揚",
          "afterKikaku": "68g",
          "afterIrisu": "12×1",
          "afterJodai": 150,
          "afterJanhenFlg": true,
          "afterBikou": "",
          // "items": []
        },
        {
          // "filename": "メーカー改廃フォーマット_250206.xlsx",
          // "sheetname": "チェックver.",
          // "rowNo": 7,
          "beforeSyubaiDate": ("24/11/28"),
          "beforeJan": "4901035610447",
          "beforeMakerName": "天乃屋",
          "beforeItemName": "ざらめ煎餅",
          "beforeKikaku": "7枚",
          "beforeIrisu": "12×1",
          "beforeJodai": 270,
          "beforeSyubaiFlg": true,
          "beforeJyodaiFlg": false,
          "beforeKikakuFlg": false,
          "beforeNyusatsuFlg": false,
          "beforeBikou": "",
          "afterHatsubaiDate": null,
          "afterJan": "",
          "afterMakerName": "",
          "afterItemName": "",
          "afterKikaku": "",
          "afterIrisu": "",
          "afterJodai": null,
          "afterJanhenFlg": false,
          "afterBikou": "差し替えなし",
          // "items": []
        }
      ];
      for (let i = 0; i < 10 - 4; i++) {
        const data: RowData = {};
        newDatas.push(data);
      }

      const datas = [...newDatas];

      const rowInfos = convertRows(datas);
      Object.assign(state, {
        datas: datas,
        rowInfos: rowInfos.rowInfos,
        rows: rowInfos.rows,
        mergeCells: rowInfos.mergeCells,
      });
    },

  }
};



//配列データに変換
const convertRows = (datas: RowData[],): {
  rows: [][],
  // dataRowIndexes:number[],
  rowInfos: RowInfo[],
  mergeCells: { row: number, col: number, rowspan: number, colspan: number }[]
} => {
  console.log('store.convertRows');

  // if(!centers || centers.length == 0) {
  //   centers = [new CodeName({code:'', name:''})];
  // }

  const mergeCells: { row: number, col: number, rowspan: number, colspan: number }[] = [];
  const mergeCols = [];
  // //固定列すべてマージは崩れる
  // colKeysNo_data.forEach((colKey) => {
  //   mergeCols.push(colFromKey(colKey));
  // });
  // colKeysData_fix.forEach((colKey) => {
  //   mergeCols.push(colFromKey(colKey));
  // });
  // colKeysData_unfix.forEach((colKey) => {
  //   mergeCols.push(colFromKey(colKey));
  // });

  const rows = [];
  // let dataRowIndexes = [];
  const rowInfos: RowInfo[] = [];
  //set No.
  // datas.forEach((data, index1) => {

  //   //一時的に全ての表示状態をfalseにする
  //   // data.visible = false;

  //   const s = rows.length;
  //   const no1:string = "" + (index1 + 1);
  //   let detailCount = 0;

  //   // const detailRowFunc = (detail, detailDataIndex) => {
  //   //   detailCount++;
  //   //   const no2:string = "" + detailCount;
  //   //   const no:string = no1 + '-' + no2;

  //   //   const r = [];
  //   //   [...colKeysNo_data, ...colKeysNo_detail].forEach((colKey) => {
  //   //     switch (colKey) {
  //   //       case 'no1':
  //   //         r.push(no1); //No.
  //   //         break;
  //   //       case 'no2':
  //   //         r.push(no2); //No.
  //   //         break;
  //   //       default:
  //   //         r.push(null);
  //   //         break;
  //   //     }
  //   //   });
  //   //   colKeysData_fix.forEach((colKey) => {
  //   //     r.push(data[colKey]);
  //   //   });
  //   //   colKeysDetail_fix.forEach((colKey) => {
  //   //     r.push(detail ? detail[colKey] : null);
  //   //   });
  //   //   colKeysData_unfix.forEach((colKey) => {
  //   //     r.push(data[colKey]);
  //   //   });
  //   //   colKeysDetail_unfix.forEach((colKey) => {
  //   //     r.push(detail ? detail[colKey] : null);
  //   //   });

  //   //   rows.push(r);
  //   //   // dataRowIndexes.push(index);
  //   //   rowInfos.push({
  //   //     no: no,
  //   //     // row:rowInfos.length,
  //   //     // data: data,
  //   //     dataIndex:index1,
  //   //     // detail: detail,
  //   //     // detailDataIndex:detailDataIndex,
  //   //     // detailRowIndex:detailCount - 1,
  //   //     // detailRowEnd: false,  //一時的に
  //   //   });
  //   //   if(data) {
  //   //     data.visible = true;
  //   //   }
  //   //   if(detail) {
  //   //     detail.visible = true;
  //   //   }
  //   // };

  //   // //一時的に全ての表示状態をfalseにする
  //   // if(data && data.details) {
  //   //   data.details.filter((detail, index2) => {
  //   //     detail.visible = false;

  //   //     {
  //   //       //定番マスタがあれば表示する
  //   //       if(detail.teibanInfo) {
  //   //         if(useTeibanAll) {
  //   //           detailRowFunc(detail, index2);
  //   //         }
  //   //         else {
  //   //           //表示対象センターならば表示する
  //   //           const center = centers.find(center => detail.SCD == center.code);
  //   //           if(center) {
  //   //             detailRowFunc(detail, index2);
  //   //           }
  //   //         }
  //   //       }
  //   //     }
  //   //   });
  //   // }

  //   // //0件は空データを投入
  //   // if(detailCount == 0) {
  //   //   detailRowFunc(null, -1);
  //   // }

  //   // rowInfos[rowInfos.length-1].detailRowEnd = true;

  //   //マージ
  //   const e = rows.length;
  //   if(e - s > 1) {
  //     const rowspan = e - s;
  //     mergeCols.forEach(col => {
  //       mergeCells.push({row: s, col: col, rowspan: rowspan, colspan: 1});
  //     });
  //   }
  // });
  datas.forEach((data, index) => {
    const no: string = "" + (index + 1);
    const r = [];
    colKeys.forEach((colKey) => {
      switch (colKey) {
        case 'no':
          r.push(no); //No.
          break;
        default:
          r.push(data[colKey]);
          break;
      }
    });
    rows.push(r);
  });

  return {
    rows: rows,
    rowInfos: rowInfos,
    mergeCells: mergeCells,
  };
}


export const getOptionLabel = (option: CodeName) => {
  return option && option.name ? (option.code + ' ' + option.name) : "";
}

const createSliceContent = (name: string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

//Page Slice Export
//KaihaiImportHandyTmp
export const kaihaiImportHandyTmpSlice = createSliceContent("kaihaiImportHandyTmp");
