import moment from 'moment';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/store/common";
import * as arrayUtil from "@/util/arrayUtil";
import { KaihaiColRowModel, KaihaiColRowModelDefault } from "@/components/kaihai/KaihaiTableModel";
import { listSortOrders } from "@/store/kaihai/kaihaiCommon";
import { PageProps } from "@/assets/pageprops/kaihai";

//強制表示列
export const mustVisibleColumnsKey:string[] = [
  "no", 
  "beforeSyubaiDate",
  "beforeJan",
  "afterHatsubaiDate",
  "afterJan",
  // "rnk",
  // "beforeItemCd",
  // "beforeMakerName",
  // "beforeItemName",
  // "beforeKikaku",
  // "beforeIrisu",
  // "beforeJodai",
  // "beforeSyubaiFlg",
  // "beforeJyodaiFlg",
  // "beforeKikakuFlg",
  // "beforeNyusatsuFlg",
  // "beforeBikou",
  // "afterItemCd",
  // "afterMakerName",
  // "afterItemName",
  // "afterKikaku",
  // "afterIrisu",
  // "afterJodai",
  // "afterJanhenFlg",
  // "afterBikou",
  // "createDate",
  // "createUserid",
  // "createUsername",
  // "updateDate",
  // "updateUserid",
  // "updateUsername",
];
//デフォルト表示列
export const defaultVisibleColumnsKey:string[] = [
  // "beforeSyubaiDate",
  // "beforeJan",
  // "afterHatsubaiDate",
  // "afterJan",
  // "rnk",
  "beforeItemCd",
  "beforeMakerName",
  "beforeItemName",
  "beforeKikaku",
  "beforeIrisu",
  "beforeJodai",
  "beforeSyubaiFlg",
  "beforeJyodaiFlg",
  "beforeKikakuFlg",
  "beforeNyusatsuFlg",
  "beforeBikou",
  "afterItemCd",
  "afterMakerName",
  "afterItemName",
  "afterKikaku",
  "afterIrisu",
  "afterJodai",
  "afterJanhenFlg",
  "afterBikou",
  // "createDate",
  // "createUserid",
  // "createUsername",
  "updateDate",
  // "updateUserid",
  "updateUsername",
];

export const optionsNameList = [
  'optionMakers',
  'optionMakerCode',
  // 'optionBumons',
  // 'optionAreas',
  'optionCenters',
  'optionItemCode',
	'optionSyubaiDateFrom',
	'optionSyubaiDateTo',
	'optionHatsubaiDateFrom',
	'optionHatsubaiDateTo',
];
export type KaihaiOptions = {
  optionMakers?: CodeName[],        //メーカー
  optionMakerCode?: string,         //メーカーCD
  // optionBumons?: CodeName[],        //所属
  // optionAreas?: CodeName[],        //エリア
  optionCenters?: CodeName[],        //センター
  optionItemCode?: string,          //商品CD
	optionSyubaiDateFrom?: Date, //終売日
	optionSyubaiDateTo?: Date, //終売日
	optionHatsubaiDateFrom?: Date, //発売日
	optionHatsubaiDateTo?: Date, //発売日
};
export const displaySettingsNameList = [
  'checkedColKey',
];
export type DisplaySettings = {
  checkedColKey?: string[],  //列項目選択
};

//Page State
export type KaihaiSaveState = {
  //検索条件
  optionMakers:CodeName[],    //メーカー
  optionMakerCode:string,            //メーカーCD
  // optionBumons:CodeName[],     //所属
  // optionAreas:CodeName[],     //エリア
  optionCenters:CodeName[],     //センター
  optionItemCode:string,            //商品CD
	optionSyubaiDateFrom?: string, //終売日(年月)
	optionSyubaiDateTo?: string, //終売日(年月)
	optionHatsubaiDateFrom?: string, //発売日(年月)
	optionHatsubaiDateTo?: string, //発売日(年月)

  favoriteOptions: { title: CodeName, params: KaihaiOptions }[] | null,
  favoriteDisplaySettings: { title: CodeName, params: DisplaySettings }[] | null,

  listSortOrder:CodeName,   //並び順
  listSortOrderDesc:boolean,   //昇順

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],
};

export const initialkaihaiState: KaihaiSaveState = {
  optionMakers:[],    //メーカー
  optionMakerCode:'',            //メーカーCD
  // optionBumons:[],     //所属
  // optionAreas:[],     //エリア
  optionCenters:[],     //センター
  optionItemCode:'',            //商品CD
	optionSyubaiDateFrom: null, //終売日
	optionSyubaiDateTo: null, //終売日
	optionHatsubaiDateFrom: null, //発売日
	optionHatsubaiDateTo: null, //発売日

  favoriteOptions: [],
  favoriteDisplaySettings: [],

  listSortOrder: listSortOrders.find(listSortOrder => listSortOrder.code == 'beforeSyubaiDate'),   //並び順
  listSortOrderDesc: true,   //並び順

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,
};

//Page Slice
export type KaihaiSaveReducer = {
  initOnDidMount: (state:KaihaiSaveState, action: PayloadAction<PageProps>) => void,
  resetOnWillUnmount: (state:KaihaiSaveState) => void,
  clearOption: (state:KaihaiSaveState, action: PayloadAction<{}>) => void,
  setOptionMakers: (state:KaihaiSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionMakerCode: (state:KaihaiSaveState, action: PayloadAction<string>) => void,
  // setOptionBumons: (state:KaihaiSaveState, action: PayloadAction<CodeName[]>) => void,
  // setOptionAreas: (state:KaihaiSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionCenters: (state:KaihaiSaveState, action: PayloadAction<CodeName[]>) => void,
  setOptionItemCode: (state:KaihaiSaveState, action: PayloadAction<string>) => void,
  setOptionSyubaiDateFrom: (state:KaihaiSaveState, action: PayloadAction<string>) => void,
  setOptionSyubaiDateTo: (state:KaihaiSaveState, action: PayloadAction<string>) => void,
  setOptionHatsubaiDateFrom: (state:KaihaiSaveState, action: PayloadAction<string>) => void,
  setOptionHatsubaiDateTo: (state:KaihaiSaveState, action: PayloadAction<string>) => void,

  setFavoriteOptions: (state:KaihaiSaveState, action: PayloadAction<{ title: CodeName, params: KaihaiOptions }[]>) => void,
  setFavoriteDisplaySettings: (state:KaihaiSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) => void,
  setListSortOrder: (state:KaihaiSaveState, action: PayloadAction<CodeName>) => void,
  setListSortOrderDesc: (state:KaihaiSaveState, action: PayloadAction<boolean>) => void,
  hideColumnKeysChange: (state:KaihaiSaveState, action: PayloadAction<string[]>) => void,
}

const createReducerContent = (name:string, colRowModel:KaihaiColRowModel, initialState: KaihaiSaveState):KaihaiSaveReducer => {return {
  //componentDidMount
  initOnDidMount(state:KaihaiSaveState, action: PayloadAction<PageProps>) {
    const props:PageProps = action.payload;

    // if(state.optionBumons === undefined) {
    //   state.optionBumons = initialkaihaiState.optionBumons;
    // }
    // if(state.optionAreas === undefined) {
    //   state.optionAreas = initialkaihaiState.optionAreas;
    // }
    if(state.optionCenters === undefined) {
      state.optionCenters = initialkaihaiState.optionCenters;
    }
    if(state.optionMakers === undefined) {
      state.optionMakers = initialkaihaiState.optionMakers;
    }
    if(state.optionMakerCode === undefined) {
      state.optionMakerCode = initialkaihaiState.optionMakerCode;
    }
    if(state.optionItemCode === undefined) {
      state.optionItemCode = initialkaihaiState.optionItemCode;
    }

    if(state.optionSyubaiDateFrom === undefined) {
      state.optionSyubaiDateFrom = moment(new Date()).add(-1, 'months').format('YYYYMM');
    }
    if(state.optionSyubaiDateTo === undefined) {
      state.optionSyubaiDateTo = moment(new Date()).format('YYYYMM');
    }

    if(state.optionHatsubaiDateFrom === undefined) {
      state.optionHatsubaiDateFrom = moment(new Date()).format('YYYYMM');
    }
    if(state.optionHatsubaiDateTo === undefined) {
      state.optionHatsubaiDateTo = moment(new Date()).add(+1, 'months').format('YYYYMM');
    }


    if(state.favoriteOptions === undefined) {
      state.favoriteOptions = initialkaihaiState.favoriteOptions;
    }
    if(state.favoriteDisplaySettings === undefined) {
      state.favoriteDisplaySettings = initialkaihaiState.favoriteDisplaySettings;
    }
    if(!state.listSortOrder) {
      state.listSortOrder = initialState.listSortOrder;
    }
    if(state.listSortOrderDesc === undefined) {
      state.listSortOrderDesc = initialState.listSortOrderDesc;
    }

    //初期表示列0配列は、常に全項目表示とする
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = colRowModel.colKeys;
    }
    else if(!state.visibleColumnsKey) {
      state.visibleColumnsKey = initialState.visibleColumnsKey;
    }
    //強制選択列
    state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colRowModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
    //非表示列
    state.hiddenColumnsKey = arrayUtil.not(colRowModel.colKeys, state.visibleColumnsKey);
  },
  //componentWillUnmount
  resetOnWillUnmount(state:KaihaiSaveState) {
    //初期表示列0配列は、常に全項目表示とするため、記憶しない
    if(initialState.visibleColumnsKey.length == 0) {
      state.visibleColumnsKey = [];
    }
  },
  clearOption(state:KaihaiSaveState, action: PayloadAction<{}>) {
    state.optionMakers = initialkaihaiState.optionMakers;
    state.optionMakerCode = initialkaihaiState.optionMakerCode;
    // state.optionBumons = initialkaihaiState.optionBumons;
    // state.optionAreas = initialkaihaiState.optionAreas;
    state.optionCenters = initialkaihaiState.optionCenters;
    state.optionItemCode = initialkaihaiState.optionItemCode;
    state.optionSyubaiDateFrom = moment(new Date()).add(-1, 'months').format('YYYYMM');
    state.optionSyubaiDateTo = moment(new Date()).format('YYYYMM');
    state.optionHatsubaiDateFrom = moment(new Date()).format('YYYYMM');
    state.optionHatsubaiDateTo = moment(new Date()).add(+1, 'months').format('YYYYMM');
  },
  setOptionMakers (state:KaihaiSaveState, action: PayloadAction<CodeName[]>) {
    state.optionMakers = action.payload;
  },
  setOptionMakerCode (state:KaihaiSaveState, action: PayloadAction<string>) {
    state.optionMakerCode = action.payload;
  },
  // setOptionBumons (state:KaihaiSaveState, action: PayloadAction<CodeName[]>) {
  //   state.optionBumons = action.payload;
  // },
  // setOptionAreas (state:KaihaiSaveState, action: PayloadAction<CodeName[]>) {
  //   state.optionAreas = action.payload;
  // },
  setOptionCenters (state:KaihaiSaveState, action: PayloadAction<CodeName[]>) {
    state.optionCenters = action.payload;
  },
  setOptionItemCode (state:KaihaiSaveState, action: PayloadAction<string>) {
    state.optionItemCode = action.payload;
  },
  setOptionSyubaiDateFrom(state:KaihaiSaveState, action: PayloadAction<string>) {
    state.optionSyubaiDateFrom = action.payload;
    if (state.optionSyubaiDateFrom && state.optionSyubaiDateTo && state.optionSyubaiDateFrom > state.optionSyubaiDateTo) {
      state.optionSyubaiDateTo = state.optionSyubaiDateFrom;
    }
  },
  setOptionSyubaiDateTo(state:KaihaiSaveState, action: PayloadAction<string>) {
    state.optionSyubaiDateTo = action.payload;
    if (state.optionSyubaiDateFrom && state.optionSyubaiDateTo && state.optionSyubaiDateFrom < state.optionSyubaiDateTo) {
      state.optionSyubaiDateFrom = state.optionSyubaiDateTo;
    }
  },
  setOptionHatsubaiDateFrom(state:KaihaiSaveState, action: PayloadAction<string>) {
    state.optionHatsubaiDateFrom = action.payload;
    if (state.optionHatsubaiDateFrom && state.optionHatsubaiDateTo && state.optionHatsubaiDateFrom > state.optionHatsubaiDateTo) {
      state.optionHatsubaiDateTo = state.optionHatsubaiDateFrom;
    }
  },
  setOptionHatsubaiDateTo(state:KaihaiSaveState, action: PayloadAction<string>) {
    state.optionHatsubaiDateTo = action.payload;
    if (state.optionHatsubaiDateFrom && state.optionHatsubaiDateTo && state.optionHatsubaiDateFrom < state.optionHatsubaiDateTo) {
      state.optionHatsubaiDateFrom = state.optionHatsubaiDateTo;
    }
  },

  setFavoriteOptions(state:KaihaiSaveState, action: PayloadAction<{ title: CodeName, params: KaihaiOptions }[]>) {
    state.favoriteOptions = action.payload;
  },
  setFavoriteDisplaySettings(state:KaihaiSaveState, action: PayloadAction<{ title: CodeName, params: DisplaySettings }[]>) {
    state.favoriteDisplaySettings = action.payload;
  },
  setListSortOrder (state:KaihaiSaveState, action: PayloadAction<CodeName>) {
    state.listSortOrder = action.payload;
  },
  setListSortOrderDesc (state:KaihaiSaveState, action: PayloadAction<boolean>) {
    state.listSortOrderDesc = action.payload;
  },
  hideColumnKeysChange(state:KaihaiSaveState, action: PayloadAction<string[]>){
    console.log('store.hideColumnKeysChange');
    //store更新
    state.hiddenColumns = colRowModel.colFromKeys(action.payload);
    state.hiddenColumnsKey = action.payload;
    state.visibleColumnsKey = arrayUtil.not(colRowModel.colKeys, action.payload);
  },
}};

const createSliceContent = (name:string, colRowModel:KaihaiColRowModel, initialState: KaihaiSaveState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colRowModel, initialState),
});

//Page Slice Export
//KaihaiSaveSlice
export const kaihaiSaveSlice = createSliceContent("kaihaiSave", KaihaiColRowModelDefault, initialkaihaiState);
