//CodeName
interface CodeNameInterface {
  code: string,
  name: string,
  group?: string;
  group_code?: string;
  group_name?: string;
  option1?: string;
  option2?: string;
  option3?: string;
  option4?: string;
  option5?: string;
  option6?: string;
  katakana?: string;
  katakana2?: string;
  ryaku?: string;
}

export class CodeName implements CodeNameInterface {
  code: string;
  name: string;
  group?: string;
  group_code?: string;
  group_name?: string;
  option1?: string;
  option2?: string;
  option3?: string;
  option4?: string;
  option5?: string;
  option6?: string;
  katakana?: string;
  katakana2?: string;
  ryaku?: string;

  constructor(param: CodeNameInterface) {
    this.code = param.code;
    this.name = param.name;
    this.group = param.group;
    this.group_code = param.group_code;
    this.group_name = param.group_name;
    this.option1 = param.option1;
    this.option2 = param.option2;
    this.option3 = param.option3;
    this.option4 = param.option4;
    this.option5 = param.option5;
    this.option6 = param.option6;
    this.katakana = param.katakana;
    this.katakana2 = param.katakana2;
    this.ryaku = param.ryaku;
  }

  labelString?():string {return this.name}
}
export const getCodeNameLabel = (option: CodeName|string, hideCode:boolean) => {
  return option ? (typeof option == 'string' ? option : (!hideCode ? `${option.code} ${option.name}` : option.name ? option.name : option.code)) : '';
}

//YM
interface YMInterface {
  code: string,
  name: string,
  thisMonth: boolean; //当月
}
export class YM implements YMInterface {
  code: string;
  name: string;
  thisMonth: boolean; //当月

  constructor(param: YMInterface) {
    this.code = param.code;
    this.name = param.name;
    this.thisMonth = param.thisMonth;
  }

  labelString():string {return this.name}
}
//YW
interface YWInterface {
  code: string,
  name: string,
  thisWeek: boolean; //当月
}
export class YW implements YWInterface {
  code: string;
  name: string;
  thisWeek: boolean; //当月

  constructor(param: YWInterface) {
    this.code = param.code;
    this.name = param.name;
    this.thisWeek = param.thisWeek;
  }

  labelString():string {return this.name}
}
export type DefaultVisibleKey = {
  name: string,
  visibleKey: string[],
};

export interface LastUpdate {
  end_time?: string | null,
}

export interface Param {
  label?: string | null,
  value?: string | null,
}


const Pages = [
  { name: '', url: '/', permissions: [], },
  { name: 'X-ray', url: '/xray', permissions: ['report-visible',], },
  { name: 'X-ray', url: '/xray#', permissions: ['report-visible',], },
  { name: 'X-ray2', url: '/xray2', permissions: ['report-visible',], },
  { name: 'X-ray2', url: '/xray2#', permissions: ['report-visible',], },
  { name: '単品別販売実績一覧', url: '/itemlist', permissions: ['report-visible',], },
  { name: '販売実績単品別詳細', url: '/salesitemlist', permissions: ['report-visible',], }, //販売実績、販売実績推移の右クリックの単品別ランキング
  { name: '単品別販売実績推移', url: '/itemlisttrans', permissions: ['report-visible',], },
  { name: '欠品帳票', url: '/keppinitemlist', permissions: ['report-visible',], },
  { name: 'データ出力', url: '/outputdata', permissions: ['report-visible',], },
  { name: '販売実績', url: '/saleslist', permissions: ['report-visible',], },
  { name: '販売実績推移', url: '/saleslisttrans', permissions: ['report-visible',], },
  { name: 'センター別滞留在庫内訳', url: '/zaikosummary', permissions: ['report-visible',], },
  { name: 'センター賞味期限別在庫一覧', url: '/zaikokigen', permissions: ['report-visible',], },
  { name: 'リアル賞味期限別在庫照会', url: '/zaikoreal', permissions: ['report-visible',], },
  { name: '・10.日次速報', url: '/report/10_daily', permissions: ['report-visible',], },
  { name: '・15.日次推移', url: '/report/11_trans_daily', permissions: ['report-visible',], },
  { name: '・25.週次推移', url: '/report/16_trans_weekly', permissions: ['report-visible',], },
  { name: '・20.週次速報', url: '/report/21_weekly', permissions: ['report-visible',], },
  { name: '・35.月次推移', url: '/report/15_trans_quick', permissions: ['report-visible',], },
  { name: '・30.月次確定（純売上照会）', url: '/report/20_quick', permissions: ['report-visible',], },
  { name: '・40.月次確報', url: '/report/30_commit', permissions: ['report-visible',], },
  { name: '・45.月次確報推移', url: '/report/35_trans_commit', permissions: ['report-visible',], },
  { name: '・50.センター経費', url: '/report/40_cost', permissions: ['report-visible',], },
  { name: '・55.センター経費推移', url: '/report/45_trans_cost', permissions: ['report-visible',], },
  { name: '・S.売上ランキング', url: '/report/S_item_ranking', permissions: ['report-visible',], },
  { name: '・アイテム削減帳票', url: '/report/itemcut', permissions: ['report-visible',], },
  { name: '特売入力', url: '/tokubai/input', permissions: ['tokubai-visible',], },
  { name: '特売管理', url: '/tokubai/manage', permissions: ['tokubai-visible',], },
  { name: '特売進捗', url: '/tokubai/progress', permissions: ['tokubai-visible',], },
  { name: '特売管理システム設定', url: '/tokubai/setting', permissions: ['tokubai-admin',], },
  { name: 'メーカー改廃', url: '/kaihai', permissions: ['tokubai-admin',], },
];

export const checkPermission = (url: string, permissions: string[]) => {
  if (!Array.isArray(permissions)) {
    console.log(permissions);
    permissions = [];
  }
  const checkUrl = url
    .replace(/\?.*$/, "") // ?以降を削る
  //パスの位置+パスの長さ＝URLの長さ
  const page = Pages.find(page => checkUrl.lastIndexOf(page.url) + page.url.length == checkUrl.length);
  // console.log("checkPermission", url, checkUrl, permissions, page);
  if (!page) {
    return false;
  }
  return page.permissions.every(permission => permissions.includes(permission));
}